import { render, staticRenderFns } from "./generalView.vue?vue&type=template&id=6e0d4382&scoped=true&"
import script from "./generalView.vue?vue&type=script&lang=js&"
export * from "./generalView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e0d4382",
  null
  
)

export default component.exports
<template>
  <v-card class="mx-auto elevation-0" flat max-width="100%" outlined>
    <v-divider class="mt-1"></v-divider>
    <v-list-item three-line>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-list-item-avatar
            v-on="on"
            tile
            size="80"
            :class="`borderRadius5 mx-2 my-2 ${!personIsConnected ? 'grayScale': ''}`"
          >
            <DefaultAvatar
              :size="80"
              :userData="visitor"
              v-if="avatarSrc === 'img/default_profile_picture.png'"
            ></DefaultAvatar>
            <v-img
              v-if="avatarSrc !== 'img/default_profile_picture.png'"
              class="mx-2 borderRadius5 cursorPointer"
              :src="avatarSrc"
              max-height="80"
              max-width="80"
              contain
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-list-item-avatar>
        </template>
        <span>{{visitor.sideBarSubject || ''}}</span>
      </v-tooltip>
      <v-list-item-content class="contentWrapper">
        <!-- <div class="datePosition">
          <span class="caption">{{formatDateToShow(visitor.created)}} h</span>
        </div>-->
        <!-- <div class="notranslate">
          <span style="font-size:14px;">{{visitor.email}}</span>
        </div>-->
        <v-list-item-subtitle>
          <span
            v-if="visitor.created"
            style="font-size:12px;font-weight:600"
          >{{formatDateToShow(visitor.created)}} h &nbsp;{{visitor.isWaitingRoom ? $t('components.waitingRoom.waitingRoom') : $t('generics.user')}}</span>
          <span v-else style="font-size:12px;">Unconfirmed user</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <span style="font-size:12px;font-weight:600">{{visitor.name}}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <span style="font-size:14px;">{{visitor.email || visitor.mobilePhone || ''}}</span>
        </v-list-item-subtitle>
        <!-- <v-list-item-subtitle class="userActivity userInCall" >{{$t('components.inviteVisitor.from')}}: {{visitor.linkDateFrom}} {{visitor.linkTimeFrom}}</v-list-item-subtitle>
        <v-list-item-subtitle>{{$t('components.inviteVisitor.until')}}: {{visitor.linkDateTo}} {{visitor.linkTimeTo}}</v-list-item-subtitle>-->
        <v-list-item-subtitle
          v-if="visitor.linkDateTo && visitor.linkTimeTo && visitor.duration"
        >{{$t('components.inviteVisitor.appointmentshort')}} {{getTimeMessage}}</v-list-item-subtitle>
        <div>
          <!-- <v-tooltip top v-if="visitor.subjectDisplay">-->
          <!-- <template v-slot:activator="{ on }">  -->
          <v-list-item-subtitle>{{ visitor.sideBarSubject ? visitor.sideBarSubject.substring(0, Math.min(visitor.sideBarSubject.length, 20)) + '...' : null }}</v-list-item-subtitle>
          <!-- </template> -->
          <!-- <span>{{visitor.subjectDisplay}}</span>
          </v-tooltip>-->
        </div>
        <div>
          <!-- <v-tooltip top v-if="visitor.internalnote">
            <template v-slot:activator="{ on }">
              <v-list-item-subtitle
                v-on="on"
                style="cursor:pointer;"
                :style="{color: badgetClassObj}"
              >{{visitor.internalnote ? visitor.internalnote.substring(0, Math.min(visitor.internalnote.length,14)) : null }}...</v-list-item-subtitle>
            </template>
            <span>{{visitor.internalnote}}</span>
          </v-tooltip>-->
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-card
      :color="badgetClassObj"
      class="px-10 noBRadius"
      :style="{
          borderWidth: 1,
          borderColor: borderClass,
          borderStyle: 'solid',
          height: 36
        }"
    >
      <v-row>
        <!-- calll -->
        <v-tooltip top v-if="!isInCallWithUs" :disabled="hasActivityNoCall">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :color="phoneColorColor"
              v-bind:disabled="disableCallBtn"
              v-on:click="callUser(uuid)"
            >
              <!-- v-on:click="!inCallText? doCall(person): joinCall(person)" -->
              <!-- v-on:dblclick="callDefaultminutes(person)"
              v-on:click="amInACall ? personCall(person) :  setShowModalMeetingTime(person.uuid) doCall(person)"-->
              <!-- v-on:click="personCall(person)" -->
              <font-awesome-icon
                :icon="['fas', 'phone']"
                :style="{ fontSize: '20px' }"
                v-if="!wholeBarWithColors()"
              />
              <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" v-else />
            </v-btn>
          </template>
          <span>{{ $t('components.userListItem.videoCallStart') }}</span>
        </v-tooltip>

        <v-tooltip top v-if="isInCallWithUs" :disabled="hasActivityNoCall">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :color="phoneColorColor"
              v-on:click="getWebrtcConnections()[uuid].die(true)"
            >
              <font-awesome-icon
                class="faPhoneRotate"
                :icon="['fal', 'phone']"
                :rotation="270"
                :style="{ fontSize: '20px' }"
                v-if="!wholeBarWithColors()"
              />
              <font-awesome-icon
                class="faPhoneRotate"
                :icon="['fas', 'phone']"
                :rotation="270"
                :style="{ fontSize: '20px' }"
                v-else
              />
            </v-btn>
          </template>
          <span>{{ $t('components.userListItem.hangOff') }}</span>
        </v-tooltip>
        <!-- end call -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              style="margin-left:15px;"
              @click="SendLinkToVisitor()"
              v-on="on"
              icon
              :color="wholeBarWithColors()? 'white' : 'rgb(83, 82, 82)'"
            >
              <font-awesome-icon :icon="['fas', 'redo']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{$t('components.inviteVisitor.resendInvLink')}}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              style="margin-left:15px;"
              @click="copyLink($event)"
              v-on="on"
              icon
              :color="wholeBarWithColors() ? 'white' : 'rgb(83, 82, 82)'"
            >
              <font-awesome-icon :icon="['fal', 'copy']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{$t('generics.copylink')}}</span>
        </v-tooltip>
        <v-layout justify-end>
          <v-menu offset-y scrollable :close-on-click="true">
            <template #activator="{ on: onMenu }">
              <v-btn v-on="onMenu" style="width:1px;height:1px;display:none;"></v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="btnr15"
                    icon
                    :color="wholeBarWithColors() ? 'white' : 'rgb(83, 82, 82)'"
                    v-on="{...on, ...onMenu}"
                  >
                    <font-awesome-icon :icon="['fal', 'info-circle']" :style="{ fontSize: '23px' }" />
                  </v-btn>
                </template>
                <span>{{$t('components.inviteVisitor.onecalldetails')}}</span>
              </v-tooltip>
            </template>
            <v-list style="width:550px;padding-bottom: 0px !important;">
              <v-list-item>
                <v-list-item-title
                  style="font-weight:400;"
                >{{$t('components.inviteVisitor.onecalldetails')}}:</v-list-item-title>
              </v-list-item>
              <v-divider class="mt-1"></v-divider>
              <v-list-item>
                <v-row style="width: 100%; margin: 0; padding-bottom:20px">
                  <!-- name -->
                  <v-col class="col-3 tigth">
                    <p class="fieldHeader">{{$t('components.inviteVisitor.name')}}:</p>
                  </v-col>
                  <v-col class="col-9 tigth">{{visitor.name}}</v-col>
                  <!-- email -->
                  <template v-if="visitor.email">
                    <v-col class="col-3 tigth">
                      <p class="fieldHeader">{{$t('components.inviteVisitor.invitationEmail')}}:</p>
                    </v-col>
                    <v-col class="col-9 tigth">{{visitor.email}}</v-col>
                  </template>
                  <!-- mobile phone -->
                  <template v-if="visitor.mobilePhone">
                    <v-col class="col-3 tigth">
                      <p class="fieldHeader">{{$t('excel.mobilePhone')}}:</p>
                    </v-col>
                    <v-col class="col-9 tigth">{{visitor.mobilePhone}}</v-col>
                  </template>
                  <!-- created -->
                  <v-col class="col-3 tigth">
                    <p class="fieldHeader">{{$t('components.inviteVisitor.invitation')}}:</p>
                  </v-col>
                  <v-col
                    v-if="visitor.created"
                    class="col-9 tigth"
                  >{{getVisitorDate(visitor.created)}}</v-col>
                  <!-- expires -->
                  <v-col class="col-3 tigth" v-if="visitor.linkDateTo && visitor.linkTimeTo">
                    <p class="fieldHeader">{{$t('components.inviteVisitor.appointmentshort')}}</p>
                  </v-col>
                  <v-col
                    v-if="visitor.linkDateTo && visitor.linkTimeTo"
                    class="col-9 tigth"
                  >{{ getTimeMessage }}</v-col>
                  <!-- Title -->
                  <v-col v-if="visitor.internalnote" class="col-3 tigth">
                    <p class="fieldHeader">{{$t('components.inviteVisitor.subject')}}:</p>
                  </v-col>
                  <v-col
                    v-if="visitor.internalnote"
                    class="col-9 tigth"
                  >{{(visitor.subject || "").substring(0,44)}}...</v-col>
                  <!-- notes -->
                  <v-col v-if="visitor.internalnote" class="col-3 tigth">
                    <p class="fieldHeader">{{$t('components.inviteVisitor.notice')}}:</p>
                  </v-col>
                  <v-col v-if="visitor.internalnote" class="col-9 tigth">{{visitor.internalnote}}</v-col>
                </v-row>
              </v-list-item>
              <v-card
                color="primary"
                class="py-2 pl-4 mt-4 pr-2 footerModal"
                style="min-height:35px;"
              ></v-card>
            </v-list>
          </v-menu>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <!-- @click="handleDeleteVisitor($event)" -->
              <v-btn
                class="btnr15"
                v-on="on"
                icon
                :disabled="state.isConfDeleting"
                :color="wholeBarWithColors() ? 'white' : 'rgb(83, 82, 82)'"
              >
              <DeleteBasket :indexData="visitor.uuid" :delFunction="deleteVisitor" extraClasses="" fontSize="20" prevent= false />
              </v-btn>
            </template>
            <span>{{$t('components.inviteVisitor.deleteOneCall')}}</span>
          </v-tooltip>
          <v-menu
            offset-y
            scrollable
            :close-on-click="true"
            v-if="state.persisted.userVisitors[uuid]"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :color="state.user.colorsettings.wholeBar ? 'white' : 'rgb(83, 82, 82)'"
                v-on="on"
                class="btnAbsolute"
                @click="resolveLink"
              >
                <font-awesome-icon :icon="['fal', 'ellipsis-v']" :style="{ fontSize: '30px' }" />
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="cursorPointer" @click="showTransferVisitorModal = true">
                <v-list-item-title>{{$t('components.inviteVisitor.transferVisitor')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
      </v-row>
    </v-card>

    <template>
      <TransferVisitorModal
        v-if="showTransferVisitorModal"
        :closeTransferVisitorModal="closeTransferVisitorModal"
        :showTransferVisitorModal="showTransferVisitorModal"
        :doTransferVisitor="doTransferVisitor"
        :visitor="visitor"
      />
    </template>

    <template>
      <SendInvitationModal
        v-if="showInvitationModal"
        :closeShowInviteInfo="closeShowInviteInfo"
        :showInviteInfo="showInvitationModal"
      />
    </template>

    <template v-if="showModalDelete">
      <RemoveVisitorModal
        :showModal="showModalDelete"
        :closeModal="closeRemoveUserModal"
        :deleteVisitor="deleteVisitor"
      ></RemoveVisitorModal>
    </template>
  </v-card>
</template>
<script>
import store from "../../store";
import {
  getUserInviteLink,
  sendInviteMailCustom,
  transferVisitor,
} from "../../lib/wsMsg";
import { sendVisitorEmail } from "../../lib/inviteHelpers";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import DeleteBasket from "../ui/deleteBasket.vue"
import { getStatusColorByUser } from "../../utils/status";
import { isWaitingRoomUser } from "../../utils/privileges";
import { dispatchSuccessAlert } from "../../effector/alerts";
import {
  myConferencesStore,
} from "../../effector/conference";
import {
  timelineEvents,
} from "../../effector/timeline";
import { callPerson, dbClickCall, disableCallBtn } from "../../utils/calls";
// import { hasRepresentative } from "../../utils/representatives";
import RemoveVisitorModal from "../modal/removeVisitorModal.vue";
import TransferVisitorModal from "../modal/transferVisitorModal.vue";
import { wholeBarWithColor } from "../../utils/color";
import { deleteVisitor } from "../../utils/waitingRoom";
import moment from "../../../sharedsrc/moment";
import {
  getWebrtcConnections
} from "../../lib/rtcConn";

export default {
  props: ["visitor", "section"],
  components: {
    DefaultAvatar,
    RemoveVisitorModal,
    SendInvitationModal,
    TransferVisitorModal,
    DeleteBasket,
  },
  data() {
    return {
      state: store.state,
      failedImage: false,
      safeWait: false,
      showInvitationModal: false,
      link: "",
      clickStaff: {
        delay: 700,
        clicks: 0,
        timer: null
      },
      showModalDelete: false,
      moment: moment,
      showTransferVisitorModal: false
    };
  },
  effector: {
    myConferencesStore: myConferencesStore,
    timelineEvents: timelineEvents
  },
  methods: {
    async doTransferVisitor(transferTo, transferUUID, visitorType) {
      const result = await transferVisitor(transferTo, transferUUID);
      if (result) {
        const visitor = store.state.group[transferUUID].user;
        if (!visitor) return;

        const extantWaitingRoomUser = await isWaitingRoomUser(transferUUID);
        if (extantWaitingRoomUser && visitorType !== "isWaitingRoom") {
          visitor.visitorData.isWaitingRoom = false;
        }

        delete visitor.visitorData.inviters[store.state.ownUUID];
        visitor.visitorData.inviters[transferTo] = true;
        store.setUserVisitor(transferUUID, visitor.visitorData);
        delete store.state.persisted.userVisitors[transferUUID];
        this.closeTransferVisitorModal();
      }
    },
    closeTransferVisitorModal() {
      this.showTransferVisitorModal = false;
    },
    async getUserInviteLink(uuid) {},
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    closeRemoveUserModal() {
      this.showModalDelete = false;
    },
    getVisitorDate(created) {
      const temporalDate = new Date(created).toUTCString().split(",");
      const temporalDay = this.$t(
        `generics.weekDays.${temporalDate[0].toLowerCase()}`
      );
      const finalResult = temporalDay + "," + temporalDate[1];
      return finalResult;
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    async setShowModalSendInvitation(person) {
      const result = await sendInviteMailCustom(person.visitorId, person);
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.name,
          email: person.email,
          date: new Date()
        }
      };
      this.showInvitationModal = true;
      setTimeout(() => {
        if (this.showInvitationModal) {
          this.showInvitationModal = false;
        }
      }, 2000);
    },
    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    // hasRepresentative(uuid) {
    //   return hasRepresentative(uuid);
    // },
    callUser(uuid) {
      this.clickStaff.clicks++;
      if (this.clickStaff.clicks === 1) {
        this.clickStaff.timer = setTimeout(() => {
          callPerson(uuid, this.$router);
          this.clickStaff.clicks = 0;
        }, this.clickStaff.delay);
      } else {
        clearTimeout(this.timer);
        // dbClickCall(uuid, this.$router);
        this.clickStaff.clicks = 0;
      }
    },
    async copyLink(evt) {
      let instalink = this.visitor.instalink;
      if (!instalink) {
        if (this.link === "") {
          instalink = await getUserInviteLink(this.uuid);
          this.visitor.instalink = instalink;
        } else {
          instalink = this.link;
          this.visitor.instalink = this.link;
        }
      }
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
        this.link = "";
      }, 1000);
    },
    async resolveLink() {
      let instalink = this.visitor.instalink;
      if (!instalink) {
        instalink = await getUserInviteLink(this.uuid);
      }
      this.link = instalink;
    },
    onImgError(event) {
      this.failedImage = true;
    },
    async SendLinkToVisitor() {
      if (((this.state.group[this.uuid] || {}).user || {}).visitor) {
        const result = await sendVisitorEmail(this.visitor);
        if (result) {
          dispatchSuccessAlert(
            this.$t("components.inviteVisitor.invitationLinkSent")
          );
        }
      } else {
        this.setShowModalSendInvitation(this.visitor);
      }
    },
    handleDeleteVisitor() {
      this.showModalDelete = !this.showModalDelete;
      // this.deleteVisitor(this.uuid);
    },
    async deleteVisitor(visId) {
      await deleteVisitor(visId, this.timelineEvents);
    },
    async updateTimeLineEventUsers(id, uuid) {},
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    formatDateToShow(info) {
      // let date = this.getVisitorDate(parseInt(info));
      //info = 1597222424593;
      //let date = 'Mi, 12 Aug 2020 08:53:44 GMT'
      return this.moment(new Date(info)).format("DD.MM.YY, HH:mm");
    }
  },
  computed: {
    getTimeMessage() {
      let dateTo = this.visitor.linkDateFrom;
      let timeTo = this.visitor.linkTimeFrom; // "08:55"
      let duration = this.visitor.duration; // 35
      let d = this.moment(timeTo, "HH:mm")
        .add(duration, "minutes")
        .format("HH:mm");
      return dateTo + " " + timeTo //+ " - " + d;
    },
    personIsConnected() {
      return (this.state.group[this.uuid] || {}).connected || false;
    },
    uuid() {
      return this.visitor.visitorId || this.visitor.uuid;
    },
    hasActivityNoCall() {
      let person = store.state.group[this.uuid];
      if (person && person.user && person.user.activity === "No Calls") {
        return true;
      } else {
        return false;
      }
    },
    borderClass() {
      let person = store.state.group[this.uuid];
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    phoneColorColor() {
      let person = store.state.group[this.uuid];
      if (!person || !person.user) return;
      if (this.wholeBarWithColors()) {
        return "#ffffff";
      } else {
        return getStatusColorByUser(person.user, person.connected);
      }
    },
    badgetClassObj() {
      let person = store.state.group[this.uuid];
      if (!person || !person.user) return;
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(person.user, person.connected);
      } else {
        return "rgba(255,255,255,0.3)";
      }
    },
    disableCallBtn() {
      let person = store.state.group[this.uuid];
      return disableCallBtn(person);
    },
    isInCallWithUs() {
      return (
        !!this.state.rtc[this.uuid] && !!this.state.remoteStreams[this.uuid]
      );
    },
    avatarSrc() {
      if (!this.state.group[this.uuid])
        return "img/default_profile_picture.png";
      if (this.failedImage) return "img/default_profile_picture.png";
      return (
        this.state.group[this.uuid].user.avatar ||
        "img/default_profile_picture.png"
      );
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    }
  }
};
</script>

<style scoped lang="scss">
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.contentWrapper {
  max-width: 200px;
}
.mainUserDiv {
  padding: 0px 10px;
  margin-top: 10px;
  min-height: 40px;
  max-height: 40px;
}
.subSectionUsers {
  .mainUserDiv {
    padding: 0px 20px;
  }
}
.redClass {
  font-size: 26px !important;
  font-weight: 800;
  color: red;
}
.greenClass {
  font-size: 26px !important;
  font-weight: 800;
  color: green;
}
.copypop {
  background-color: white;
  max-width: 700px;
}
.linkContainer {
  margin-top: 20px;
}
.noBRadius {
  border-radius: 0px !important;
  height: 37px !important;
}
.borderRadius10 {
  border-radius: 10px;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.cursorPointer {
  cursor: pointer;
}
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.btnAbsolute {
  position: absolute;
  right: 0;
}
.v-card .v-list-item {
  padding-left: 4px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px !important;
}
.grayScale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.fieldHeader {
  font-weight: 400;
  float: right;
}
.btnr15 {
  margin-right: 15px;
}
.tigth {
  height: 30px;
  max-height: 30px !important;
}
.datePosition {
  position: absolute;
  top: 0;
}
</style>


<template>
  <div class="h100 justVideo"
  >
              <!-- <span v-if="uuid === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->
    <!-- modal to inform the user that he is in a maximum member call -->
    <fullCallModal v-if="fullCall" :fullCall="fullCall" />
    <!-- <div class="callerName">
      <span v-if="uuid">{{ getDataForHeader(uuid) }}</span>
    </div> -->
    <div v-if="state.nerd" class="rtcStatus">
      <div>
        IceConnectionState:
        <span
          v-bind:class="{ green: state.rtc[uuid].iceConnectionState === 'connected' }"
        >{{ state.rtc[uuid].iceConnectionState }}</span>
      </div>
      <div>
        IceGatheringState:
        <span
          v-bind:class="{ green: state.rtc[uuid].iceGatheringState === 'complete' }"
        >{{ state.rtc[uuid].iceGatheringState }}</span>
      </div>
      <div>
        SignalingState:
        <span
          v-bind:class="{ green: state.rtc[uuid].signalingState === 'stable' }"
        >{{ state.rtc[uuid].signalingState }}</span>
      </div>
      <div>
        RingingState:
        <span
          v-bind:class="{ green: state.rtc[uuid].ringingState === 'connected' }"
        >{{ state.rtc[uuid].ringingState }}</span>
      </div>
      <div v-if="activeCandidates">
        ConnectionType:
        <span
          v-bind:class="{ green: !isTurn }"
        >{{ activeCandidates.local.candidateType }} / {{ activeCandidates.remote.candidateType }}</span>
      </div>
      <div
        v-if="this.videoDetailsFirst && this.videoDetailsFirst.width"
      >First: {{ this.videoDetailsFirst.width || 0 }}x{{ this.videoDetailsFirst.height || 0 }}@{{ Math.floor(this.videoDetailsFirst.frameRate || 0) }}</div>
      <div
        v-if="this.videoDetailsSecond && this.videoDetailsSecond.width"
      >Second: {{ this.videoDetailsSecond.width || 0 }}x{{ this.videoDetailsSecond.height || 0 }}@{{ Math.floor(this.videoDetailsSecond.frameRate || 0) }}</div>
      <!-- <div v-if="state.nerd">
        <AudioStreamMeter :stream="remote.first" />
        <AudioStreamMeter :stream="remote.second" />
      </div>-->
    </div>
    <div id="videos" class="videos" ref="videos" @dblclick="fullSreen($event)">
      <!-- <div v-if="IsFullScreen()">
        test {{IsFullScreen()}}
      </div>-->
      <!-- <template v-if="IsFullScreen()">
        <v-card class="mx-auto cardPositionInfo" max-width="300" flat color="transparent">
          <v-card-text class="headline font-weight-bold">
            <v-list-item class="grow opacity">
              <v-list-item-avatar tile size="60">
                <v-img
                  class="elevation-6"
                  :src="getAvatarForUuid(uuid)"
                  lazy-src="assets/default_profile_picture.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{getTitelForUuid(uuid)}} {{getNameForUuid(uuid)}}</v-list-item-title>
                <v-list-item-subtitle class="white--text">{{getPositionForUuid(uuid)}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </template>
      <template v-if="IsFullScreen()">
        <v-card class="mx-auto cardPositionInfoLogo" max-width="300" flat color="transparent">
          <v-card-text class="d-flex align-center opacity">
            <v-img class="mx-2" src="assets/icon.png" max-height="40" max-width="40" contain></v-img>
            <div class="white--text v-toolbar__title font-weight-thin">vOffice</div>
          </v-card-text>
        </v-card>
      </template> -->
      <video
        id="remote-first"
        class="remote-first"
        autoplay="autoplay"
        :src-object.prop.camel="remote.first"
        v-bind:class="{ hidden: !remote.first || !haveRemoteFirstVideo}"
        :muted="isLocalSoundMuted"
        @click.prevent
      ></video>
      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'ringing'">
        <div class="noVideoStreamContent">
          <div class="callAvatarWrapper">
            <v-img
              class="noVideoStreamAvatar"
              contain
              max-width="65"
              max-height="65"
              :src="getAvatarForUuid(uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </div>
          <div>
            <span
              class="callingText"
            >{{ $t('components.remotePersonCall.calling')}} {{ getNameForUuid(uuid) }}</span>
            <div id="wave">
              <div color="primary" class="dot"></div>
              <div color="primary" class="dot"></div>
              <div color="primary" class="dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'accepted'">
        <div class="noVideoStreamContent">
          <span class="callingText">{{ $t('components.remotePersonCall.callInProgress') }}</span>
        </div>
      </div>
      <div class="noVideoStream" v-if="state.rtc[uuid].ringingState === 'connected'">
        <!-- NO VIDEO STREAM -->
        <div class="noVideoStreamContent">
          <div class="callAvatarWrapper">
            <v-img
              class="noVideoStreamAvatar"
              contain
              max-width="65"
              max-height="65"
              :src="getAvatarForUuid(uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </div>
          <div>
            <span
              class="callingText"
            >{{ $t('components.remotePersonCall.inCallWith')}} {{ getNameForUuid(uuid) }}</span>
          </div>
        </div>
      </div>
      <div class="callerName">
      <span v-if="uuid">{{ getDataForHeader(uuid) }}</span>
    </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
// import { getWebrtcConnections } from '../../lib/rtcCall';
import { getWebrtcConnections } from "../../lib/rtcConn";
import {ownerUUID, callInfoStateStore} from "../../effector/call";
import AudioStreamMeter from "./audioStreamMeter.vue";
import fullCallModal from "../modal/fullCallModal.vue";
import VueDragResize from "vue-drag-resize";
import { isVisitor } from '../../utils/privileges';
import {getSubjectVisitor} from "../../utils/basicFunctions.js";
export default {
  components: { AudioStreamMeter, fullCallModal, VueDragResize },
  props: ["uuid", "remote", "isLocalSoundMuted"],
  data() {
    return {
      state: store.state,
      localMute: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0
    };
  },
  effector:{
    ownerUUID:ownerUUID,
    callInfo: callInfoStateStore,
  },
  methods: {
    attachSinkId(element, sinkId) {
      try {
        if (Array.isArray(element)) element = element[0];
        // console.log('remotePersonCallJustVideo attachSinkId', sinkId, element);
        if (!element) {
          console.warn('attachSinkId element is null', element);
          return;
        }
        if (typeof element.sinkId !== "undefined") {
          element
            .setSinkId(sinkId)
            .then(() => {
              // console.log(`Success, audio output device attached: ${sinkId}`);
            })
            .catch(error => {
              let errorMessage = error;
              if (error.name === "SecurityError") {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              // Jump back to first output device in the list as it's the default.
              // if (this.$refs.audioOutput.selectedIndex) this.$refs.audioOutput.selectedIndex = 0;
            });
        } else {
          console.warn("Browser does not support output device selection.");
        }
      } catch (err) {
        console.warn('attachSinkId err', err);
      }
    },
    changeAudioDestination() {
      if (this.$refs.remoteFirst) this.attachSinkId(this.$refs.remoteFirst, this.audioDestination);
      // this.attachSinkId(this.$refs.remoteSecond, this.audioDestination);
    },
    // ShowTicketEntry(assignee) {
    //   console.log("show ticket entry ", this.state.showTicketEntry, assignee);
    //   this.state.currentContentVisile.showComponent = true;
    //   this.state.currentContentVisile.component = "ticketEntry";
    //   this.state.showTicketEntry = true;
    //   this.state.ticket.t_assignee = assignee;
    // },
    fullSreen(event) {
      if (!this.IsFullScreen()) {
        // const elem = event.target;
        const elem = this.$refs.videos;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().catch(() => {});
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },

    handleResize() {
      const el = this.$refs.videos;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },

    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getPositionForUuid(uuid) {
      return store.getPositionForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
       getTitelForUuid(uuid) {
      if (store.getTitelForUuid(uuid) !== "unknown") {
        return store.getTitelForUuid(uuid);
      } else {
        return "";
      }
    },
    getEmailForUuid(uuid) {
       return store.getEmailForUuid(uuid);
    },
    getDataForHeader(uuid) {
      // FIXME: dont hardcode first item here once we have decent section/location/team logic
      let userList = store.getProcessedUserList();
      let data = [];
      // if (
      //   userList[uuid] &&
      //   userList[uuid].teamSections &&
      //   userList[uuid].teamSections[0]
      // ) {
      //   data.push(userList[uuid].teamSections[0]);
      // }
      if (this.state.group[uuid].user.locations) {
        data.push(this.state.group[uuid].user.locations[0]);
      }
      if (
        userList[uuid] &&
        userList[uuid].memberOfTeams &&
        userList[uuid].memberOfTeams[0]
      ) {
        data.push(userList[uuid].memberOfTeams[0]);
      }
      if(isVisitor(uuid)){
        data.push(this.$t('components.userSettings.guest'))
      }
      if (this.state.group[uuid].user.name) {
        let titel = ''
        if(this.state.group[uuid].user.titel && this.state.group[uuid].user.titel !== 'unknown'){
          titel = this.state.group[uuid].user.titel + ' '
        }
        let name = titel + this.state.group[uuid].user.name
        data.push(name);
      }
      //    if(isVisitor(uuid)){
      //   data.push(getSubjectVisitor(uuid))
      // }
      if(uuid === this.ownerUUID){
        data.push('Moderator')
      }
        data.push('P2P')
      // if (this.state.group[uuid].user.sections) {
      //   data.push(this.state.group[uuid].user.sections[0]);
      // }
      // if (this.state.group[uuid].user.locations) {
      //   data.push(this.state.group[uuid].user.locations[0]);
      // }
      // if (this.state.group[uuid].user.teams) {
      //   data.push(this.state.group[uuid].user.teams[0]);
      // }
      // if (this.state.group[uuid].user.name) {
      //   data.push(this.state.group[uuid].user.name);
      // }

      return data.filter(Boolean).join(" - ");
    },
    toggleLocalMute() {
      this.localMute = !this.localMute;
    }
  },
  computed: {
    stats() {
      const o = {};
      const s = this.state.rtc[this.uuid].stats || [];
      // console.log('Stats', s.entries());
      s.forEach(d => (o[d.id] = d));
      return o;
    },
    activeCandidatePair() {
      return Object.values(this.stats).find(
        e =>
          e.type === "candidate-pair" && e.state === "succeeded" && e.nominated
      );
    },
    activeCandidates() {
      if (!this.activeCandidatePair) return;
      return {
        local: this.stats[this.activeCandidatePair.localCandidateId],
        remote: this.stats[this.activeCandidatePair.remoteCandidateId]
      };
    },
    isTurn() {
      if (!this.activeCandidates) return false; // Assume no till we know
      if (
        this.activeCandidates.local.candidateType === "relay" ||
        this.activeCandidates.remote.candidateType === "relay"
      )
        return true;
      return false;
    },
    fullCall() {
      return this.state.isCallFull;
    },
    haveRemoteFirstVideo() {
      if (!this.remote) return false;
      if (!this.remote.first) return false;
      for (const track of this.remote.first.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    videoDetailsFirst() {
      try {
        if (!this.remote) return;
        if (!this.remote.first) return;
        if (!this.state.currentTS) return; // For the ref
        return this.remote.first.getVideoTracks()[0].getSettings();
      } catch (err) {
        console.warn("videoDetailsFirst Err:", err.message);
      }
    },
    videoDetailsSecond() {
      try {
        if (!this.remote) return;
        if (!this.remote.second) return;
        if (!this.state.currentTS) return; // For the ref
        return this.remote.second.getVideoTracks()[0].getSettings();
      } catch (err) {
        console.warn("videoDetailsSecond Err:", err.message);
      }
    }
    // isFirstAudioMuted() {
    //   if (!this.remote.first) return false;
    //   for (const track of this.remote.first.getTracks()) {
    //     if (track.kind === 'audio') return !track.enabled;
    //   }
    //   return false;
    // },
  },
  watch: {
    audioDestination() {
      this.changeAudioDestination();
    },
  },
  mounted() {
    this.changeAudioDestination();
  },
};
</script>

<style scoped lang="scss">
.justVideo{
  position: absolute !important;
  bottom: 30px;
  max-width: 350px;
  width: 100%;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.cardPositionInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: auto;
}
.cardPositionInfoLogo {
  position: absolute;
  left: 0;
  right: unset;
  bottom: 5px;
  margin: auto;
}
.opacity {
  opacity: 0.4;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video {
  display: flex;
  background-color: black;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.h100 {
  height: calc((100% / 4) - 30px);
  z-index: 0;
  position: relative;
}

.oneRemote{
  height: calc(100% - 24px);
  z-index: 0;
  position: relative;
}
.twoRemote{
   height: calc((100% - 24px) / 2);
  z-index: 0;
  position: relative;
}
.threeRemote{
 height: calc((100% - 24px) / 3);
  z-index: 0;
  position: relative;
}
.callerName {
  // position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  z-index: 99999999;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.rtcStatus {
  font-size: 10px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: #000000b5;
  border-radius: 3px;
  left: 5px;
  bottom: 5px;
  color: white;
}
.green {
  color: green;
}

.theme--light .noVideoStreamContent{
  background-color: #f2f2f2 !important;
}

.theme--dark .noVideoStreamContent{
  background-color: #272727 !important;
}

.videos {
  position: relative;
  height: 100%;
  width: 100%;
  .divButtons {
    display: block;
    position: absolute;
    text-align: center;
    width: 30px;
    height: auto;
    justify-content: center;
    left: 25px;
    top: calc(50% - 90px);
    .redBtn {
      background: red;
      color: white !important;
      border-radius: 4px !important;
    }
  }
  // &:hover {
  //   > .divButtons {
  //     display: block;
  //   }
  // }
  .remote-first {
    width: 100%;
    height: 100%;
    max-height: unset;
    &.sharing {
      position: absolute;
      bottom: 0;
      width: 350px;
      left: 0;
      height: auto;
      animation: width 0.7s linear;
    }
  }
  .remote-second {
    width: 100%;
    height: 100%;
    max-height: unset;
  }
  .noVideoStream {
    position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noVideoStreamContent {
      display: table-cell;
      vertical-align: middle;
      background: #f2f2f2f2;
    }
    .noVideoStreamAvatar {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin: -10px auto 0px auto;
      padding-bottom: 0px !important;
    }
    .v-avatar .v-image {
      border-radius: 10px !important;
    }
    .callAvatarWrapper {
      padding-bottom: 0px;
      img,
      .v-avatar .v-image {
        width: 65px;
        height: 65px;
        border: 4px solid white;
        margin-bottom: 10px !important;
        border-radius: 10px !important;
      }
    }
  }
  .callingText {
    font-weight: normal;
    font-size: 15px;
  }
  #wave {
    margin-top: -15px;
    .dot {
      background: #02889d;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
    }
    .dot:nth-child(2) {
      animation-delay: -1.1s;
    }
    .dot:nth-child(3) {
      animation-delay: -0.9s;
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}
 .videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: .06em black;
  font-size: 1em;
  font-family: verdana;
}


.buttonsUser {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  padding-top: 7px !important;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  .pUserName {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 5px;
    color: white;
  }
  .divButtonsLocal {
    button {
      margin-right: 5px;
      color: white;
      // background: #02889D;
      // border-radius: 4px;
      &:last-of-type {
        margin-right: 0px;
      }
      &.redBtn {
        // background: red;
      }
    }
  }
}
</style>
<template>
  <div :class="{ buttonMoreActionsGrid: isRegularGrid }" class="menuWrapper">
    <v-menu v-model="showMenu" offset-y scrollable :close-on-click="true">
      <template v-slot:activator="{ on }">
        <v-btn
          :id="
            isRegularGrid
              ? `userActionList-${getPersonUUID}`
              : `userActionsList-${getPersonUUID}`
          "
          v-if="!isRegularGrid"
          :color="
            customStyle ? (wholeBarWithColors() ? 'white' : 'black') : null
          "
          icon
          v-on="on"
          :class="`${customStyle ? 'btnAbsolute' : null} ${
            isRegularGrid ? 'buttonCallFrontCard' : ''
          }`"
        >
          <font-awesome-icon
            :icon="['fal', 'ellipsis-v']"
            :style="{ fontSize: isRegularGrid ? '20px' : '30px' }"
          />
        </v-btn>
        <div
          :id="
            isRegularGrid
              ? `userActionList-${getPersonUUID}`
              : `userActionsList-${getPersonUUID}`
          "
          v-if="isRegularGrid"
          v-on="on"
          class="spanMoreActions"
        >
          <font-awesome-icon
            :icon="['fal', 'ellipsis-v']"
            class="iconMoreActions"
          />
        </div>
      </template>
      <v-list >
        <template v-if="typeData !== 'waitingroom'">
          <v-list-item v-if="showCallTeam" @click="callTeam()">
            <v-list-item-title>{{
              $t("components.userListItem.callTeam")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              isRegularGrid &&
              (person.connected && person.user.activity !== 'No status' ) &&
              getPersonUUID != state.ownUUID
            "
            @click="callUser(getPersonUUID)"
            :disabled="disableCallBtn"
          >
            <v-list-item-title>{{
              $t("components.notificationsModal.callUser")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isRegularGrid && getPersonUUID != state.ownUUID"
            @click="sendMessage(getPersonUUID)"
          >
            <v-list-item-title>{{
              $t("components.userProfile.sendAMessage")
            }}</v-list-item-title>
          </v-list-item>
          <!-- START DAILY REPORT -->
          <v-list-item
            v-if="isRegularGrid && isOrga && showDailyReport"
            @click="goToDailyReport(getPersonUUID)"
          >
            <v-list-item-title>{{
              $t("components.adminSettingsModal.dailyReport")
            }}</v-list-item-title>
          </v-list-item>
          <!-- END DAILY REPORT -->
          <v-list-item
            v-if="isRegularGrid && typeData !== 'guests' && !isMobile"
            @click="goToOrganisation(getPersonUUID)"
          >
            <v-list-item-title>{{
              $t("components.organisationCarousel.showInOrganigram")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              isRegularGrid &&
              !isBasicView &&
              showUploadFile &&
              person.connected &&
              !isMobile
            "
            @click="
              getPersonUUID !== state.ownUUID
                ? sendFileTo(getPersonUUID)
                : openDropdown()
            "
          >
            <v-list-item-title>{{
              getPersonUUID !== state.ownUUID
                ? $t("generics.uploadFileMax50")
                : $t("components.drawerContentCard.files")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              (!personIsGuest && getPersonUUID && !isreachable)
            "
            @click="toggleMessageMeRequest(getPersonUUID)"
          >
            <v-list-item-title>{{
              $t("components.userListItem.callWhenAvailable")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getPersonUUID != state.ownUUID"
            @click="sendCallBackMessage(person)"
          >
            <v-list-item-title>{{
              $t("components.userListItem.callBackTT")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              !isOrga &&
              isRegularGrid &&
              !personIsGuest &&
              person.user &&
              showHelpscoutButton &&
              showVisitPage
            "
            @click="openHelpsCoutModal"
          >
            <v-list-item-title>Helpscout</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="state.nerd && getPersonUUID != state.ownUUID"
            @click="personPing(person)"
            :disabled="!person.connected"
          >
            <v-list-item-title>{{
              $t("components.userListItem.pingUser")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="getPersonUUID != state.ownUUID"
            target="_blank"
            :href="`mailto:${person.user.email}?subject=${state.user.name}%20from%20vOffice&body=${emailSalutation}%20${person.user.name},`"
          >
            <v-list-item-title>{{
              $t("components.userListItem.sendEMail")
            }}</v-list-item-title>
          </v-list-item>
          <!-- copy user link -->
          <v-list-item
            v-if="amIAdmin"
            @click="copyUserLink($event, getPersonUUID)"
          >
            <v-list-item-title>{{ $t("generics.copylink") }}</v-list-item-title>
          </v-list-item>
          <!-- End copy link -->
          <v-list-item
            v-if="!isFavorite && getPersonUUID != state.ownUUID"
            @click="setPersonFav(person)"
          >
            <v-list-item-title>{{
              $t("components.userListItem.addToMyFavorites")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isFavorite"
            @click="
              personIsGuest ? (deleteFavorite = true) : removePersonFav(person)
            "
          >
            <v-list-item-title>{{
              $t("components.userListItem.removeFromMyFavorites")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="amIAdmin"
            class="pingButton"
            @click="setShowModalSendInvitation(person)"
          >
            <v-list-item-title>
              <span v-if="person.user.accessmail"
                >{{ $t("components.userListItem.sendAccessLink") }} &nbsp;
                {{
                  person.user.accessmail === "0"
                    ? ""
                    : "(" + person.user.accessmail + ")"
                }}</span
              >
              <span v-if="!person.user.accessmail">{{
                $t("components.userListItem.sendAccessLink")
              }}</span>
              <font-awesome-icon
                v-show="hasBeenOnPage"
                :icon="['fal', 'check-double']"
              />
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canRemoveGuest && getPersonUUID != state.ownUUID"
            @click="setShowDeleteUser"
          >
            <v-list-item-title>
              {{
                section === "My Guests"
                  ? $t("components.userListItem.removeGuest")
                  : $t("components.userListItem.removeUser")
              }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="amIAdmin && typeData !== 'guests'"
            @click="changeFunction(person)"
          >
            <v-list-item-title>{{
              $t("components.userListItem.changeFunction")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setUserInModal">
            <v-list-item-title>{{
              $t("components.adminSettingsModal.userInfo")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              !amIGuest &&
              (getPersonUUID === ownUUID || amIAdmin) &&
              typeData !== 'guests'
            "
            @click="toggleShowHolidaysModal"
          >
            <v-list-item-title>{{
              $t("components.holidays.setYourHolidays")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="amIAdmin && !isOrga">
            <v-list-item-content class="py-0 my-0">
              <v-switch
                class="my-0 py-2"
                @change="changeUserVisibility(getPersonUUID)"
                v-model="hideShowUser"
                :label="$t('components.userListItem.hideShowUser')"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-tooltip top >
            <template v-slot:activator="{ on }">
              <v-list-item
                v-on="on"
                v-if="amIAdmin && getPersonUUID != state.ownUUID"
                @click="disable2fa(getPersonUUID)"
              >
                <v-list-item-title>{{
                  $t("components.userListItem.disable2fa")
                }}</v-list-item-title>
              </v-list-item>
            </template>
            <span>{{ $t("components.userListItem.twofaTT") }}</span>
          </v-tooltip>
            <v-list-item
            v-if="isOrga && isEditableView && (isMovingDepa || isMovingTeam)"
            class="cursorPointer"
            @click="handlerMove"
          >
            <v-list-item-title>
             {{ isMovingDepa ? $t('components.manageOrganisation.department.move') : isMovingTeam ? $t('components.manageOrganisation.team.move') : ''}}
            </v-list-item-title>
          </v-list-item>
        </template>
        <!-- START LIST FOR WAITING ROOM -->
        <template v-if="typeData === 'waitingroom'">
          <v-list-item
            v-if="
              person.connected &&
              hasPrivilege(state.ownUUID) &&
              getPersonUUID !== state.ownUUID
            "
            @click="
              getPersonUUID !== state.ownUUID
                ? callUser(getPersonUUID)
                : callUser(person.user.visitorData.userInviter)
            "
            :disabled="disableCallBtn"
          >
            <v-list-item-title>{{
              hasPrivilege(getPersonUUID)
                ? $t("components.notificationsModal.callUser")
                : $t("components.userListItem.callGuest")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              !person.user.conferenceTitle &&
              getPersonUUID !== state.ownUUID &&
              !hasPrivilege(getPersonUUID)
            "
            class="cursorPointer"
          >
            <v-list-item-title @click="deleteVisitor(getPersonUUID)">
              {{ $t("generics.deleteGuest") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              !hasPrivilege(getPersonUUID) && getPersonUUID != state.ownUUID
            "
            @click="sendMessage(getPersonUUID)"
          >
            <v-list-item-title>{{
              $t("components.userProfile.sendAMessage")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="getPersonUUID !== state.ownUUID"
            @click="SendLinkToUserOrVisitor(person)"
          >
            <v-list-item-title>{{
              $t("components.inviteVisitor.resendInvLink")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              showUploadFile &&
              person.connected &&
              getPersonUUID !== state.ownUUID
            "
            @click="
              getPersonUUID !== state.ownUUID
                ? sendFileTo(getPersonUUID)
                : sendFileTo(person.user.visitorData.userInviter)
            "
          >
            <v-list-item-title>
              {{ $t("generics.uploadFileMax50") }}
            </v-list-item-title>
          </v-list-item>
          <!-- copy user link -->
          <v-list-item @click="copyVisitorLink($event, person)">
            <v-list-item-title>{{ $t("generics.copylink") }}</v-list-item-title>
          </v-list-item>
          <!-- End copy link -->
          <v-list-item
            v-if="
              !hasPrivilege(getPersonUUID) &&
              !isConferenceVisitor(getPersonUUID)
            "
            class="cursorPointer"
            @click="showTransferVisitorModal = true"
          >
            <v-list-item-title>{{
              $t("components.inviteVisitor.transferVisitor")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="hasPrivilege(getPersonUUID)"
            class="cursorPointer"
            @click="setGuestInModal"
          >
            <v-list-item-title>{{
              $t("components.adminSettingsModal.userInfo")
            }}</v-list-item-title>
          </v-list-item>
        
          
        </template>
        <!-- END LIST FOR WAITING ROOM -->
      </v-list>
    </v-menu>
    <template>
      <DeviceSetupModal
        v-if="showCamSettings"
        :showSetupDevice="true"
        :toggleModalSettings="closeMicModal"
      />
    </template>
    <template>
      <SendInvitationModal
        v-if="showInvitationModal"
        :closeShowInviteInfo="closeShowInviteInfo"
        :showInviteInfo="showInvitationModal"
      />
    </template>
    <template v-if="deleteUser">
      <RemoveUserModal
        :person="person"
        :showModal="deleteUser"
        :closeModal="setShowDeleteUser"
      ></RemoveUserModal>
    </template>
    <template v-if="deleteFavorite">
      <RemoveFromFavoriteModal
        :person="person"
        :showModal="deleteFavorite"
        :closeModal="closeDeleteFavoriteModal"
      ></RemoveFromFavoriteModal>
    </template>
    <template>
      <ChangeFunctionModal
        :modalData="changeUserFunction"
        :cancelChangeFunction="cancelChangeFunction"
        v-if="changeUserFunction.show"
      ></ChangeFunctionModal>
    </template>
    <template>
      <template v-if="openHolidaysCalendar">
        <HolidaysCalendarModal
          :userUUID="getPersonUUID"
          :closeModal="toggleShowHolidaysModal"
          :showModal="openHolidaysCalendar"
        ></HolidaysCalendarModal>
      </template>
      <template>
        <TransferVisitorModal
          v-if="showTransferVisitorModal"
          :closeTransferVisitorModal="closeTransferVisitorModal"
          :showTransferVisitorModal="showTransferVisitorModal"
          :doTransferVisitor="doTransferVisitor"
          :visitor="person"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import { isMobile } from "../../lib/mobileUtil";
import { v4 as uuidv4 } from "uuid";
import store, { EventBus } from "../../store";
import { wsCall } from "../../lib/wsConnect";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import RemoveUserModal from "../modal/removeUserModal.vue";
import RemoveFromFavoriteModal from "../modal/removeFromFavoriteModal.vue";
import { wholeBarWithColor } from "../../utils/color";
import {
  callPerson,
  wsCallStartBridgeCall,
  userInCallText,
  disableCallBtn,
} from "../../utils/calls";
import ChangeFunctionModal from "../modal/changeFunctionModal.vue";
import SetLanguageModal from "../modal/setLanguageModal.vue";
import UserProfle from "../modal/userProfile.vue";
import HolidaysCalendarModal from "../modal/holidaysCalendarModal.vue";
import {
  setUserProfileEvent,
  setHistoryMessagesModalEvent,
  setDailyReportSettingsModalEvent
} from "../../effector/modals";
import { newMessageEvent } from "../../effector/message";
import { timelineEvents } from "../../effector/timeline";
import { dispatchSuccessAlert } from "../../effector/alerts";
import { deleteVisitor } from "../../utils/waitingRoom";
import TransferVisitorModal from "../modal/transferVisitorModal.vue";
import {
  isWaitingRoomUser,
  hasPrivilege,
  isConferenceVisitor,
} from "../../utils/privileges";
import DeviceSetupModal from "../modal/deviceSetupModal.vue";
import { sendVisitorEmail } from "../../lib/inviteHelpers";
import { newPresenceWatchingEvent } from "../../effector/presence";
import { userHasHolidaysSetted, isDailyReportEnabled } from "../../utils/basicFunctions";
import {
  sendNotificationToUUID,
  getUserInviteLink,
  setRemoteUser,
  transferVisitor,
  sendInviteMailCustom,
} from "../../lib/wsMsg";
export default {
  components: {
    SendInvitationModal,
    RemoveUserModal,
    RemoveFromFavoriteModal,
    ChangeFunctionModal,
    UserProfle,
    HolidaysCalendarModal,
    TransferVisitorModal,
    DeviceSetupModal,
    SetLanguageModal,
  },
  props: [
    "person",
    "section",
    "customStyle",
    "isRegularGrid",
    "isBasicView",
    "showUploadFile",
    "sendFileTo",
    "openDropdown",
    "showHelpscoutButton",
    "showVisitPage",
    "openHelpsCoutModal",
    "checkSendMessage",
    "typeData",
    "isOrga",
    "sectionData",
    "checkMove",
    "isEditableView",
    "isMovingDepa",
    "isMovingTeam",
    "nameSectionToMove",
    "parentToMove",
    "moveType",
    "teamInfo"
  ],
  data() {
    return {
      isMobile: isMobile,
      setCurrentContentVisile: store.setCurrentContentVisile,
      state: store.state,
      ownUUID: store.state.ownUUID,
      showInvitationModal: false,
      deleteUser: false,
      deleteFavorite: false,
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      openHolidaysCalendar: false,
      hideShowUser: false,
      showMenu: false,
      showTransferVisitorModal: false,
      showCamSettings: false,
    };
  },
  effector: {
    timelineEvents: timelineEvents,
  },
  watch: {
    checkVisibility: {
      immediate: true,
      handler: function (value) {
        this.hideShowUser = value || false;
      },
      deep: true,
    },
  },
  methods: {
    handlerMove(){
      console.log('Check Move ! ')
      if ( this.checkMove){
        let classListTo = this.isMovingDepa ? 'sectionsDrag' : 'departmentsDrag'
        this.checkMove(classListTo, this.nameSectionToMove, this.parentToMove, this.moveType)
      }
    },
    async copyVisitorLink(evt, visitor) {
      let instalink = visitor.instalink;
      if (!instalink) {
        instalink = await getUserInviteLink(visitor.user.uuid);
        visitor.instalink = instalink;
      }
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    toggleMessageMeRequest(uuid) {
      newPresenceWatchingEvent(uuid);
    },
    sendMessage(uuid) {
      if (this.checkSendMessage) {
        this.checkSendMessage(uuid);
      } else {
        setHistoryMessagesModalEvent(uuid);
      }
    },
    goToDailyReport(){
      if (this.sectionData) {
        if (this.teamInfo) {
          // for users
          setDailyReportSettingsModalEvent({showModal: true, type:'members', value: this.getPersonUUID});
        } else {
          setDailyReportSettingsModalEvent({showModal: true, type:this.sectionData.type, value:this.sectionData.name});
        }
      }
    },
    async SendLinkToUserOrVisitor(person) {
      if (person.user && person.user.visitorData) {
        const result = await sendVisitorEmail(person.user.visitorData);
        if (result) {
          dispatchSuccessAlert(
            this.$t("components.inviteVisitor.invitationLinkSent")
          );
        }
      } else {
        this.setShowModalSendInvitation(person);
      }
    },
    closeMicModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      this.showCamSettings = false;
      if (
        store.state.user.visitorData.isImmediate &&
        !store.state.user.visitorData.userLastCallTs
      ) {
        this.showModal = true;
        const uuid = this.state.user.visitorData.userInviter;
        if (uuid) {
          this.callInviter();
        }
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    isConferenceVisitor(uuid) {
      return isConferenceVisitor(uuid);
    },
    async doTransferVisitor(transferTo, transferUUID, visitorType) {
      const result = await transferVisitor(transferTo, transferUUID);
      if (result) {
        const visitor = store.state.group[transferUUID].user;
        if (!visitor) return;

        const extantWaitingRoomUser = await isWaitingRoomUser(transferUUID);
        if (extantWaitingRoomUser && visitorType !== "isWaitingRoom") {
          visitor.visitorData.isWaitingRoom = false;
        }

        delete visitor.visitorData.inviters[store.state.ownUUID];
        visitor.visitorData.inviters[transferTo] = true;
        store.setUserVisitor(transferUUID, visitor.visitorData);
        delete store.state.persisted.userVisitors[transferUUID];
        this.closeTransferVisitorModal();
      }
    },
    closeTransferVisitorModal() {
      this.showTransferVisitorModal = false;
    },
    async deleteVisitor(visId, person) {
      await deleteVisitor(visId, this.timelineEvents);
    },
    handlerMenu() {
      this.showMenu = false;
    },
    goToOrganisation(uuid) {
      // go to the user organisation
      this.setCurrentContentVisile("organisation/" + uuid, true, this.$router);
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    callTeam() {
      let connectedMembers = [];
      const id = uuidv4();
      if (
        this.state.namespaceSettings.processedOrganisation.teams[
          this.sectionData.name
        ] &&
        this.state.namespaceSettings.processedOrganisation.teams[
          this.sectionData.name
        ].supervisors.length > 0
      ) {
        const supervisors =
          this.state.namespaceSettings.processedOrganisation.teams[
            this.sectionData.name
          ].supervisors;
        supervisors.forEach((supervisor) => {
          if (
            this.state.ownUUID !== supervisor.uuid &&
            this.state.group[supervisor.uuid] &&
            this.state.group[supervisor.uuid].connected
          ) {
            if (connectedMembers.indexOf(supervisor.uuid) === -1) {
              connectedMembers.push(supervisor.uuid);
            }
          }
        });
      }
      this.sectionData.data.members.forEach((user) => {
        if (
          this.state.ownUUID !== user &&
          this.state.group[user] &&
          this.state.group[user].connected
        ) {
          if (connectedMembers.indexOf(user) === -1) {
            connectedMembers.push(user);
          }
        }
      });

      if (connectedMembers.length > 0) {
        connectedMembers.forEach((user, idx) => {
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: true,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: Math.floor(60000 * 20),
            infiniteCall: false,
          };
          wsCallStartBridgeCall(
            user,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        });
      }
      const info = {
        callUUID: id,
        calling: connectedMembers,
        isNormalMode: true,
        initiator:
          this.checkInitiator === "" ? this.state.ownUUID : this.checkInitiator,
        callStartTs: Date.now(),
        callDurationMs: Math.floor(60000 * 20),
        infiniteCall: false,
      };
      store.addRemoteBridgeStream(id, info);
      store.setCurrentContentVisile("", false, this.$router);
    },
    sendCallBackMessage(person) {
      const title = this.state.user.titel && this.state.user.titel !== "unknown"
        ? this.state.user.titel
        : "";
      const text = this.$t("components.userListItem.callBackMessage", [
        `${title} ${this.state.user.name}`.trim(),
      ]);
      const header = {
        translateMessage: {
          translateKey: "components.userListItem.callBackMessage",
          params: [`${title} ${this.state.user.name}`.trim()],
        },
      };
      this.preparedataToSendMsgEffector(this.getPersonUUID, text, header);
    },
    preparedataToSendMsgEffector(id, text, header) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: text,
        header: header,
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
    changeUserVisibility(uuid) {
      setRemoteUser(uuid, { hidedByAdmin: this.hideShowUser });
    },
    toggleShowHolidaysModal() {
      this.openHolidaysCalendar = !this.openHolidaysCalendar;
    },
    toggleUserProfile() {
      setUserProfileEvent(true);
    },
    setUserInModal() {
      //open modal if im not guest
      store.state.ownModalUser = this.getPersonUUID === this.state.ownUUID;
      store.state.userOnModal = this.person.user;
      store.state.userOnModal.connected = this.person.connected;
      store.state.userOnModal.uuid = this.getPersonUUID;
      store.state.userOnModal.userTS = this.person.userTS;
      this.toggleUserProfile();
    },
    setGuestInModal() {
      store.state.ownModalUser =
        this.person.user.visitorData.visitorId === this.state.ownUUID;
      store.state.userOnModal = this.person.user;
      store.state.userOnModal.connected = this.person.connected;
      store.state.userOnModal.uuid = this.person.user.visitorData.visitorId;
      store.state.userOnModal.userTS = this.person.userTS;
      this.toggleUserProfile();
    },
    changeFunction(person) {
      this.changeUserFunction = {
        show: true,
        person: person,
      };
    },
    cancelChangeFunction() {
      this.changeUserFunction = {
        show: false,
        person: undefined,
      };
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    setShowDeleteUser() {
      this.deleteUser = !this.deleteUser;
    },
    disable2fa(uuid) {
      return setRemoteUser(uuid, {}, { "2fa": { disable: true } }).then(() => {
        dispatchSuccessAlert(
          `${this.$t("components.userListItem.twofaToaster")}`
        );
      });
    },
    removePersonFav: function (person) {
      store.setUuidInFavorites(this.getPersonUUID, undefined);
    },
    closeDeleteFavoriteModal() {
      this.deleteFavorite = false;
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    async setShowModalSendInvitation(person) {
      await sendInviteMailCustom(person.uuid, { ...person.user, subject: this.$t('components.invitesText.defaultUserSubject', person.user.language || this.defaultEmailLanguage, [this.virtualOfficePrefix]) })
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.user.name,
          email: person.user.email,
          date: new Date(),
        },
      };
      this.showInvitationModal = true;
      setTimeout(() => {
        if (this.showInvitationModal) {
          this.showInvitationModal = false;
        }
      }, 2000);
    },
    setPersonFav: function (person) {
      store.setUuidInFavorites(this.getPersonUUID, true);
    },
    async copyUserLink(evt, uuid) {
      let instalink = ((this.state.group[uuid] || {}).user || {}).visitor
        ? await getUserInviteLink(uuid)
        : await getUserInviteLink(uuid);
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    async copyLink(evt, uuid) {
      let instalink = await getUserInviteLink(uuid);
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    personPing: function (person) {
      wsCall("sendToUUID", this.getPersonUUID, { type: "ping" });
      sendNotificationToUUID(this.getPersonUUID, { type: "ping" });
    },
    getCallText(person) {
      return userInCallText(person);
    },
  },
  computed: {
    isDailyReportEnabled(){
      return isDailyReportEnabled()
    },
    showDailyReport(){
      if (!this.isDailyReportEnabled || !this.sectionData) return false;
      const organisation = store.state.namespaceSettings.processedOrganisation;
      const apartType = this.sectionData.type;
      const apartName = this.sectionData.name;
      if (apartType == 'teams') {
        // check if my user is supervisor of the team
        const supervisors = organisation.teams[apartName].supervisors.map(e => e.uuid)
        if (supervisors.indexOf(this.state.ownUUID) !== -1) return true;
      } else if (apartType == 'sections') {
        // check if my user is supervisor of the team
        const supervisors = organisation.sections[apartName].supervisors.map(e => e.uuid)
        if (supervisors.indexOf(this.state.ownUUID) !== -1) return true;
      } else if (apartType == 'members' && this.teamInfo) {
        const supervisorsTeam = organisation.teams[this.teamInfo].supervisors.map((e) => { return e.uuid});
        if (supervisorsTeam.indexOf(this.ownUUID) !== -1) return true;
      }
      return false;
    },
    defaultEmailLanguage() {
      return (webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer)
        ? "de"
        : "en";
    },
    virtualOfficePrefix() {
      return store.state.namespaceSettings.companyInfo.virtualOfficePrefix || store.state.namespaceSettings.companyInfo.company_name || store.getVirtualOfficePrefix();
    },
    userInHolidays() {
      return userHasHolidaysSetted(this.getPersonUUID);
    },
    checkVisibility() {
      return ((store.state.group[this.getPersonUUID] || {}).user || {}).hidedByAdmin;
    },
    disableCallBtn() {
      return disableCallBtn(this.person);
    },
    getPersonUUID() {
      return this.person.uuid || this.person.user.uuid;
    },
    isreachable() {
      let reportingStatus = [
        "Out of Office",
        "Only phone",
        "No Calls",
        "Break",
        "No status",
        "inCall",
      ];
      let isReachable = true;
      if (!this.person.connected || this.person.user.activity === 'No status' || (!this.person.connected && this.person.user.activity === 'Holidays')) {
        isReachable = false;
      } else {
        if (
          reportingStatus.indexOf(this.person.user.activity) !== -1 ||
          this.getCallText(this.person)
        ) {
          isReachable = false;
        } else {
          isReachable = true;
        }
      }
      return isReachable;
    },
    showCallTeam() {
      if (
        this.isRegularGrid &&
        this.getPersonUUID != this.state.ownUUID &&
        this.isOrga &&
        this.sectionData &&
        this.sectionData.type === "teams" &&
        this.anyUserConnected
      ) {
        return true;
      }
      return false;
    },
    anyUserConnected() {
      for (let i = 0; i < this.sectionData.data.members.length; i++) {
        const uuid = this.sectionData.data.members[i];
        if (uuid !== this.state.ownUUID) {
          if (this.state.group[uuid] && this.state.group[uuid].connected) {
            return true;
          }
        }
      }
      if (
        this.state.namespaceSettings.processedOrganisation.teams &&
        this.state.namespaceSettings.processedOrganisation.teams[
          this.sectionData.name
        ] &&
        this.state.namespaceSettings.processedOrganisation.teams[
          this.sectionData.name
        ].supervisors
      ) {
        const supervisors =
          this.state.namespaceSettings.processedOrganisation.teams[
            this.sectionData.name
          ].supervisors;
        supervisors.forEach((supervisor) => {
          if (
            this.state.group[supervisor.uuid] &&
            this.state.group[supervisor.uuid].connected
          ) {
            return true;
          }
        });
      }
      return false;
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    canRemoveGuest() {
      const inviters = this.person.user.inviters;
      if (
        this.amIAdmin ||
        (inviters && inviters[this.state.ownUUID]) ||
        this.section === "My Guests"
      ) {
        return true;
      }
    },
    hasBeenOnPage() {
      return this.person.user.rtcCallStart !== undefined;
    },
    isFavorite() {
      return this.state.persisted.favoriteUsers[this.getPersonUUID];
    },
    personIsGuest() {
      return this.getPersonUUID && store.getUserIsGuest(this.getPersonUUID);
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    emailSalutation() {
      return encodeURIComponent(
        this.$t("components.userListItem.emailSalutation")
      );
    },
  },
  mounted() {
    EventBus.$on("closeMenu", this.handlerMenu);
  },
  destroyed() {
    EventBus.$off("closeMenu", this.handlerMenu);
  }
};
</script>
<style lang="scss">
  .v-menu__content{
    margin-top: 0 !important;
  }
</style>
<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.spanMoreActions {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  text-align: center;
  .iconMoreActions {
    font-size: 22px;
    color: #ffffff;
  }
}
.btnAbsolute {
  position: absolute;
  right: 0;
  top: 0;
}
.buttonCallFrontCard {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
</style>
import store from '../store';
import { hasRepresentative } from './representatives';
import {
  personIsInBridgeCall,
} from "./calls";
import { userHasHolidaysSetted } from './basicFunctions'
export function colorStatus(status) {
  switch (status) {
    case 'Available':
      return '#008000';
    case 'Only phone':
      return '#ff0000';
    case 'Out of Office':
      return '#ff0000';
    case 'Busy':
      return '#ffa500';
    case 'inCall':
      return '#ffa500';
    case 'Just urgent calls':
      return '#ffa500';
    case 'No Calls':
      return '#ff0000';
    case 'Break':
      return '#ff0000';
    case 'Holidays':
      return '#7208ff';
    case 'No status':
      return '#000000';
    case 'Coffee break':
      return '#795548';
    default:
      return '#ffa500';
  }
}

export function getStatusColorByUser(person, isConnected) {
  if (person) {
    if (person.activity === 'No status'){
      return '#000000';
    }
    else if (person.activity === 'Holidays' || userHasHolidaysSetted(person.uuid)) {
      return '#7208ff';
    } else if (
      person.activity === 'Break' ||
      person.activity === 'Out of Office' ||
      person.activity === 'No Calls' ||
      person.activity === 'Only phone'
    ) {
      return '#ff0000';
    } else if (
      isConnected && person.activity === 'Coffee break'
    ) {
      return '#795548';
    } else if (
      isConnected && (
        person.activity === 'Just urgent calls' ||
        person.activity === 'Busy' ||
        person.activity === 'In meeting' ||
        !!(
          personIsInBridgeCall(person.uuid)
        ))
    ) {
      return '#ffa500';
    } else if (
      isConnected && person.activity === 'Available' &&
      !!(
        personIsInBridgeCall(person.uuid)
      ) === false
    ) {
      return '#008000';
    }
    else if (!(isConnected || usedMobileRecently(person)) || person.activity === 'No status') {
      return '#808080';
    }
  } else {
    return '#808080';
  }
}

export function usedMobileRecently(personReceived) {
  const recent = 60000 * 60;
  let person = personReceived;
  const representativeUuid = hasRepresentative(person.uuid);

  if (representativeUuid && representativeUuid !== store.state.ownUUID) {
    // evereybody can call to the representative and the representative can call the user
    person = store.state.group[representativeUuid];
  } else {
    person = personReceived;
  }
  if (person.user && person.user.mobileLastActive && ((Date.now() - person.user.mobileLastActive) < recent)) {
    return true;
  }
  return false;
}

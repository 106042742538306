<template></template>

<script>
import store from "../../store";
import { waitingInfoStore, waitingForInfoStore } from "../../effector/users/waitingInfo";
import { amInABridgeCall } from "../../utils/calls";

export default {
  props: [],
  components: {},
  data() {
    return {
      state: store.state,
      lengthList: 0,
      actualNumberTone: 0,
    };
  },
  effector: {
    waitingUsesEffect: waitingInfoStore,
    waitingForUsersEffect: waitingForInfoStore
  },
  watch: {
    totalWaitingRoomCount: {
      immediate: true,
      handler: function (total) {
        const previous = this.lengthList;
        this.lengthList = total;
        if (this.amInBridgeCall) {
          if (this._interval) {
            this.stopSound();
          }
        } else {
          this.dingDongLogic(previous);
        }
      }
    },
    // getWaitingRoomCount: {
    //   immediate: true,
    //   handler: function (total) {
    //     const previous = this.lengthList;
    //     this.lengthList = total;
    //     if (this.amInBridgeCall) {
    //       if (this._interval) {
    //         this.stopSound();
    //       }
    //     } else {
    //       this.dingDongLogic(previous);
    //     }
    //   },
    // },
    amInBridgeCall: {
      handler: function (inBridgeCall) {
        const previous = this.lengthList;
        this.lengthList = this.totalWaitingRoomCount;
        if (inBridgeCall && this._interval) {
          this.stopSound();
        } else {
          this.dingDongLogic(previous);
        }
      },
    },
    actualNumberTone: {
      immediate: true,
      handler: function (total) {
        if (this._interval && total >= this.getNumberOfTone) {
          this.stopSound();
        }
      },
    },
  },
  destroyed() {
    this.stopSound();
  },
  methods: {
    dingDongLogic(previous) {
      if ((this.lengthList === 0 || (!this.lengthList && this._interval))) {
        this.stopSound();
      }
      if (
        (previous <= this.lengthList &&
        this.lengthList > 0 &&
        !this._interval)
      ) {
        if (this.actualNumberTone >= this.getNumberOfTone) {
          this.actualNumberTone = 0;
        }
        if (this.$route.path !== "/waitingroom") {
          this.fireSound();
        }
      }
    },
    stopSound() {
      clearInterval(this._interval);
      this._interval = undefined;
      this.actualNumberTone = 0;
    },
    fireSound() {
      if (!this._interval) {
        this.reproduceDingDong();
        this.actualNumberTone += 1;
        this._interval = setInterval(() => {
          this.reproduceDingDong();
          this.actualNumberTone += 1;
        }, this.timeForIntervalDingDong);
      }
    },
    reproduceDingDong() {
      try {
        const audio = new Audio("/media/dingDong.mp3");
        audio
          .play()
          .catch((error) =>
            console.warn("Failed to play notification audio", error)
          );
      } catch (error) {
        console.warn("Failed to play notification audio", error);
      }
    },
  },
  computed: {
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getWaitingForUsersEffect() {
      return this.waitingForUsersEffect;
    },
    getNumberOfTone() {
      return store.state.user.userSettings.numberNotificationWaitingroom || 2;
    },
    getWaitingRoomCount() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    assistantsUser() {
      let finalResult = [];
      if (this.state.user.assists && this.state.user.assists.length > 0) {
        finalResult = JSON.parse(JSON.stringify(this.state.user.assists));
      }
      if (
        this.state.user.waitingRoomAssists &&
        this.state.user.waitingRoomAssists.length > 0
      ) {
        this.state.user.waitingRoomAssists.forEach((uuid) => {
          if (finalResult.indexOf(uuid) == -1) {
            finalResult.push(uuid);
          }
        });
      }
      return finalResult;
    },
    assistantWaitingRoomList() {
      let finalArray = []
      this.assistantsUser.forEach(element => {
        if(store.getWaitingRoomList(element).length > 0) {
          finalArray = finalArray.concat(store.getWaitingRoomList(element).map((user) => user.uuid))
        }
      });
      return finalArray;
    },
    getWaitingRoomForCount() {
      if (!this.assistantWaitingRoomList || !this.assistantWaitingRoomList.length) {
        return 0;
      }
      const calledVisitors = Object.entries(this.waitingForUsersEffect.called)
        .filter(([uuid, value]) => {
          return !!(value && this.assistantWaitingRoomList.find((e) => e.uuid === uuid))
        })
        .map(([uuid, value]) => uuid);
      const total = this.assistantWaitingRoomList.length - calledVisitors.length;
      return total;
    },
    totalWaitingRoomCount() {
      return this.getWaitingRoomCount + this.getWaitingRoomForCount;
    },
    timeForIntervalDingDong() {
      return store.state.namespaceSettings.dingDongTimeForRepeat || 180000;
    },
    amInBridgeCall() {
      return amInABridgeCall();
    },
  },
};
</script>
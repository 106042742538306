<template>
  <v-bottom-navigation
    fixed
    :value="activeBtn"
    grow
    color="teal"
  >
    <div class="footerElement">
      <v-btn icon @click="goToPage('users')" class="fullHeight">
        <font-awesome-icon
            v-if="currentPage('/users')"
            :icon="['fas', 'user']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'user']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
      </v-btn>
    </div>
    <div class="footerElement">
      <v-btn icon @click="goToPage('my-favorites')" class="fullHeight">
        <font-awesome-icon
            v-if="currentPage('/my-favorites')"
            :icon="['fas', 'star']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'star']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
      </v-btn>
    </div>
    <div class="footerElement">
      <v-badge
        right
        color="error"
        :content="getconnecetedWaitingrooms"
        :value="getconnecetedWaitingrooms"
        bordered
        overlap
        offset-x="36"
        offset-y="23"
        class="waitingRoomBadge"
        v-if="showWaitingRoom"
      >
        <v-btn icon @click="goToPage('waitingroom')" class="fullHeight">
          <font-awesome-icon
            v-if="currentPage('/waitingroom')"
            :icon="['fas', 'users-class']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'users-class']"
            class="primary--text"
            :style="{fontSize: '20px'}"
          />
        </v-btn>
      </v-badge>
    </div>
    <div class="footerElement" >
      <v-dialog v-model="userCard" hide-overlay transition="dialog-bottom-transition" @keydown.esc="userCard = false">
        <template v-slot:activator="{ on }">
          <DefaultAvatar
            v-on="on"
            :size="30"
            :userData="state.user"
            v-if="state.user.avatar === 'img/default_profile_picture.png'"
          ></DefaultAvatar>
          <v-img
            v-on="on"
            v-if="
              state.user.avatar !== 'img/default_profile_picture.png'
            "
            class="borderRadius50 footerImg"
            max-height="30"
            max-width="30"
            :contain="getPictureSize"
            :src="state.user.avatar"
            lazy-src="assets/default_profile_picture.png"
          ></v-img>
        </template>
        <DrawerContentCard
          ref="drawerContentCardRef"
        />
      </v-dialog>
    </div>
  </v-bottom-navigation>
</template>
<script>
import store from "../../store";
import { waitingInfoStore } from "../../effector/users/waitingInfo";
import { getPictureSize } from "../../utils/basicFunctions";
import DefaultAvatar from "../image/defaultAvatar.vue";
import DrawerContentCard from "../sidebar/drawerContentCard.vue";
export default {
  components: { DefaultAvatar, DrawerContentCard },
  data () {
    return {
      activeBtn: 1,
      dialogRecent: false,
      dialogInvite: false,
      dialogUsers: false,
      dialogFavorites: false,
      notifications: false,
      sound: true,
      widgets: false,
      state: store.state,
      userCard: true
    }
  },
  effector:{
    waitingUsesEffect: waitingInfoStore,
  },
  mounted() {
    setTimeout(() => {
      this.userCard = false
    }, 10);
  },
  methods: {
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    goToPage(page){
      if ( !this.currentPage(`/${page}`) ){
        this.setCurrentContentVisile(page, true, this.$router);
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
  },
  computed: {
    getPictureSize() {
      return getPictureSize();
    },
    showWaitingRoom() {
      return this.state.user.userSettings.showWaitingRoom;
    },
    getconnecetedWaitingrooms() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
  }
}
</script>
<style scoped lang="scss">
.waitingRoomBadge{
  height: 100%;
}
.footerImg{
  position: relative;
  top: 14px;
  margin: auto;
  border-radius: 50% !important;
}
.footerElement{
  width: calc(100% / 4);
}
.fullHeight{
  height: 100% !important;
}
</style>
<style lang="scss">
.v-dialog--fullscreen{
  overflow: hidden !important;
}
.vueScroll {
  height: calc(100% - 56px) !important;
  .__view{
    width: unset !important;
  }
}
.theme--dark .v-dialog.v-dialog--active{
  background-color: #1e1e1e;
}
.theme--light .v-dialog.v-dialog--active{
  background-color: #fff;
}
</style>
<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.holidays.setYourHolidays')"
        :closeModalFunction="closeModal"
        showInfoButton="holidays"
      />
      <v-card-text class="mx-auto">
        <!-- <v-row class="mx-auto" flex>
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</v-col
          >
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</v-col
          >
        </v-row> -->
        <v-row class="mx-auto" flex>
          <v-col :cols="isMobile? '12':'6'">
            <div class="mb-2 text-center">
            {{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</div>
            <v-date-picker
              no-title
              v-model="startHolidays"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :max="endHolidays"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="100%"
            ></v-date-picker>
          </v-col>
          <v-col :cols="isMobile? '12':'6'">
            <div class="mb-2 text-center">{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</div>
            <v-date-picker
              :min="startHolidays"
              no-title
              v-model="endHolidays"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="100%"
            ></v-date-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <FooterModal :showCancel="true" :closeModalFunction="closeModal">
        <v-btn
          :disabled="disabledSave"
          color="primary"
          class="ml-2"
          @click="deleteHolidays"
          >{{ $t("components.holidays.deleteHolidays") }}</v-btn
        >
        <v-btn
          color="primary"
          class="ml-2"
          :disabled="disabledSave"
          @click="saveHolidays"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { isMobile } from "../../lib/mobileUtil";

export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal", "userUUID"],
  data: () => ({
    state: store.state,
    startHolidays: "",
    endHolidays: "",
    moment: moment,
    isMobile: isMobile(),
  }),
  mounted: function () {
    this.startHolidays = store.state.group[this.userUUID].user.startHolidays;
    this.endHolidays = store.state.group[this.userUUID].user.endHolidays;
  },
  methods: {
    deleteHolidays() {
      this.setSaveHolidays(undefined, undefined);
      this.state.group[this.userUUID].user.activity = "Available";
      this.closeModal();
    },
    saveHolidays() {
      try {
        const startHolidaysTimeStamp = new Date(
          moment(this.startHolidays).format("DD.MM.YYYY HH:mm:ss")
        ).getTime();
        if (startHolidaysTimeStamp < Date.now()) {
          // if the current date is higher than the start holidays setted, set activity to holidays automatically
          this.state.group[this.userUUID].user.activity = "Holidays";
        }
        this.setSaveHolidays(this.startHolidays, this.endHolidays);
        this.closeModal();
      } catch {}
    },
    setSaveHolidays(startDate, endDate) {
      store.setSaveHolidays(startDate, endDate, this.userUUID);
    },
  },
  computed: {
    getStartHolidaysFormatted() {
      if (this.startHolidays) {
        return moment(this.startHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    getEndHolidaysFormatted() {
      if (this.endHolidays) {
        return moment(this.endHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    disabledSave() {
      if (!this.startHolidays || !this.endHolidays) {
        return true;
      }
      return false;
    },
  },
};
</script>
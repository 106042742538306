<template>
  <popper
    v-if="visible"
    trigger="clickToOpen"
    force-show
    :boundaries-selector="reference"
    :options="{
      placement: 'auto',
    }">
    <div class="popper">
      <v-color-picker class="ma-2" hide-inputs :color.sync="color" />
      <v-btn color="primary" @click="hide(true)">{{ $t('generics.ok') }}</v-btn>
      <v-btn color="primary" @click="hide(false)">{{ $t('generics.cancel') }}</v-btn>
    </div>

    <div slot="reference"></div>
  </popper>
</template>

<script>
import Vue from "vue";
import Popper from 'vue-popperjs'

export default {
  components: { Popper },
  data() {
    return {
      color: null,
      reference: null,
      callback: null,
      visible: false,
    }
  },
  methods: {
    hide(save) {
      if (save) this.callback(this.color)
      this.reference = null
      this.callback = null
      this.visible = false
    },
    showPicker(reference, callback) {
      this.reference = reference
      this.callback = callback
      this.$nextTick(() => this.visible = true)
    }
  }
}
</script>

<style scoped>
/deep/ .v-color-picker {
  box-shadow: initial;
}

/deep/ .popper {
  background-color: #ffffff;
  padding-bottom: 8px;
}
</style>
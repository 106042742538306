<template>
  <v-form>
    <v-card-title>
      Benutzer
      <v-spacer />
      <v-text-field
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        @change="search"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="visibleUsers"
      item-key="uuid"
      dense
      show-select
      show-expand
      single-expand
      fixed-header
      :loading="isLoading"
      :items-per-page="itemsPerPage"
      height="calc(100% - 187px)"
    >
      <!-- eslint-disable vue/valid-v-slot -->
      <template #item.user.name="{ item }">
        <v-text-field
          v-model="item.user.name"
          solo
          flat
          :rules="[rules.required]"
          dense
          hide-details="auto"
          class="my-1"
        />
      </template>
      <template #item.user.email="{ item }">
        <v-text-field
          v-model="item.user.email"
          solo
          flat
          :rules="[rules.required, rules.email]"
          dense
          hide-details="auto"
          class="my-1"
        />
      </template>
      <template #item.user.position="{ item }">
        <v-text-field
          v-model="item.user.position"
          solo
          flat
          dense
          hide-details="auto"
          class="my-1"
        />
      </template>
      <template #item.user.assistants="{ item }">
        <v-autocomplete
          v-model="item.user.assistants"
          :items="allUsers"
          item-text="user.name"
          item-value="uuid"
          multiple
          auto-select-first
          solo
          flat
          hide-details="auto"
        >
          <template #item="data">
            <v-list-item-avatar tile>
              <img :src="data.item.user.avatar">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="$sanitize(data.item.user.name)" />
              <v-list-item-subtitle v-html="$sanitize(data.item.user.position)" />
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </template>
      <template #item.permissions="{ item }">
        <v-select
          :value="item.permissions"
          :items="permissions"
          item-text="name"
          item-value="value"
          multiple
          auto-select-first
          dense
          solo
          flat
          hide-details="auto"
          disabled
        />
      </template>
      <!-- eslint-enable vue/valid-v-slot -->
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 10px 16px;"
        >
          <!-- export in own component -->
          <div style="display: flex">
            <div style="width: 200px;">
              <v-row>
                <v-col sm="12">
                  <v-avatar
                    size="200"
                    style="border-radius: 5px"
                    :style="{ border: `2px solid ${getStatusColorByUser(item.user, item.connected)}` }"
                  >
                    <img :src="item.user.avatar">
                  </v-avatar>
                </v-col>
              </v-row>
            </div>
            <div style="width: 100%; margin-left: 16px;">
              <v-row>
                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.title"
                    label="Titel"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col
                  sm="8"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.name"
                    label="Name"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>

                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.location"
                    label="Ort"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.homeHours"
                    label="Wochenstunden (Homeoffice)"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.hours"
                    label="Wochenstunden (Realoffice)"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>

                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.phone"
                    label="Telefon"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.extension"
                    label="Durchwahl"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col
                  sm="4"
                  xs="12"
                >
                  <v-text-field
                    v-model="item.user.mobilePhone"
                    label="Mobiltelefon"
                    placeholder=" "
                    dense
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row>
            <v-col style="display: flex;">
              <div>
                <span
                  class="dot mr-2"
                  :style="{ backgroundColor: getStatusColorByUser(item.user, item.connected) }"
                />{{ item.user.activity }}
              </div>
              <div class="px-2">
                |
              </div>
              <div>
                <!-- from drawerContentCard.vue - extract in own component -->
                <font-awesome-icon
                  v-if="state.user.userLocation === 'homeOffice'"
                  :icon="['fal', 'house-user']"
                  color="#02889d"
                />
                <font-awesome-icon
                  v-else-if="state.user.userLocation === 'outOfHouse'"
                  :icon="['fal', 'car-building']"
                  color="#02889d"
                />
                <font-awesome-icon
                  v-else-if="
                    state.user.userLocation === 'realoOffice'
                  "
                  :icon="['fal', 'building']"
                  color="#02889d"
                />
                <span
                  v-else-if="
                    state.user.userLocation === 'notSpecified'
                  "
                >--</span>
                <font-awesome-icon
                  v-else
                  :icon="['fal', 'location-arrow']"
                />
                <span class="ml-1">{{ locationTranslated(item.user) }}</span>
              </div>
              <div class="px-2">
                |
              </div>
              <div>
                <font-awesome-icon
                  color="#02889d"
                  :icon="['fal', 'arrow-alt-circle-up']"
                />
                <span class="ml-1">{{ formatDateTime(item.user.presentFrom) }}</span>
              </div>
            </v-col>
            <v-col
              class="px-2 py-0"
              align="right"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                  >
                    Einladungslink senden
                  </v-btn>
                </template>
                <span>In Vorbereitung</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                  >
                    Löschen
                  </v-btn>
                </template>
                <span>In Vorbereitung</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-card
      color="primary"
      class="pl-4 pr-2 footerModal"
      style="padding: 6px 0;"
    >
      <v-row
        class="btns mx-0"
        flex
      >
        <v-col
          cols="8"
          class="px-0 py-0"
          align="right"
        >
          <v-btn
            color="primary"
            disabled
            @click="save"
          >
            <span v-if="!isSaving">{{ $t('components.manageOrganisation.save') }}</span>
            <v-progress-circular
              v-else
              indeterminate
            />
          </v-btn>
          <v-btn
            color="primary"
            disabled
            @click="saveAndClose"
          >
            {{ $t('components.manageOrganisation.saveAndClose') }}
          </v-btn>
          <!-- <v-btn color="primary">{{ $t('components.manageOrganisation.abort') }}</v-btn> -->
          <v-btn
            color="primary"
            @click="close"
          >
            {{ $t('generics.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
// import Vue from 'vue';
import moment from "../../../sharedsrc/moment";
import store from '../../store';
import { getStatusColorByUser } from '../../utils/status';
import { locationTranslated } from '../../utils/basicFunctions';
import { importUsersParsed } from '../../lib/wsMsg';
import FlexSearch from 'flexsearch';
import { cloneDeep, pick } from 'lodash';

const index = new FlexSearch({
  async: true
});

// compatibility with "old" user management
function toReducedUser(user) {
  const properties = ['avatar', 'titel', 'name', 'email', 'assistants', 'customId', 'rmoUserId', 'permissions', 'position', 'location', 'qualification', 'phone', 'extension', 'mobilePhone', 'device', 'hours', 'homeHours', 'accessmail', 'dasdUserId', 'uuid', 'omk'];
  return pick(user, properties);
}

export default {
  data() {
    return {
      state: store.state,
      visibleUserIds: [],
      isLoading: true,
      isSaving: false,
      wrapperHeight: 0,
      headers: [
        { text: 'vOffice Benutzername*', value: 'user.name', width: '180px' },
        { text: 'E-Mail*', value: 'user.email', width: '200px' },
        { text: 'Funktion', value: 'user.position', width: '180px' },
        { text: 'Assistenten', value: 'user.assistants' },
        { text: 'Rechte', value: 'permissions' },
        { text: 'Details', value: 'data-table-expand' },
      ],
      users: null,
      permissions: [
        { name: 'Verwalter', value: 1 },
        { name: 'Anrufe sind für andere Benutzer nicht sichtbar', value: 2 },
        { name: 'Kann keine Benutzer einladen', value: 3 },
        { name: 'Kann keine Konferenzen erstellen', value: 4 },
        { name: 'Kann Anrufe in seinen Warteraum weiterleiten', value: 5 },
        { name: 'Benutzer ist inaktiv', value: 6 },
        { name: 'Benutzer ist Moderator', value: 7 },
      ],
      rules: {
        required: value => !!value || 'E-Mail muss angegeben werden',
        email: value => (typeof value === 'string' && /.+\@.+\..+/.test(value)) || 'E-Mail ist ungültig.'
      },
    };
  },
  computed: {
    allUserIds() {
      return Object.entries(this.users)
        .filter(([uuid, group]) => group && group.user)
        .map(([uuid, group]) => uuid);
    },
    allUsers() {
      return this.allUserIds.map(uuid => this.users[uuid]);
    },
    visibleUsers() {
      return this.visibleUserIds.map(uuid => this.users[uuid]);
    },
    itemsPerPage() {
      return Math.floor(this.wrapperHeight / 45);
    }
  },
  mounted() {
    this.users = cloneDeep(this.state.group);
    Object.entries(this.users)
      .filter(([uuid, group]) => group && group.user)
      .forEach(([uuid, group]) => {
        group.uuid = uuid;
        index.add(uuid, group.user.name + ' ' + group.user.email + '' + group.user.position);
      });
    this.visibleUserIds = this.allUserIds;
    this.isLoading = false;
    this.wrapperHeight = document.getElementsByClassName('v-data-table__wrapper')[0].offsetHeight;
  },
  methods: {
    locationTranslated,
    getStatusColorByUser,
    commaSeparatedToArray(string) {
      return string.split(',');
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format('DD.MM.YYYY HH:mm') : '';
    },
    async search(query) {
      this.isLoading = true;
      this.visibleUserIds = query ? await index.search(query) : this.allUserIds;
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      const users = Object.entries(this.users)
        .filter(([uuid, group]) => group && group.user)
        .map(([uuid, group]) => ({
          uuid,
          permissions: group.permissions,
          ...group.user
        }))
        .map(toReducedUser);
      const data = { user: users };
      try {
        await importUsersParsed(data, { overwrite: true, update: true });
        this.isSaving = false;
      } catch (error) {
        console.warn('faild saving user', error);
        this.isSaving = false;
      }
    },
    close() {
      store.setCurrentContentVisile('/', true, this.$router);
    },
    async saveAndClose() {
      await this.save();
      this.close();
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .v-select__selections {
  flex-wrap: initial;
}

/deep/ .v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: inherit;
}

/deep/ .v-data-footer {
  margin-right: 0 !important;
}

/deep/ .v-input__slot {
  padding: 0 !important;
}

/deep/ .theme--light .v-data-table__expanded__content {
  background-color: #fafafa;
}

/deep/ .theme--dark .v-data-table__expanded__content {
  background-color: #121212;
}

/deep/ .v-text-field__details {
  padding: 0 !important;
}

/deep/ .v-data-table tbody tr:hover {
  background: initial !important;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>

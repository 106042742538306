<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
    :attach="getAttach"
    :hide-overlay="getAttach ? true : false"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.conferenceForm.titleInviteVisitor')"
        :closeModalFunction="closeModal"
        
      />
      <v-col cols="12">
        <v-col cols="12" v-if="isConferenceCall && existingGuests.length > 0" class="px-6">
          <p class="mb-0"> {{$t('components.conferenceForm.inviteGuest')}} </p>
           <v-combobox
            v-model="selectedGuest"
            :items="existingGuests"
            :search-input.sync="searchGuest"
            select
            item-text="name"
            item-value="uuid"
            :label="$t('components.conferenceForm.vOfficeUsers')"
            dense
            outlined
            class="mt-3"
            @change="callWRVisitor"
            hide-details
            autofocus
            :item-disabled="userIsInCall"
          >
            <template v-slot:item="data">
              <div >
                  <span class="statusSpan" :style="{background: data.item.statusColor}"></span>
                  <span :style="{marginLeft: '25px'}" v-text="data.item.name"></span>
              </div>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12">
          <ConferencesDirectCallMask
            ref="directCallMaskRef"
            :setNoticeText="setNoticeText"
            :dataConference="dataConference"
          />
        </v-col>
      </v-col>
      <FooterModal :closeModalFunction="closeModal">
        <v-tooltip top v-if="directCallMaskRef">
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-2"
              color="primary"
              v-on="on"
              @click="addVisitor"
              :disabled="!directCallMaskRef.validData || directCallMaskRef.isProcessing"
              >{{ $t("generics.add") }}</v-btn
            >
          </template>
          <span>{{ $t("generics.add") }}</span>
        </v-tooltip>
      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>
 
<script>
import store, { EventBus } from "../../store";
import { wsCall } from "../../lib/wsConnect";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import ConferencesDirectCallMask from "../timeline/conferencesDirectCallMask.vue";
import { updateGroupConferenceEvent } from "../../effector/groupConferences";
import { updateTimelineItemEvent } from "../../effector/timeline";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { getStatusColorByUser } from "../../utils/status";
import {
  callPerson,
  personIsInBridgeCall
} from "../../utils/calls";

export default {
  props: ["isConferenceCall", "amIModerator", "showModal", "closeModal", "dataConference", "attachTo"],
  components: { HeaderModal, FooterModal, ConferencesDirectCallMask },
  data() {
    return {
      state: store.state,
      searchUsers: null,
      selectedUser: null,
      allSelectedUsers: [],
      noticeText: null,
      directCallMaskRef: null,
      selectedGuest: null,
      searchGuest: "",
    };
  },
  watch: {},
  effector: {
    bridgeInfoStore: bridgeInfoStore
  },
  mounted() {
    EventBus.$on("setReference", this.setReference);
    this.directCallMaskRef = this.$refs.directCallMaskRef;
  },
  destroyed() {
    EventBus.$off("setReference", this.setReference);
  },
  methods: {
    userIsInCall(item){
      return personIsInBridgeCall(item.uuid)
    },
    getStatusColorByUser(uuid){
      if ( store.state.group[uuid] && store.state.group[uuid].user ){
        return getStatusColorByUser(store.state.group[uuid])
      }
    },
     callWRVisitor() {
      if (this.selectedGuest && this.selectedGuest.uuid){
        this.addVisitorToConference(this.selectedGuest.uuid);
        callPerson(this.selectedGuest.uuid, this.$router);
        this.closeModal();
      }
    },
    async addUserToConference(uuid) {
      let tmpData = this.dataConference; // full conference
      if(!tmpData || !tmpData.confId) {
        console.warn('No conference to add user to');
        return;
      }
      if (tmpData.confUUIDS.indexOf(uuid) == -1) {
        const newMember = {
            isGuest: true,
            isModerator: false,
            name: this.state.group[uuid].user.name,
            uuid: uuid,
            isGroup: false,
            isTeam: false
          }
          tmpData.members.push(newMember);
          tmpData.confUUIDS.push(uuid);
          await updateGroupConferenceEvent(tmpData);
         
         const newAttendees = this.getAttendeesObject(tmpData.confUUIDS);
          this.getConfOnlineUsers(tmpData, newAttendees);
      }
    },  
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach(uuid => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    getConfOnlineUsers(entry, newAttendees) {
      let usernames = [];
      if (entry && entry.confUUIDS) {
        entry.confUUIDS.forEach(uuid => {
          const username =
            ((store.state.group[uuid] || {}).user || {}).name || "";
          if (username && username !== "") {
            let data = { username: username, uuid: uuid };
            usernames.push(data);
          }
        });
        usernames.forEach(name => {
          let isOnline = false;
          if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
            Object.keys(this.bridgeInfoStore.calls).forEach(call => {
              if (
                this.bridgeInfoStore.calls[call].confId === entry.confId
              ) {
                isOnline = this.bridgeInfoStore.users[name.uuid] || false;
              }
            });
          }

          if (isOnline) {

            wsCall("sendToUUID", name.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: this.getCallUUID,
                confAttendees: newAttendees
              }
            });
          }
        });
      }
    },
    setReference() {
      this.directCallMaskRef = this.$refs.directCallMaskRef;
    },
    async addVisitor() {
      if (!this.directCallMaskRef) throw new Error("Reference not found");
      const newVisitor = await this.directCallMaskRef.addUserToList(true);
      if (!newVisitor) return;
      this.addVisitorToConference(newVisitor)
      this.closeModal();  
    },
     async addVisitorToConference(uuid){
      let tmpData = this.dataConference;
      
      if (tmpData && tmpData.confId){
        if (tmpData.confUUIDS.indexOf(uuid) == -1) {
          const newVisitor = {
            isGuest: true,
            isModerator: false,
            name: this.state.group[uuid].user.name,
            uuid: uuid,
            isGroup: false,
            isTeam: false
            }
            tmpData.members.push(newVisitor);
            tmpData.confUUIDS.push(uuid);
            await updateGroupConferenceEvent(tmpData);
            const newAttendees = await this.getAttendeesObject(tmpData.confUUIDS);
            this.getConfOnlineUsers(tmpData, newAttendees);
        }
      }
    },
    // getConfOnlineUsers(entry) {
    //   let usernames = [];
    //   if (entry && entry.confUUIDS) {
    //     entry.confUUIDS.forEach(uuid => {
    //       const username =
    //         ((store.state.group[uuid] || {}).user || {}).name || "";
    //       if (username && username !== "") {
    //         let data = { username: username, uuid: uuid };
    //         usernames.push(data);
    //       }
    //     });
    //     usernames.forEach(name => {
    //       let isOnline = false;
    //       if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
    //         Object.keys(this.bridgeInfoStore.calls).forEach(call => {
    //           if (
    //             this.bridgeInfoStore.calls[call].confId === entry.confId
    //           ) {
    //             isOnline = this.bridgeInfoStore.users[name.uuid] || false;
    //           }
    //         });
    //       }
    //       if (isOnline) {
    //         wsCall("sendToUUID", name.uuid, {
    //           type: "bridge-signal",
    //           action: "updateConferenceAttendees",
    //           sender: this.state.ownUUID,
    //           info: {
    //             callUUID: this.getCallUUID,
    //             confAttendees: entry.members
    //           }
    //         });
    //       }
    //     });
    //   }
    // },
    setNoticeText(value) {
      this.noticeText = value;
    },
  },
  computed: {
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      return remoteStreamsKey;
    },
    existingGuests(){
      let guests = store.getWaitingRoomList();
      const result = [];
      guests = guests.filter(
        (e) => ((e.user.visitorData || {}).conferenceUUID || []).length > 0
      );
      // console.log(guests)
      guests.forEach(async (user) => {
        const obj = {
          name: user.user.name,
          uuid: user.user.uuid,
          statusColor: 'green'
        }
        result.push(obj);
      })
      return result;
    },
    getAttach(){
      if ( this.attachTo ){
        const element = document.getElementById(this.attachTo);
        if ( element ){
          return element;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.statusSpan{
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
}
</style>
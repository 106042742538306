import { render, staticRenderFns } from "./fullBridgeCallModal.vue?vue&type=template&id=387504e5&scoped=true&"
import script from "./fullBridgeCallModal.vue?vue&type=script&lang=js&"
export * from "./fullBridgeCallModal.vue?vue&type=script&lang=js&"
import style0 from "./fullBridgeCallModal.vue?vue&type=style&index=0&id=387504e5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387504e5",
  null
  
)

export default component.exports
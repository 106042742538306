import store from '../store';

export function hasRepresentative(userUuid) {
  if (
    store.state.group[userUuid] &&
    store.state.group[userUuid].user &&
    store.state.group[userUuid].user.representatives &&
    store.state.group[userUuid].user.representatives.active &&
    store.state.group[userUuid].user.representatives.users.length > 0
  ) {
    return store.state.group[userUuid].user.representatives.users[0].uuid;
  }
}

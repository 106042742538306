import { render, staticRenderFns } from "./moveOrgaItemModal.vue?vue&type=template&id=891d7cf6&scoped=true&"
import script from "./moveOrgaItemModal.vue?vue&type=script&lang=js&"
export * from "./moveOrgaItemModal.vue?vue&type=script&lang=js&"
import style0 from "./moveOrgaItemModal.vue?vue&type=style&index=0&id=891d7cf6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "891d7cf6",
  null
  
)

export default component.exports
<template>
  <div>
    <v-layout row wrap justify-start class="ml-0">
      <DrawerContentCard
        ref="drawerContentCardRef"
        :mini="mini"
        :primaryDrawer="primaryDrawer"
        v-if="hasPrivilege(ownUUID) && !isMobile"
      />
      <v-app-bar :class="`topBar ${isMobile ? 'px-2' : ''}`">
        <v-tooltip bottom v-if="hasPrivilege(ownUUID) && !isMobile">
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon
              v-on="on"
              color="primary"
              @click.stop="
                openCloseMenu();
                saveStatusSidebar();
              "
              class="navIcon mb-0"
            />
          </template>
          <span>{{ $t("components.navbar.sidebarSwitch") }}</span>
        </v-tooltip>
        <template v-if="systemMessagesArray && systemMessagesArray.length > 0">
          <SystemMessagesBar :systemMessagesArray="systemMessagesArray" />
        </template>
        <template
          v-if="!systemMessagesArray || systemMessagesArray.length == 0"
        >
          <v-layout row wrap justify-start flex-column>
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    class="mb-0 mt-0"
                    v-on="on"
                    v-bind:class="{
                      'ml-1 mt--6': isMobile,
                      'ml-3': isMobile && !hasPrivilege(ownUUID),
                      'mr-2 ml-2': !isMobile,
                      'mt-0 ml-5': !isMobile && !hasPrivilege(ownUUID),
                    }"
                    :max-height="
                      !isMobile ? (hasPrivilege(ownUUID) ? 40 : 40) : 35
                    "
                    :max-width="
                      !isMobile ? (hasPrivilege(ownUUID) ? 40 : 40) : 35
                    "
                    src="assets/icon.png"
                    contain
                    @click="takeMeHome()"
                    style="cursor: pointer"
                  ></v-img>
                </template>
                <span>vOffice</span>
              </v-tooltip>
              <a
                v-if="!isMobile"
                class="font-weight-bold linkApp customTitle"
                href="https://www.voffice.pro"
                target="_blank"
              >
                vOffice
              </a>
            </div>
            <!-- SHOW ACTIVE VERSION -->
          </v-layout>
          <v-layout row wrap justify-center class="text-center">
            <v-list-item class="justify-center" v-if="hasPrivilege(ownUUID)">
              <v-list-item-content class="navbarCenter">
                <v-list-item-title class="centerTitle">
                  <v-list-item-avatar
                    v-bind:class="{
                      'mb-0 mx-0 mt-4': isMobile,
                      'mb-3 mt-0 mr-2 ml-0': !isMobile,
                    }"
                    v-if="!showVirtualOfficePrefix"
                  >
                    <v-tooltip bottom v-if="!!showCompanyLogo">
                      <template v-slot:activator="{ on }">
                        <v-img
                          v-on="on"
                          v-bind:class="{
                            'ml-2 mt--6': isMobile,
                          }"
                          :max-height="!isMobile ? 40 : 35"
                          :max-width="!isMobile ? 40 : 35"
                          :src="showCompanyLogo"
                          @click="goBackLogicNavbar()"
                          contain
                        ></v-img>
                      </template>
                      <span>{{ $t("components.navbar.companyLogoTT") }}</span>
                    </v-tooltip>
                  </v-list-item-avatar>
                  <template v-if="!showVirtualOfficePrefix && !isMobile">
                    <span
                      :class="`font-weight-bold customTitle ${
                        isMobile &&
                        (state.namespaceSettings.companyInfo.virtualOfficePrefix
                          .length > 21 ||
                          virtualOfficePrefix.length > 21)
                          ? 'font13'
                          : ''
                      }`"
                      >{{
                        state.namespaceSettings.companyInfo
                          .virtualOfficePrefix || virtualOfficePrefix
                      }}</span
                    >
                  </template>
                  <template v-if="showStatusNumber && !isMobile">
                    <StatsNumbers :inNavbar="true" />
                  </template>
                  <v-col
                    v-if="showVirtualOfficePrefix && !isMobile"
                    class="my-0 py-0 mx-auto"
                  >
                    <v-text-field
                      :rules="rules"
                      hide-details
                      v-model="
                        state.namespaceSettings.companyInfo.virtualOfficePrefix
                      "
                      v-on:keyup.enter="changeVirtualOfficePrefix()"
                      v-on:blur="changeVirtualOfficePrefix()"
                      autofocus
                    ></v-text-field>
                  </v-col>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!hasPrivilege(ownUUID)">
              <v-list-item-content>
                <v-list-item-title
                  :class="`font-weight-bold body-1 ${
                    isMobile ? 'pr-0' : 'mb-5'
                  }`"
                >
                  <v-list-item-avatar
                    v-bind:class="{
                      'mb-0 mx-0 mt-0': isMobile,
                      'mb-3 mt-1 mr-0': !isMobile,
                      logoMobileGuest: isMobile && !hasPrivilege(ownUUID),
                    }"
                    v-if="!showVirtualOfficePrefix"
                  >
                    <v-tooltip bottom v-if="!!showCompanyLogo">
                      <template v-slot:activator="{ on }">
                        <v-img
                          v-on="on"
                          v-bind:class="{
                            'ml-2 mt--6': isMobile,
                          }"
                          :max-height="!isMobile ? 30 : 25"
                          :max-width="!isMobile ? 30 : 25"
                          :src="showCompanyLogo"
                          @click="goBackLogicNavbar()"
                          contain
                        ></v-img>
                      </template>
                      <span>{{ $t("components.navbar.companyLogoTT") }}</span>
                    </v-tooltip>
                  </v-list-item-avatar>
                  <template v-if="!showVirtualOfficePrefix && !isMobile">
                    <span
                      v-on:click.stop.prevent="setshowVirtualOfficePrefix(true)"
                      :class="` font-weight-bold customTitle ${
                        isMobile &&
                        (state.namespaceSettings.companyInfo.virtualOfficePrefix
                          .length > 21 ||
                          virtualOfficePrefix.length > 21)
                          ? 'font13'
                          : ''
                      }`"
                      >{{
                        state.namespaceSettings.companyInfo
                          .virtualOfficePrefix || virtualOfficePrefix
                      }}</span
                    >
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="!isMobile"
                  :class="isDarkMode ? 'white--text' : 'black--text'"
                  class="subtitleWaitingroom font-weight-medium"
                >
                  {{ getSubtitleWaitingroom }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-layout>
          <v-layout row wrap justify-end class="px-2">
            <v-flex shrink>
              <v-flex class="ml-2">
                <ReturnToCall
                  v-if="
                    state.currentContentVisile.showComponent &&
                    (state.user.rtcCallStart ||
                      Object.keys(state.remoteBridgeStreams).length > 0 ||
                      (state.user.inBridgeCall &&
                        state.user.inBridgeCall.length > 0))
                  "
                />
                <!-- START MUTE UNMUTE ICONS -->
                <v-tooltip bottom v-if="!isMobile && !isNotificationMuted">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="primary"
                      @click="setMuteUnmute()"
                    >
                      <img
                        class="themeIcon"
                        src="img/icons/vo_mute.svg"
                        style="width: 20px"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.muteSound") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!isMobile && isNotificationMuted">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="setMuteUnmute()"
                      :class="notificationBlinking ? 'mutedIconBlink' : ''"
                    >
                      <img
                        class="themeIcon"
                        :src="
                          notificationBlinking
                            ? 'img/icons/vo_unmute_red.svg'
                            : 'img/icons/vo_unmute.svg'
                        "
                        style="width: 20px"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.unmuteSound") }}</span>
                </v-tooltip>
                <!-- END MUTE UNMUTE ICONS -->
                <NotificationsWaitingRoom
                  v-if="!hasPrivilege(ownUUID)"
                  :setTotalNotificationCall="setTotalNotificationCall"
                  :setTotalNotificationFile="setTotalNotificationFile"
                  iconsColor="#38a2b8"
                  :isNavbar="true"
                />
                <v-tooltip bottom v-if="!isMobile">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="default"
                      @click="switchTheme"
                      v-on="on"
                      :small="isMobile"
                    >
                      <img
                        class="themeIcon"
                        src="img/day-mode.svg"
                        v-if="$vuetify.theme.dark"
                      />
                      <img
                        class="themeIcon"
                        src="img/night-mode.svg"
                        v-if="!$vuetify.theme.dark"
                      />
                    </v-btn>
                  </template>
                  <span>{{
                    $vuetify.theme.dark
                      ? $t("generics.dayMode")
                      : $t("generics.nightMode")
                  }}</span>
                </v-tooltip>
                <!-- Mimi SETTINGS -->
                <template v-if="hasPrivilege(ownUUID)">
                  <Settings
                    :mini="mini"
                    :primaryDrawer="primaryDrawer"
                    :amIAdmin="amIAdmin"
                    :toggleStatusColorSettings="toggleStatusColorSettings"
                    :isMobile="isMobile"
                    :infoDialog="infoDialog"
                    :toggleModalAdminSettings="toggleModalAdminSettings"
                    :seeOrganisationExcel="seeOrganisationExcel"
                    :showProtocol="showProtocol"
                    :setShowBeacon="setShowBeacon"
                    :toggle2faSettings="toggle2Modal2faSetting"
                    :toggleModalRepresentative="toggleModalRepresentative"
                    :toggleShowHolidaysModal="toggleShowHolidaysModal"
                    :showDialUpTime="showDialUpTime"
                    :showStartView="showStartView"
                    :logout="logout"
                    :showSpeechlive="showSpeechlive"
                    :openCloseMenu="openCloseMenu"
                  />
                </template>
                <template v-if="!hasPrivilege(ownUUID)">
                  <SettingsWaitingRoomUser
                    :toggleModalSettings="toggleModalSettings"
                  />
                </template>
                <!-- END SETTINGS -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="logout"
                      :small="isMobile"
                      id="v-tour-logout"
                    >
                      <img
                        src="/img/icon-logout-navbar.svg"
                        style="width: 20px"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.logoutTT") }}</span>
                </v-tooltip>
              </v-flex>
            </v-flex>
          </v-layout>
        </template>
        <div class="headerMeetingLine" v-if="showTimeline">
          <MeetingLine
            v-show="!isVisitor(this.ownUUID)"
            v-if="showMeetingLine && !isMobile"
          />
        </div>
      </v-app-bar>
    </v-layout>
    <v-dialog v-model="infoDialog.show" persistent max-width="460">
      <v-card>
        <v-card-title class="headline">
          <v-img
            class="mr-1"
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          {{ $t("components.drawerContentCard.information") }}
        </v-card-title>
        <v-card-text>{{ infoDialog.info }}</v-card-text>
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" flex>
            <v-col cols="8" class="px-0 py-0" align="right">
              <v-btn
                color="primary"
                @click="infoDialog = { show: false, info: '' }"
                >{{ $t("generics.accept") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <AdminSettingModal
      v-if="showAdminSettings"
      :showAdminSettings="showAdminSettings"
      :toggleModalAdminSettings="toggleModalAdminSettings"
    />
    <StatusColorModal
      :showStatusColorSettings="showStatusColorSettings"
      :toggleStatusColorSettings="toggleStatusColorSettings"
    />
    <ProtocolModal :showProtocol="showProtocol" :protocol="protocol" />
    <TwofaModalContainer
      v-if="show2faSettings"
      :showModalStatus="show2faSettings"
      :toggle2Modal2faSetting="toggle2Modal2faSetting"
    />
    <DialUpTimeModal
      :showDialUpTime="showDialUpTime"
      :dialUpTime="dialUpTime"
    />
    <StartViewModal
      v-if="startView"
      :showStartView="showStartView"
      :startView="startView"
    />
    <SpeechliveModal
      :showSpeechlive="showSpeechlive"
      :speechlive="speechlive"
    />
    <DeviceSetupModal
      v-if="showSetupDevice"
      :showSetupDevice="hasPrivilege ? showSetupDevice : showDeviceGuest"
      :toggleModalSettings="toggleModalSettings"
    />
    <SetVisitorNameModal v-if="showVisitorNameModal" />
  </div>
</template>

<script>
import store, { EventBus } from "../../store";
import Settings from "../sidebar/settings.vue";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import { init as messagingInit, requestPermission } from "../../lib/messaging";
import LanguageDropdown from "./languageDropdown.vue";
import ReturnToCall from "./returnToCall.vue";
import DeviceSetupModal from "../modal/deviceSetupModal.vue";
import Upgrade from "../modal/upgradeModal.vue";
import { setNamespaceSetting } from "../../lib/wsMsg.js";
import moment from "../../../sharedsrc/moment";
import InviteVisitor from "../sidebar/inviteVisitorsMenu_new.vue";
import {
  unseenMessagesToMe,
  systemMessagesReceived,
  markAsReadSpecificMessageEvent,
} from "../../effector/message";
import MeetingLine from "../meetingLine/meetingLineEffector.vue";
import {
  isVisitor,
  hasPrivilege,
  basicView,
  isWaitingRoomUser,
} from "../../utils/privileges";
import DrawerContentCard from "../sidebar/drawerContentCard.vue";
import NavbarIcons from "./navbarIcons.vue";
import { setlogoutModalEvent, setVisitorNameModalEvent, visitorNameModalStore } from "../../effector/modals";
import AdminSettingModal from "../modal/newAdminSettingsModal.vue";
import StatusColorModal from "../modal/statusColorModal.vue";
import ProtocolModal from "../modal/protocolModal.vue";
import TwofaModalContainer from "../2fa/modals/2faModalContainer.vue";
import DialUpTimeModal from "../modal/dialUpTimeModal.vue";
import StartViewModal from "../modal/startViewModal.vue";
import SpeechliveModal from "../modal/speechliveModal.vue";
import NotificationsWaitingRoom from "../sidebar/userActionBar/notificationsWaitingRoom.vue";
import StatsNumbers from "../content/statsNumbers.vue";
import SettingsWaitingRoomUser from "./settingsWaitingRoomUser.vue";
import { waitingInfoStore } from "../../effector/users/waitingInfo";
import SystemMessagesBar from "./systemMessagesBar.vue";
import SetVisitorNameModal from "../modal/setVisitorNameModal.vue"

messagingInit();

export default {
  props: ["primaryDrawer", "mini", "openCloseSidebar"],
  components: {
    LanguageDropdown,
    ReturnToCall,
    DeviceSetupModal,
    Upgrade,
    InviteVisitor,
    MeetingLine,
    DrawerContentCard,
    NavbarIcons,
    Settings,
    AdminSettingModal,
    StatusColorModal,
    ProtocolModal,
    TwofaModalContainer,
    DialUpTimeModal,
    StartViewModal,
    SpeechliveModal,
    NotificationsWaitingRoom,
    StatsNumbers,
    SettingsWaitingRoomUser,
    SystemMessagesBar,
    SetVisitorNameModal
  },
  data() {
    return {
      item: 1,
      state: store.state,
      ownUUID: store.state.ownUUID,
      showSetupDevice: false,
      showVirtualOfficePrefix: false,
      rules: [(value) => !!value || this.$t("generics.required")],
      setCurrentContentVisile: store.setCurrentContentVisile,
      setSubordinateUser: store.setSubordinateUser,
      persisted: store.state.persisted,
      moment: moment,
      isMobile: isMobile(),
      isIpad: isIpad(),
      // Mimi
      infoDialog: {
        show: false,
        info: "",
      },
      showStatusColorSettings: false,
      showAdminSettings: false,
      protocol: false,
      showBeacon: false,
      show2faSettings: false,
      showModalRepresentative: false,
      openHolidaysCalendar: false,
      speechlive: false,
      dialUpTime: false,
      startView: false,
    };
  },
  effector: {
    unseenMessagesToMe: unseenMessagesToMe,
    waitingUsesEffect: waitingInfoStore,
    systemMessages: systemMessagesReceived,
    showVisitorNameModal: visitorNameModalStore,
  },
  mounted() {
    EventBus.$on("openSidebar", this.openSidebarHandler);
    EventBus.$on("openCloseSidebar", this.openCloseSidebar);
    if (!this.hasPrivilege(this.ownUUID) && this.showDeviceGuest) {
      this.showSetupDevice = true;
    }
  },
  destroyed(){
    EventBus.$off("openSidebar", this.openSidebarHandler);
    EventBus.$off("openCloseSidebar", this.openCloseSidebar);
  },
  methods: {
    openSidebarHandler() {
      this.openCloseMenu();
      this.saveStatusSidebar();
    },
    setMuteUnmute() {
      if (this.isNotificationMuted) {
        // unmute
        this.state.persisted.notificationsMuted = false;
      } else {
        // mute
        this.state.persisted.notificationsMuted = true;
      }
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    setTotalNotificationFile: function (...args) {
      store.setTotalNotificationFile(...args);
    },
    setTotalNotificationCall: function (...args) {
      store.setTotalNotificationCall(...args);
    },
    saveStatusSidebar() {
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings.sidebarMini = !this.mini;
      return store.setUserSettings(temporalUserSettings);
    },
    showSpeechlive() {
      this.speechlive = !this.speechlive;
    },
    showDialUpTime() {
      this.dialUpTime = !this.dialUpTime;
    },
    showStartView() {
      this.startView = !this.startView;
    },
    toggleShowHolidaysModal() {
      this.openHolidaysCalendar = !this.openHolidaysCalendar;
    },
    toggleModalRepresentative() {
      this.showModalRepresentative = !this.showModalRepresentative;
    },
    toggle2Modal2faSetting() {
      this.show2faSettings = !this.show2faSettings;
    },
    setShowBeacon() {
      this.showBeacon = !this.showBeacon;
    },
    showProtocol() {
      this.protocol = !this.protocol;
    },
    seeOrganisationExcel() {
      this.setExcelTotalView("organisation");
      this.editExcel();
    },
    setExcelTotalView(value) {
      return store.setExcelTotalView(value);
    },
    editExcel() {
      if (!store.getUserIsAdmin(this.state.ownUUID)) {
        return;
      }
      if (!this.mini) {
        this.openCloseSidebar();
      }
      store.setCurrentContentVisile(
        "editUploadExcell",
        true,
        this.$router,
        "/"
      );
    },
    toggleModalAdminSettings() {
      this.showAdminSettings = !this.showAdminSettings;
    },
    toggleStatusColorSettings() {
      this.showStatusColorSettings = !this.showStatusColorSettings;
    },
    logout() {
      setlogoutModalEvent(true);
    },
    takeMeHome() {
      if (this.$route.path !== "/home") {
        this.$router.push({ path: "/home" });
      }
    },
    openCloseMenu() {
      if (this.state.searchTerm.length > 0) {
        EventBus.$emit("sendOpenCloseSearchField");
        return;
      }
      this.openCloseSidebar();
    },
    openSidebar() {
      if (!this.mini && !this.state.user.userSettings.dynamicSidePanel) {
        this.openCloseSidebar();
      }
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    isWaitingRoomUser(uuid) {
      return isWaitingRoomUser(uuid);
    },
    getUserIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    goBackLogicNavbar() {
      if (this.$route.path !== "/home") {
        this.setCurrentContentVisile("home", true, this.$router);
      }
    },
    switchTheme() {
      if (!this.$vuetify.theme.dark) {
        store.setIsDark(true);
      } else {
        store.setIsDark(false);
      }
    },
    changeVirtualOfficePrefix() {
      const virtualOfficePrefix =
        store.state.namespaceSettings.companyInfo.virtualOfficePrefix;
      if (virtualOfficePrefix.length > 0) {
        setNamespaceSetting(
          "companyInfo",
          store.state.namespaceSettings.companyInfo
        );
        this.setshowVirtualOfficePrefix(false);
      }
    },
    getUserIsAdmin(uuid) {
      return store.getUserIsAdmin(uuid);
    },
    setshowVirtualOfficePrefix(show) {
      if (this.getUserIsAdmin(this.ownUUID)) {
        this.showVirtualOfficePrefix = show;
      }
    },
    toggleModalSettings() {
      this.showSetupDevice = !this.showSetupDevice;
      this.state.user.guestDeviceSetted = !this.state.user.guestDeviceSetted;
      if(!this.hasPrivilege(this.ownUUID) && this.state.user.guestDeviceSetted && !this.state.user.name){
        this.showSetVisitorNameModal()
      }
    },
    requestPermission() {
      return requestPermission();
    },
    showSetVisitorNameModal(){
      setVisitorNameModalEvent(true)
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== "/messages") {
        this.setSubordinateUser("");
      }
      store.state.user.activeGroupName = "";
    },
    systemMessagesArray: {
      immediate: true,
      handler: function (menssages) {
        menssages.forEach((message, index) => {
          if (index > 0) {
            markAsReadSpecificMessageEvent(message.messageUUID);
          }
        });
      },
    },
  },
  computed: {
    showDeviceGuest() {
      if (!this.hasPrivilege(this.ownUUID) && !this.state.user.guestDeviceSetted) {
        return true;
      } else {
        return false;
      }
    },
    systemMessagesArray() {
      const messages = [];
      for (let message of this.systemMessages) {
        if (
          (message.users[this.ownUUID] && message.users[this.ownUUID].read) ||
          message.creatorUUID == this.ownUUID
        )
          continue;
        messages.push(message);
      }
      return messages;
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    getConnectedWaitingRooms() {
      const calledVisitors = Object.entries(this.waitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    notificationBlinking() {
      if (
        this.incommingCallsArray.length > 0 ||
        this.getConnectedWaitingRooms > 0 ||
        this.totalNotification > 0
      ) {
        return true;
      }
      return false;
    },
    incommingCallsArray() {
      const calls = [];
      for (let uuid in this.state.incomingBridgeCallModal.calls) {
        const call = this.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        calls.push({ ...call, uuid });
      }
      return calls;
    },
    isNotificationMuted() {
      return this.state.persisted.notificationsMuted;
    },
    showTimeline() {
      if (
        this.$route.path === "/invitationformuser" ||
        this.$route.path === "/invitationform"
      ) {
        return false;
      }
      return true;
    },
    waitingRoomAlias() {
      return store.state.namespaceSettings.waitingRoomAlias;
    },
    getSubtitleWaitingroom() {
      const userInviter = this.state.user.visitorData.userInviter;
      return `${
        this.waitingRoomAlias
          ? this.waitingRoomAlias
          : this.$t("components.waitingRoom.waitingroomFor")
      } ${this.getTitelForUuid(userInviter)} ${this.getNameForUuid(
        userInviter
      )}`;
    },
    showStatusNumber() {
      return store.state.user.userSettings.showStatusNumber;
    },
    showMeetingLine() {
      return (
        !this.isWaitingRoomUser(this.ownUUID) &&
        this.state.user.userSettings.showVideoCallPlanner &&
        !this.isBasicView
      );
    },
    isBasicView() {
      return basicView();
    },
    totalNotificationMessages() {
      return this.unseenMessagesToMe;
    },
    isGuest() {
      return store.getUserIsGuest(this.ownUUID);
    },
    totalNotification() {
      return (
        store.state.persisted.totalNotificationCall ||
        0 + store.state.persisted.totalNotificationTicke ||
        0 + this.totalNotificationMessages ||
        0 + store.state.persisted.totalNotificationFile ||
        0
      );
    },
    showCompanyLogo() {
      return this.state.namespaceSettings.companyLogo || "";
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    virtualOfficePrefix() {
      return store.getVirtualOfficePrefix();
    },
  },
};
</script>
<style lang="scss">
.w100 {
  width: 100%;
}
.v-toolbar__content {
  @media (max-width: 475px) {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
}
</style>
<style scoped lang="scss">
.mutedIconBlink {
  animation: blinker 1s linear infinite;
  color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.subtitleWaitingroom {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.logoMobileGuest {
  position: absolute;
  top: 10px;
}
.statusNumber {
  position: absolute;
  bottom: 15px;
  width: 100%;
  right: 0;
}
.centerTitle {
  height: 55px;
  position: relative;
  top: -7px;
}
.settings-right {
  display: inline;
}
.versionText {
  margin-left: -26px;
  color: var(--v-primary-base);
}
.versionTextWaiting {
  margin-left: 16px;
  color: var(--v-primary-base);
}
.themeIcon {
  width: 28px;
  height: 28px;
  position: relative;
  top: 1px;
}
.navbarCenter {
  flex: unset !important;
}
.customTitle {
  font-size: 28px !important;
}
.totalUsersConnected {
  font-size: 13px;
  position: absolute;
  top: 30px !important;
}

.topBar {
  box-shadow: none !important;
  border-bottom: 1px solid #9e9e9e !important;
  width: 0;
}

.headerMeetingLine {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 64px;
  height: 60px;
}
.theme--light .headerMeetingLine {
  background-color: white;
  border-bottom: 1px solid #efefef;
}
.theme--dark .headerMeetingLine {
  background-color: #131313;
}

.blinkingBadge {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.height35 {
  height: 35px;
}

.height45 {
  height: 45px;
}

.mt--6 {
  margin-top: -6px;
}
.linkApp {
  text-decoration: unset;
  color: unset !important;
}
.marginRight10 {
  margin-right: 4px !important;
}
.marginTop-5 {
  margin-top: -5px !important;
}

.mbutton10 {
  margin-right: 10px;
}
.cursorPointer {
  cursor: pointer;
}
.font13 {
  font-size: 13px;
}
.youtubeSpecial {
  color: var(--v-primary-base);
}

.usersAvailable {
  color: #008000;
}
.usersNotAvailable {
  color: #ffa500;
}
.usersHolidays {
  color: #7208ff;
}
.usersOffline {
  color: #808080;
}
.usersBusy {
  color: #ff0000;
}
.betaversion {
  color: #ff0000;
}
.usersConnections {
  color: var(--v-primary-base) !important;
}
.usersList-white {
  color: white;
}
.usersList {
  color: black;
}

.croppie-container {
  height: unset;
  margin: 40px auto;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #02889d !important;
}

#logoModal {
  height: 180px;
  width: 180px;
  min-width: 180px;
  margin-top: 0px;
  &:hover ~ .hasImage {
    display: block;
  }
  &:hover ~ .hasNoImage {
    display: block;
  }
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 180px;
  width: 180px;
  top: 0px;
  border-radius: 10px;
  text-align: center;
  padding-top: 100px;
  display: none;

  &:hover {
    display: block;
  }
}

.uploadAvatarButtons:hover {
  display: block;
}

.borderCircle {
  border-radius: 50% !important;
}

.tightPlus {
  cursor: pointer;
  font-size: 12px !important;
  z-index: 99999;
  right: 25px;
  position: relative;
  top: -11px;
}
.buttonNavbar {
  position: absolute !important;
  right: 10px;
  top: -4px;
  max-width: 10px;
  max-height: 10px;
}
.numberNavbar {
  position: absolute !important;
  right: 10px;
  top: -12px;
  max-width: 10px;
  max-height: 10px;
  font-size: 12px;
  color: #02889d;
}

.numberNavbarInfob {
  position: absolute !important;
  right: 10px;
  top: -6px;
  max-width: 10px;
  max-height: 10px;
  font-size: 12px;
  color: #02889d;
}

.buttonInfoBoardNavbar {
  position: absolute !important;
  right: 3px;
  top: -4px;
  max-width: 10px;
  max-height: 10px;
}
.font-weight-400 {
  font-weight: 400 !important;
}
</style>
/* eslint-disable no-extend-native, no-unused-vars */
import store from '../store';
import { v4 as uuidv4 } from 'uuid';
import { activeCallUUID } from '../effector/call';
import { ownUserUUIDState } from '../effector/users';

const telemetryEndpoint = 'https://telemetry.voffice.pro/log';
const telemetryInstanceId = uuidv4();

const origLog = console.log;
const origWarn = console.warn;

async function postTelemetry(obj) {
  // TODO: this should probably be changed to be a batched navigator.sendBeacon like other projects, instead of a per-event fetchPost
  try {
    // origLog('postTelemetry', obj);
    const res = await fetch(telemetryEndpoint, {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(obj),
    });
    return await res.json();
  } catch (err) {
    // Dont really want to log this in normal console object because infinite loops.
    origWarn('postTelemetry', err, obj);
  }
}

function getCallId() { return (store.state.user.inBridgeCall || activeCallUUID.getState()); }
function getUserId() { return ownUserUUIDState.getState(); }
function getInstanceId() { return location.host.replace(':', '_'); }

async function telemetryLogMessage(message, domain = 'unknown', level = 0) {
  return postTelemetry({
    timestamp: Date.now(), level, domain, userid: getUserId(), callid: getCallId(), instanceid: getInstanceId(), telemetryInstanceId, message,
  });
}

export async function telemetryLogMessageExported(message, domain = 'unknown', level = 0) {
  return postTelemetry({
    timestamp: Date.now(), level, domain, userid: getUserId(), callid: getCallId(), instanceid: getInstanceId(), telemetryInstanceId, message,
  });
}

// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
if (!('toJSON' in Error.prototype)) {
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      var alt = {};
      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key];
      }, this);
      return alt;
    },
    configurable: true,
    writable: true
  });
}

// http://tobyho.com/2012/07/27/taking-over-console-log/
function takeOverConsole() {
  const console = window.console;
  if (!console) return;
  function intercept(method, level) {
    const original = console[method];
    console[method] = function() {
      telemetryLogMessage({ method, arguments: arguments }, 'log', level);
      // do sneaky stuff
      if (original.apply) {
        // Do this for normal browsers
        original.apply(console, arguments);
      } else {
        // Do this for IE
        const message = Array.prototype.slice.apply(arguments).join(' ');
        original(message);
      }
    };
  }
  const methods = ['info', 'log', 'warn', 'error'];
  for (let i = 0; i < methods.length; i++) { intercept(methods[i], i + 1); }
}

async function logStartupInfo() {
  const info = {
    // userAgent: navigator.userAgent,
    navigator: {
      userAgent: navigator.userAgent,
      doNotTrack: navigator.doNotTrack,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
      platform: navigator.platform,
      cookieEnabled: navigator.cookieEnabled,
      language: navigator.language,
      languages: navigator.languages,
      maxTouchPoints: navigator.maxTouchPoints,
      connection: {},
    },
    window: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
    },
  };
  if (navigator.connection) {
    info.navigator.connection = {
      downlink: navigator.connection.downlink,
      downlinkMax: navigator.connection.downlinkMax,
      effectiveType: navigator.connection.effectiveType,
      rtt: navigator.connection.rtt,
      saveData: navigator.connection.saveData,
      type: navigator.connection.type,
    };
  }
  return telemetryLogMessage(info, 'startup', 0);
}

export default () => {
  const forcedTelemetryInstances = ['ra-micro.voffice.pro'];
  const allowedTelemetryInstances = ['beta.voffice.pro', 'demo.voffice.pro'];
  if (forcedTelemetryInstances.indexOf(getInstanceId()) !== -1 || (allowedTelemetryInstances.indexOf(getInstanceId()) !== -1 && store.state.namespaceSettings.qualityLogging)) {
    console.log('Telemetry enabled');
    takeOverConsole();
    logStartupInfo();
  } else {
    console.log('Telemetry disabled');
  }
};

import { render, staticRenderFns } from "./remotePersonCallPresentation.vue?vue&type=template&id=ab949da6&scoped=true&"
import script from "./remotePersonCallPresentation.vue?vue&type=script&lang=js&"
export * from "./remotePersonCallPresentation.vue?vue&type=script&lang=js&"
import style0 from "./remotePersonCallPresentation.vue?vue&type=style&index=0&id=ab949da6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab949da6",
  null
  
)

export default component.exports
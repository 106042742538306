 <template>
  <span>
    <template v-if="organisationData && organisationData.name">
      <span>{{ organisationData.name }}</span>
      <br />
    </template>
    <!-- <template v-if="organisationData && organisationData.position">
      <span>{{ organisationData.position }}</span>
      <br />
    </template> -->
    <template v-if="person && person.uuid">
      <span
        >{{ assistant ? "Assistant" : getUserTitel(person.user.uuid) }}
        {{ person.user.name }}</span
      >
      <br
        v-if="
          person.user.position ||
          (person.user.visitorData &&
            person.user.visitorData.noteForContact &&
            person.user.visitorData.noteForContact.length)
        "
      />
    </template>
    <span v-if="hasPrivilege(person.user.uuid) && person.user.position">{{
      person.user.position
    }}</span>
    <span
      v-if="
        !hasPrivilege(person.user.uuid) &&
        person.user.visitorData &&
        person.user.visitorData.noteForContact
      "
      >{{ person.user.visitorData.noteForContact }}</span
    >
    <template
      v-if="
        hasPrivilege(person.user.uuid) && getLocationTranslated(person.user)
      "
    >
      <br />
      <span>{{ getLocationTranslated(person.user) }}</span>
    </template>
    <template v-if="state.namespaceSettings.displayUserStatus">
      <br v-if="getUserActivity(person) && !isGuest" />
      <span v-if="!isGuest">
        <font-awesome-icon
          :icon="['fal', 'video']"
          v-if="getCallText(person) && person.user.activity !== 'No status'"
        />
        {{
          getCallText(person) && person.user.activity !== "No status"
            ? getCallText(person)
            : checkStatusLabel
        }}
      </span>
      <br
        v-if="
          (!getCallText(person) && getPersonInHolidays && !isGuest) ||
          (getCallText(person) && getPersonInHolidays)
        "
      />
      <span v-if="getPersonInHolidays && !isGuest"
        >{{ moment(new Date(person.user.startHolidays)).format("DD.MM.YY") }}
        -
        {{ moment(new Date(person.user.endHolidays)).format("DD.MM.YY") }}</span
      >
      <template
        v-if="person.user.activity == 'Break' && person.user.breakTimeEnd"
      >
        <br />
        <span> {{ breakTimeEnd }} {{ $t("generics.hours") }} </span>
      </template>
      <br />
    </template>
  </span>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { userInCallText } from "../../utils/calls";
import {
  locationTranslated,
  userHasHolidaysSetted,
  getUserActivityFunction,
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges.js";
export default {
  props: ["person", "assistant", "organisationData"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment: moment,
    };
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getLocationTranslated(person) {
      return locationTranslated(person);
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getCallText(person) {
      return userInCallText(person);
    },
    getUserActivity(person) {
      return getUserActivityFunction(person);
    },
  },
  computed: {
    breakTimeEnd() {
      if ((this.person.user.breakTimeEnd || '').length > 5 && Date.parse(this.person.user.breakTimeEnd)) {
        return moment(this.person.user.breakTimeEnd).format("HH:mm");
      }
      return this.person.user.breakTimeEnd;
    },
    checkStatusLabel() {
      if (
        this.person.user.activity === "Break" &&
        this.person.user.breakTimeEnd
      ) {
        return this.$t("generics.expectedReturn");
      } else {
        return this.getUserActivity(this.person);
      }
    },
    getPersonInHolidays() {
      return userHasHolidaysSetted(this.person.user.uuid);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
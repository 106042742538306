<template>
  <div
    class="icons iconsRemote"
    :id="`remoteBar_${participant.participantId}`"
    @mousemove="
      moveMouserCardRemote
        ? moveMouserCardRemote(participant.participantId)
        : false
    "
    @mouseleave="hideRemoteBar ? hideRemoteBar() : false"
  >
    <div class="buttosContainer">
      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!getIsConferenceHall"
            v-show="amIModerator && !isUserModerator"
            class="buttonsCall"
            icon
            color="white"
            v-bind="attrs"
            v-on="on"
            @click.prevent.stop="toggleRemoteMic(participant)"
          >
            <!-- :disabled="
              participantsMuted[participant.participantId] &&
              participantsMuted[participant.participantId].muted &&
              participantsMuted[participant.participantId].byAdmin == false
            " -->
            <font-awesome-icon
              v-show="showIconsMic == 'mic'"
              :icon="['fal', 'microphone']"
              :style="{ fontSize: '20px' }"
            />
            <img
              v-show="showIconsMic == 'micMuted'"
              class="microphone-slash"
              src="/img/micro-red.svg"
            />
             <img
              v-show="showIconsMic == 'micMutedByAdmin'"
              class="microphone-slash"
              src="/img/microphone-moderator-white-slash-red.svg"
            />
            <div
              :id="`remotAudioBar-${participant.participantId}`"
              class="progressBarBridge"
              v-show="participantsMuted.indexOf(participant.participantId) == -1"
            >
              <div class="progressBarLevelBridge"></div>
            </div>
          </v-btn>
        </template>
        <span>{{
          micMutedByAdmin.indexOf(participant.participantId) !== -1 ? 'moderador' :
          (participantsMuted.indexOf(participant.participantId) == -1
            ? $t("components.callsContent.micOff")
            : $t("components.callsContent.micOn"))
        }}</span>
      </v-tooltip>

      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="amIModerator && !isUserModerator && !getIsConferenceHall"
            class="buttonsCall"
            icon
            color="white"
            v-bind="attrs"
            v-on="on"
            @click.prevent.stop="
              toggleCameraOffRemote(participant.participantId)
            "
          >
            <img
              v-show="!participantsCameraOff[participant.participantId]"
              class="microphone-slash"
              src="/img/user-camera-white.svg"
            />
            <img
              v-show="participantsCameraOff[participant.participantId]"
              class="microphone-slash"
              src="/img/user-camera-slash-red-white.svg"
            />
          </v-btn>
        </template>
        <span>{{
          participantsCameraOff[participant.participantId]
            ? $t("components.callsContent.stopCameraOffParticipant")
            : $t("components.callsContent.cameraOffParticipant")
        }}</span>
      </v-tooltip>
      <!-- camera off -->
      <!--<v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="
              amIModerator &&
              !fullScreenActive &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy
            "
            class="buttonsCall"
            icon
            v-bind="attrs"
            v-on="on"
            color="white"
            @click.prevent.stop="toggleMessage()"
          >
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '20px' }"
              :color="
                isOnlyOneCallUser && unseeMessageOneCallUser ? 'red' : 'white'
              "
            />
          </v-btn>
        </template>
        <span>{{ $t("components.userProfile.sendAMessage") }}</span>
      </v-tooltip>
      -->
      <!-- HangUp -->
      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="showHangUpButton"
            class="buttonsCall"
            icon
            color="white"
            v-bind="attrs"
            v-on="on"
            @click.prevent.stop="toggleHangUp"
          >
            <font-awesome-icon
              :icon="['fal', 'phone']"
              class="faPhoneRotate"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("generics.hangUp") }}</span>
      </v-tooltip>
      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!amIModerator && !fullScreenActive"
            class="buttonsCall"
            icon
            :color="
              (isOnlyOneCallUser || amIGuest) && unseeMessageOneCallUser
                ? 'red'
                : 'white'
            "
            v-bind="attrs"
            v-on="on"
            @click.prevent.stop="toggleMessage"
          >
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.userProfile.sendAMessage") }}</span>
      </v-tooltip>
      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!isMobile && !isOnlyOneCallUser"
            class="buttonsCall"
            icon
            color="white"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="
              toggleFullscreenRoutine(participant.participantId)
            "
          >
            <font-awesome-icon
              :icon="['fal', 'expand-wide']"
              :style="{ fontSize: '20px' }"
              v-show="actualFullScreenTypeRemote == 'maximizeScreen'"
            />
            <font-awesome-icon
              :icon="['fal', 'compress-wide']"
              :style="{ fontSize: '20px' }"
              v-show="actualFullScreenTypeRemote == 'fullscreen'"
            />
          </v-btn>
        </template>
        <span>
          {{
            actualFullScreenTypeRemote == "maximizeScreen"
              ? $t("generics.fullscreen")
              : actualFullScreenTypeRemote == "fullscreen"
              ? $t("generics.minimize")
              : $t("components.callsContent.defaultView")
          }}
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!isMobile && !isOnlyOneCallUser && getIsConferenceHall"
            class="buttonsCall"
            icon
            color="white"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="
              setUserInModal(participant.userUUID)
            "
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>
          {{ $t("components.adminSettingsModal.userInfo") }}
        </span>
      </v-tooltip>
      <!-- Buttons out for more less than 15  -->
      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="
              !isUserModerator &&
              amIModerator &&
              !hasPrivilege &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy &&
              isPayAvailable &&
              !getIsConferenceHall
            "
            class="buttonsCall"
            icon
            v-bind="attrs"
            v-on="on"
            color="white"
            @click.prevent.stop="requestPayment()"
          >
            <img
              class="currency"
              src="img/icons/facepay-white.svg"
              lazy-src="img/icons/facepay.svg"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.requestPayment") }}</span>
      </v-tooltip>

      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="
              (!isUserModerator &&
              amIModerator &&
              hasPrivilege &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy && !getIsConferenceHall) || 
              ( !isUserModerator &&
              amIModerator &&
              hasPrivilege &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID))
            "
            class="buttonsCall"
            icon
            v-bind="attrs"
            v-on="on"
            color="white"
            @click.prevent.stop="toggleModerator(participant.participantId)"
          >
            <font-awesome-icon
              :icon="['fal', 'users-crown']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.remotePersonCall.transferModerator") }}</span>
      </v-tooltip>

      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="
              amIModerator &&
              !fullScreenActive &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy
            "
            class="buttonsCall"
            icon
            v-bind="attrs"
            v-on="on"
            color="white"
            @click.prevent.stop="toggleMessage()"
          >
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '20px' }"
              :color="
                (isOnlyOneCallUser || amIGuest) && unseeMessageOneCallUser
                  ? 'red'
                  : 'white'
              "
            />
          </v-btn>
        </template>
        <span>{{ $t("components.userProfile.sendAMessage") }}</span>
      </v-tooltip>

      <v-tooltip
        top
        :content-class="
          actualFullScreenTypeRemote == 'fullscreen'
            ? 'ttFullScreen'
            : undefined
        "
        :attach="
          actualFullScreenTypeRemote == 'fullscreen'
            ? `#remoteBar_${participant.participantId}`
            : false
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="
              userHasEmail &&
              amIModerator &&
              getActiveUsers < 15 &&
              !currentDesktop &&
              !presenterModeInitiatedBy
            "
            class="buttonsCall"
            icon
            v-bind="attrs"
            v-on="on"
            color="white"
            @click.prevent.stop="toggleSendEmail()"
          >
            <font-awesome-icon
              :icon="['fal', 'envelope-square']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.userListItem.sendEMail") }}</span>
      </v-tooltip>

      <!-- End -->

      <v-menu
        :content-class="!getIsConferenceHall || ((getIsConferenceHall && (getIsParticipantIsPodium(participant.userUUID) || (!getIsParticipantIsPodium(participant.userUUID) && this.isFullScreen()) ))) ? 'customMenu' : ''"
        bottom
        :offset-x="(getIsConferenceHall && !getIsParticipantIsPodium(participant.userUUID) && !this.isFullScreen()) ? true : false"
        z-index="50"
        absolute
        :attach="(getIsConferenceHall && !getIsParticipantIsPodium(participant.userUUID) && !this.isFullScreen()) ? false : `#remoteBar_${participant.participantId}`"
        min-width="220px"
        v-if="
          amIModerator &&
          (getActiveUsers >= 15 || currentDesktop || presenterModeInitiatedBy)
        "
        v-model="menuOpen"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="buttonsCall"
            icon
            v-bind="attrs"
            @click.stop.prevent
            v-on="on"
            color="white"
          >
            <font-awesome-icon
              :icon="['fal', 'ellipsis-v']"
              :style="{ fontSize: '20px', color: '#ffffff' }"
            />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="requestPayment()"
            v-show="!isUserModerator && amIModerator && !hasPrivilege && !getIsConferenceHall"
          >
            <v-list-item-icon>
              <img
                v-if="$vuetify.theme.dark"
                class="currency"
                src="img/icons/facepay-white.svg"
                lazy-src="img/icons/facepay.svg"
              />
              <img
                v-else
                class="currency"
                src="img/icons/facepay.svg"
                lazy-src="img/icons/facepay.svg"
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.callsContent.requestPayment") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="toggleModerator(participant.participantId)"
            v-show="!isUserModerator && amIModerator && hasPrivilege && !getIsConferenceHall || (!isUserModerator && amIModerator && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID) && hasPrivilege)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'users-crown']"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.remotePersonCall.transferModerator") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="toggleMessage()"
            v-show="amIModerator && !fullScreenActive"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'paper-plane']"
                :style="{ fontSize: '20px' }"
                :color="
                  isOnlyOneCallUser && unseeMessageOneCallUser
                    ? 'red'
                    : isDark
                    ? 'white'
                    : 'black'
                "
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.userProfile.sendAMessage") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="toggleSendEmail()" v-show="amIModerator">
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'envelope-square']"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.userListItem.sendEMail") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="toggleHangUp"
            v-show="showHangUpButtonMenu"
          >
            <!-- v-show="
              ((amIModerator || !isUserModerator) &&
              room &&
              presenterModeInitiatedBy &&
              presenterModeInitiatedBy !== participant.participantId &&
              !currentDesktop && !getIsConferenceHall) ||  
              ((amIModerator || !isUserModerator) &&
              room &&
              presenterModeInitiatedBy &&
              presenterModeInitiatedBy !== participant.participantId &&
              !currentDesktop && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID)) &&
              (state.remoteBridgeStreams[getCallUUID] || {}).initiator !== participant.userUUID
            " -->
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'phone']"
                class="faPhoneRotate"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("generics.hangUp") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="toggleGiveWord(participant.participantId)"
            v-show="
              (room &&
              getAmIModerator &&
              presenterModeInitiatedBy &&
              presenterModeInitiatedBy !== participant.participantId &&
              !currentDesktop) 
            "
          >
              <!-- (room &&
              getAmIModerator &&
              presenterModeInitiatedBy &&
              presenterModeInitiatedBy !== participant.participantId &&
              !currentDesktop && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID)) -->
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'comment']"
                :style="{ fontSize: '20px' }"
              />
              <span
                class="toggleGiveWordBadge"
                v-show="wavingHand.indexOf(participant.participantId) !== -1"
              >
                <font-awesome-icon
                  :icon="['fal', 'exclamation']"
                  :style="{ fontSize: '19px', padding: 1 }"
                />
              </span>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.callsContent.giveWord") }}
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-if="getIsConferenceHall" @click="setUserInModal(participant.userUUID)">
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'info-circle']"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("components.adminSettingsModal.userInfo") }}
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item
            v-show="showTakeWord"
            @click="toggleListenerSpeakerConferenceHall(participant.userUUID)"
          >
            <!-- <v-list-item-icon v-if="!isListenerSpeakerConferenceHall">
              <font-awesome-icon
                :icon="['fal', 'comment']"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon> -->
            <v-list-item-icon>
              <font-awesome-icon
                :icon="['fal', 'comment-slash']"
                :style="{ fontSize: '20px' }"
              />
            </v-list-item-icon>
            <v-list-item-title class="inTwoLine">
              {{  $t("components.callsContent.moveToHall")  }} 
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import { hasPrivilege } from "../../../utils/privileges";
import {
  setHistoryMessagesModalEvent,
  setRequestPaymentModal,
  setUserProfileEvent
} from "../../../effector/modals";
import { messagesToMe } from "../../../effector/message";
import { myConferenceDetails } from "../../../effector/groupConferences";
import { myCompanyDocumentsDetails } from "../../../effector/companyDocuments";
import { wsCall } from "../../../lib/wsConnect";

export default {
  components: {},
  props: [
    "getActiveUsers",
    "moveMouserCardRemote",
    "hideRemoteBar",
    "hasPrivilege",
    "participant",
    "isUserModerator",
    "room",
    "parentReferences",
    "participantsMuted",
    "getMicrophoneLevel",
    "isFullScreen",
    "exitFullScreen",
    "enterFullScreen",
    "setUserMaximized",
    "isLocalFullScreen",
    "mutedByModerator",
    "getAmIModerator",
    "toggleGiveWord",
    "wavingHand",
    "presenterModeInitiatedBy",
    "currentDesktop",
    "setMaximizeScreen",
    "showBarOnOver",
    // "disableFullScreen",
    "fullScreenActive",
    "isOnlyOneCallUser",
    "isConferenceCall",
    "actualFullScreenTypeRemote",
    "changeActualFullScreenTypeRemote",
    "changeMenuIsOpen",
    "wordGivenTo",
    "speakerView",
    "changeSpeakerView",
    "participantsCameraOff",
    "toggleCameraOff",
    "getIsParticipantIsPodium",
    "getIsConferenceHall",
    "getCallUUID",
    "micMutedByAdmin",
    "toggleRemoteMic"
    // "toggleListenerSpeakerConferenceHall",
    // "isListenerSpeakerConferenceHall"
  ],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      positionOfFullScreenArray: 0,
      // fullScrennType: ["fullscreen", "maximize", "maximizeScreen", "normal"],
      fullScrennType: ["fullscreen", "maximizeScreen"],
      isMobile: isMobile(),
      menuOpen: false,
    };
  },
  mounted() {
    EventBus.$on("toggleFullscreenRoutine", this.toggleFullscreenRoutine);
  },
  destroyed() {
    EventBus.$off("toggleFullscreenRoutine", this.toggleFullscreenRoutine);
  },
  effector: {
    messagesToMe: messagesToMe,
    myConferenceDetails: myConferenceDetails,
    myDocuments: myCompanyDocumentsDetails,
  },
  watch: {
    menuOpen: {
      // immediate: true,
      handler: function (value) {
        this.changeMenuIsOpen(value);
      },
    },
    fullScreenActive: {
      // immediate: true,
      deep: true,
      handler: function (isFullScreen) {
        if (!isFullScreen) {
          this.positionOfFullScreenArray = 0;
          this.changeActualFullScreenTypeRemote("maximizeScreen");
        }
        //  if(isFullScreen){
        //    this.positionOfFullScreenArray = 1

        //  }else {
        //    this.positionOfFullScreenArray = 0
        //    this.changeActualFullScreenTypeRemote('maximizeScreen')
        //  }
      },
    },
  },
  methods: {
    toggleRemoteMicMethod(participant){
      this.toggleRemoteMic(participant)
    },
    toggleListenerSpeakerConferenceHall(user){
       const callUUID = this.getCallUUID;
         if(!callUUID) return false
        const infoCall = this.state.remoteBridgeStreams[callUUID]
        wsCall("sendToUUID", user, {
          type: "bridge-signal",
          action: "podium_listener",
          sender: this.state.ownUUID,
          callUUID: callUUID,
          infoCall: infoCall
        });
    },
    setUserInModal(uuid) {
      //open modal if im not guest
      if (!uuid) return;
      const person = this.state.group[uuid];
      store.state.ownModalUser = uuid === this.state.ownUUID;
      store.state.userOnModal = person.user;
      store.state.userOnModal.connected = person.user.connected;
      store.state.userOnModal.uuid = uuid;
      store.state.userOnModal.userTS = person.userTS;
      setUserProfileEvent(true);
    },
    toggleCameraOffRemote(participantId) {
      this.toggleCameraOff(participantId);
    },
    getUUIDForDisplayName(userUUID) {
      return userUUID.split("_")[1];
    },
    setInfoModal(data) {
      return store.setinfoModal(data);
    },
    setRequestPayment(uuid, tileId) {
      const callUUID = this.getCallUUID;
      const obj = {
        receiver: uuid,
        tileId: tileId,
        callUUID: callUUID,
      };
      setRequestPaymentModal(obj);
    },
    requestPayment() {
      if (!this.room) return;
      if (this.isPayEnabled) {
        const objParticipant = this.room.getParticipantById(
          this.participant.participantId
        );
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        this.setRequestPayment(personId, this.participant.participantId);
      } else {
        const data = {
          show: true,
          header: this.$t("generics.info"),
          body: this.$t("components.inviteVisitor.payLinkNote"),
        };
        this.setInfoModal(data);
      }
    },
    toggleFullscreenRoutine(id = false) {
      const participantId = id ? id : this.participant.participantId;
      // if (this.isLocalFullScreen) {
      //   this.positionOfFullScreenArray = 0;
      // }
      const mode = this.fullScrennType[this.positionOfFullScreenArray];
      this.changeActualFullScreenTypeRemote(mode);

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to fullscreen
      // +++++++++++++++++++++++++++++++++++++++++++++++
      if (mode == "fullscreen") {
        if (!this.isFullScreen()) {
          let elem =
            this.parentReferences[
              `remoteParticipantContainer-${participantId}`
            ][0];
          this.enterFullScreen(elem);
        }
      }

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to maximize mode
      // +++++++++++++++++++++++++++++++++++++++++++++++

      // if (mode == "maximize") {
      //   if (this.isFullScreen()) {
      //     this.exitFullScreen();
      //   }
      //   this.setUserMaximized(participantId);
      // }

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to maximizeScreen mode
      // +++++++++++++++++++++++++++++++++++++++++++++++

      if (mode == "maximizeScreen") {
        if (this.isFullScreen()) {
          this.exitFullScreen();
        }
        this.setMaximizeScreen(true);
      }

      // +++++++++++++++++++++++++++++++++++++++++++++++
      // Switch to normal mode
      // +++++++++++++++++++++++++++++++++++++++++++++++
      // if (mode == "normal") {
      //   if (this.isFullScreen()) {
      //     this.exitFullScreen();
      //   }
      //   this.setMaximizeScreen(null);
      //   this.setUserMaximized(null);
      // }

      this.positionOfFullScreenArray += 1;
      if (this.positionOfFullScreenArray > this.fullScrennType.length - 1) {
        this.positionOfFullScreenArray = 0;
      }
    },
    async toggleHangUp() {
      // console.log('hanging up ')
      if (this.room) {
        if (
          this.room
            .getParticipantById(this.participant.participantId)
            .isModerator()
        ) {
          await this.room.takeMod(this.participant.participantId);
        }
        if (this.room.isModerator()) {
          this.room.kickParticipant(this.participant.participantId);
        } else {
          this.room.sendTextMessage(
            JSON.stringify({
              cmd: "cmdLeave",
              user: this.participant.participantId,
            })
          );
        }
      }
    },
    // toggleRemoteMic() {
      // if (
      //   this.participantsMuted[this.participant.participantId] &&
      //   this.participantsMuted[this.participant.participantId].muted == true
      // ) {
      //   delete this.participantsMuted[this.participant.participantId];
      //   this.room.sendTextMessage(
      //     JSON.stringify({
      //       cmd: "cmdParticipantsMuted",
      //       value: this.participantsMuted,
      //     })
      //   );
      //   if (this.parentReferences[this.participant.audioTrack]) {
      //     this.parentReferences[this.participant.audioTrack][0].muted = false;
      //   }
      // } else {
      //   this.participantsMuted[this.participant.participantId] = {
      //     muted: true,
      //     byAdmin: true,
      //   };
      //   this.room.sendTextMessage(
      //     JSON.stringify({
      //       cmd: "cmdParticipantsMuted",
      //       value: this.participantsMuted,
      //     })
      //   );
      //   if (this.parentReferences[this.participant.audioTrack]) {
      //     this.parentReferences[this.participant.audioTrack][0].muted = true;
      //   }
      // }
    // },

    getEmailForUuid(userUUID) {
      return store.getEmailForUuid(userUUID);
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    emailSalutation() {
      return encodeURIComponent(
        this.$t("components.userListItem.emailSalutation")
      );
    },
    toggleSendEmail() {
      const userUUID = this.participant.userUUID;
      const userEmail = this.getEmailForUuid(userUUID);
      const subject = encodeURIComponent(
        this.$t("components.callsContent.vOfficeMessage")
      );
      const mailData = `mailto:${userEmail}?subject=${subject}&body=${this.emailSalutation()}%20${this.getNameForUuid(
        userUUID
      ).replace(/\s{2,}/g, " ")},`;
      window.open(mailData, "_blank");
    },
    toggleMessage() {
      if (this.participant.userUUID) {
        setHistoryMessagesModalEvent(this.participant.userUUID);
      }
    },
    async toggleModerator(participantId) {
      if (!this.room) return;
      if (!participantId) return;
      // remove camare off
      if (
        this.participantsCameraOff &&
        this.participantsCameraOff[participantId]
      ) {
        this.toggleCameraOffRemote(participantId);
      }
      this.room.giveMod(participantId);
    },
  },
  computed: {
    userHasEmail(){
      if (this.participant && this.state.group[this.participant.userUUID]?.user?.email?.trim().length) return true;
      return false;
    },
    showIconsMic(){
      if(!this.participant.participantId) return 'mic';
      if(this.micMutedByAdmin.indexOf(this.participant.participantId) == -1) {
        // If the user is not muted by moderator, check if the user is muted by hiself or not
        if (this.participantsMuted.indexOf(this.participant.participantId) == -1) return 'mic';
        if(this.participantsMuted.indexOf(this.participant.participantId) !== -1) return 'micMuted'
      } else {
        // user is muted by admin
        return 'micMutedByAdmin';
      }
      
    },
    showTakeWord(){
      // prevent to move the creator to the listener view
      const participantUUID = this.participant.userUUID
      if( this.amIModerator && 
          this.getIsConferenceHall && 
          this.getIsParticipantIsPodium(participantUUID) && 
          this.getShareScreenTopBar !==participantUUID && 
          (this.state.remoteBridgeStreams[this.getCallUUID] || {}).initiator !== participantUUID
      ){
        return true;
      }
      return false;
    },
    getShareScreenTopBar() {
      if (!this.room) return false;
      if (this.currentDesktop) {
        const objParticipant = this.room.getParticipantById(
          this.currentDesktop
        );
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        return personId;
      }
      return false;
    },
    amIGuest() {
      if (!store.state.ownUUID) return false;
      return store.getUserIsGuest(store.state.ownUUID);
    },
    isFullScreenMode() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    showHangUpButtonMenu() {
      if (!this.room) return false;
      // if is conference call
      if (this.isConferenceCall && !this.getIsConferenceHall) {
        if (this.amIPrivileges
            && this.amIModerator
            && this.presenterModeInitiatedBy
            && this.presenterModeInitiatedBy !== this.participant.participantId) {
          return true;
        } else {
          return false;
        }
        // conference hall calls
      } else if (this.getIsConferenceHall){
        if (this.amIPrivileges
            && this.amIModerator
            && this.presenterModeInitiatedBy
            && this.presenterModeInitiatedBy !== this.participant.participantId
            && (this.state.remoteBridgeStreams[this.getCallUUID] || {}).initiator !== this.participant.userUUID) {
          return true;
        } else {
          return false;
        }
      } else {
        // if is normal call
        if (
          this.amIPrivileges &&
          (this.amIModerator || !this.isUserModerator) &&
          (this.presenterModeInitiatedBy 
          && this.presenterModeInitiatedBy !== this.participant.participantId)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showHangUpButton() {
      if (!this.room) return false;
      // if is conference call
      if (this.isConferenceCall && !this.getIsConferenceHall) {
        if (this.amIPrivileges && this.amIModerator && !this.presenterModeInitiatedBy) return true;
        return false;
        // conference hall calls
      } else if (this.getIsConferenceHall){
        return false;
      } else {
        // if is normal call
        if (this.amIPrivileges &&
          (this.amIModerator || !this.isUserModerator) &&
          !this.presenterModeInitiatedBy 
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    unseeMessageOneCallUser() {
      const receivedMessage = this.messagesToMe.filter(
        (message) =>
          message.creatorUUID == this.participant.userUUID &&
          message.users[this.state.ownUUID] &&
          !message.users[this.state.ownUUID].read
      );
      return receivedMessage.length;
    },
    isPayAvailable() {
      // See also websrc/components/modal/newAdminSettingsModal.vue isPayAvailable
      return webLicensedBaseFeatures.isVisitorPayAvailable;
    },
    isPayEnabled() {
      // See also websrc/components/content/inviteForm.vue isPayEnabled
      return (
        webLicensedBaseFeatures.isVisitorPayAvailable &&
        this.state.namespaceSettings.paySetting
      );
    },
    amIModerator() {
      if (!this.room) return false;
      return this.room.isModerator();
    },
    amIPrivileges() {
      return hasPrivilege(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.inTwoLine{
  white-space: unset;
  text-overflow: unset;
  overflow: unset;
}
.customMenu {
  z-index: 10px;
  // top: 52px !important;
  top: 40px !important;
  left: unset !important;
}
.menuRemoteButtons {
  top: -173px !important;
}

.menuRemoteButtonsSharePres {
  top: -223px !important;
}
.ttFullScreen {
  top: -34px !important;
}
.progressBarLevelBridge {
  width: 1%;
  height: 3px;
  max-height: 3px;
  min-height: 3px;
  background-color: #02889d;
}
.progressBarBridge {
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: -5px;
}
.pRelative {
  position: relative;

  .buttonMore {
    position: absolute;
    bottom: -3px;
    height: 15px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    right: -3px;
    opacity: 0.6;

    &:hover {
      background: var(--v-primary-base);
    }
  }
}
.iconsRemote {
  z-index: 11 !important;
}
.icons {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 0;
  top: 30px !important;
  margin: 0 !important;
  height: 36px;
  width: 100%;
  text-align: left;
  display: flex;
  left: 0;
  justify-content: center;
  cursor: default !important;

  .buttosContainer {
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
    &:hover {
      opacity: 0.8;
    }
  }

  .buttonsCall {
    border-radius: 50%;
    margin: 0 5px;

    .rotatedIcon {
      transform: rotate(226deg);
    }

    &:hover {
      box-shadow: 4px 4px 48px rgb(14, 13, 13);
      background: #242525;
    }
  }
}

.currency {
  height: 19px;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.progressBarBridge {
  position: absolute !important;
  left: 0px !important;
  bottom: -5px !important;
}
.toggleGiveWordBadge {
  position: absolute;
  top: 0px;
  left: 30px;
  padding: 0px 6px;
  border-radius: 50% !important;
  background: var(--v-primary-base) !important;
  color: white;
}
</style>

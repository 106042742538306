<template>
  <div class="settings-right">
    <v-menu offset-y v-if="!state.setUpConfig.showSetUp" v-model="showMenu">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          color="default"
          v-on="on"
          @dblclick.stop.prevent="seeAllExcel"
          id="firstElement"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <font-awesome-icon
                v-on="on"
                class="primary--text settingsIcon"
                :icon="['fal', 'cog']"
                :style="{ fontSize: '20px' }"
              />
            </template>
            <span>{{ $t("generics.settings") }} </span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="toggleCamMic()">
            <v-list-item-icon class="marginRight10">
              <font-awesome-icon
                :icon="['fal', 'video']"
                :style="{ fontSize: '16px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ $t("components.navbar.camAndMic") }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in getSortedList"
            :key="i"
            @click="
              item.index === 1
                ? openUserSettings()
                : item.index === 2
                ? seeExcel()
                : item.index === 4
                ? toggleModalAdminSettings()
                : item.index === 5
                ? goToPayments()
                : (item.index === 7 && hasDailyReportEnabled)
                ? openDailyReportModal()
                : null
            "
            :style="[
              ((item.index === 4 ||
                item.index === 3 ||
                item.index === 2 ||
                item.index === 5) &&
                !amIAdmin) ||
              ((item.index === 4 ||
                item.index === 3 ||
                item.index === 2 ||
                item.index === 5) &&
                isMobile)||
              (item.index === 7  && !hasDailyReportEnabled)
                ? { display: 'none' }
                : { display: 'flex' },
            ]"
          >
            <v-list-item-icon class="marginRight10">
              <font-awesome-icon
                v-if="item.index == 1"
                :icon="['fal', 'user-cog']"
                :style="{ fontSize: '16px' }"
              />
              <font-awesome-icon
                v-if="item.index == 2"
                :icon="['fal', 'users-cog']"
                :style="{ fontSize: '16px' }"
              />
              <font-awesome-icon
                v-if="item.index == 4"
                :icon="['fal', 'cog']"
                :style="{ fontSize: '16px' }"
              />
              <img
                v-if="item.index == 5 && !$vuetify.theme.dark"
                class="facePayLogo"
                src="img/icons/facepay-settings.svg"
                lazy-src="img/icons/facepay-settings.svg"
              />
              <img
                v-if="item.index == 5 && $vuetify.theme.dark"
                class="facePayLogo"
                src="img/icons/facepay-settings-white.svg"
                lazy-src="img/icons/facepay-settings-white.svg"
              />
                 <font-awesome-icon
                v-if="item.index == 7"
                :icon="['fal', 'file']"
                :style="{ fontSize: '16px' }"
              />
            </v-list-item-icon>
            <v-list-item-title v-if="item.index == 1">
              <span>{{ $t("components.userSettings.userSettings") }}</span>
            </v-list-item-title>
            <v-list-item-title v-if="item.index == 2">
              <span>{{ $t("components.userSettings.userManagement") }}</span>
            </v-list-item-title>
            <v-list-item-title v-if="item.index == 4">
              <span>{{ $t("generics.adminSettings") }}</span>
            </v-list-item-title>
            <v-list-item-title v-if="item.index == 5">
              <span>{{ item.name }}</span>
            </v-list-item-title>
            <v-list-item-title v-if="item.index == 6">
              <span>{{ $t("components.navbar.camAndMic") }}</span>
            </v-list-item-title>
             <v-list-item-title v-if="item.index == 7">
              <span>{{ $t("components.adminSettingsModal.dailyReport") }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showHelpCenter()">
            <v-list-item-icon class="marginRight10">
              <font-awesome-icon
                :icon="['fal', 'question-circle']"
                :style="{ fontSize: '16px' }"
              />
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ $t("generics.support") }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showHotkeysModal()">
            <v-list-item-icon class="marginRight10">
              <font-awesome-icon
                :icon="['far', 'keyboard']"
                :style="{ fontSize: '16px' }"
                class="cursorPointer imageIcon"
              />
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ $t("generics.hotkeys") }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showChangelog()">
            <v-list-item-icon class="marginRight10">
              <v-img
                height="25"
                width="25"
                max-height="25"
                max-width="25"
                class="cursorPointer imgBubble"
                :src="
                  !updateInfoDisplayed &&
                  dirty &&
                  !callInfoStateStore.callActive &&
                  !inBridgeCall
                    ? 'img/light-bulb.svg'
                    : 'img/light-bulb-blue.svg'
                "
                contain
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ $t("generics.changeLog") }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showvOfficePro()">
            <v-list-item-icon class="marginRight10">
              <v-img
                height="20"
                width="20"
                max-height="20"
                max-width="20"
                class="cursorPointer imageIcon"
                :src="'assets/icon.png'"
                contain
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title>
              <span>{{ $t("generics.aboutvOffice") }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <CustomTT
      v-if="showCustomTT"
      :text="`${$t('components.hotkeys.controlKey')} + M`"
      position="bottom"
      :extraclass="'ctrm'"
    />
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { callInfoStateStore } from "../../effector/call";
import { isDirtyStore } from "../../effector/version";
import { messages } from "../../_locales";
import {
  setUserSettingsEvent,
  setChangelogModalEvent,
  setHotkesysvent,
  setDailyReportModalEvent
} from "../../effector/modals";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { hasPrivilege } from "../../utils/privileges";
import { amInABridgeCall } from "../../utils/calls";
import {isDailyReportEnabled} from "../../utils/basicFunctions"
import SendInvitationModal from "../modal/sendInvitationModal.vue";
export default {
  props: [
    "amIAdmin",
    "isMobile",
    "toggleModalAdminSettings",
    "mini",
    "openCloseMenu",
  ],
  components: {
    SendInvitationModal,
    CustomTT
  },
  data: () => ({
    state: store.state,
    showMenu: false,
    menu: false,
    updateInfoDisplayed: false,
  }),
  effector: {
    dirty: isDirtyStore,
    callInfoStateStore: callInfoStateStore,
    showCustomTT: hotkeysInfoStore,
  },
  mounted() {
    EventBus.$on("openSettingsMenuEvent", this.openMenuSettings);
  },
  destroyed(){
    EventBus.$off("openSettingsMenuEvent", this.openMenuSettings);
  },
  methods: {
    openDailyReportModal(){
      setDailyReportModalEvent(true)
    },
    toggleCamMic() {
      EventBus.$emit("togleMicSettings");
    },
    goToPayments() {
      if (!store.getUserIsAdmin(this.state.ownUUID)) {
        return;
      }
      this.$router.push({ path: "/payments" });
    },
    showHotkeysModal() {
      setHotkesysvent(true);
    },
    showvOfficePro() {
      const lang = store.state.user.language;
      if (lang && lang === 'de'){
        window.open("https://www.voffice.pro");
      } else {
        window.open("https://www.voffice.pro/en");
      }
    },
    showHelpCenter() {
      window.open("https://helpcenter.voffice.pro");
    },
    showChangelog() {
      setChangelogModalEvent(true);
      this.updateInfoDisplayed = true;
    },
    openUserSettings() {
      setUserSettingsEvent(true);
    },
    openMenuSettings(e) {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        setTimeout(() => {
          document.getElementById("firstElement").focus();
        }, 500);
      }
    },
    seeAllExcel() {
      this.setExcelTotalView(true);
      this.editExcel();
    },
    setExcelTotalView(value) {
      return store.setExcelTotalView(value);
    },
    editExcel() {
      if (!store.getUserIsAdmin(this.state.ownUUID)) {
        return;
      }
      if (!this.mini) {
        this.openCloseMenu();
      }
      store.setCurrentContentVisile(
        "editUploadExcell",
        true,
        this.$router,
        "/"
      );
    },
    seeExcel() {
      this.setExcelTotalView(false);
      this.editExcel();
    },
  },
  computed: {
    hasDailyReportEnabled(){
      if(this.isDailyReportEnabled && this.state.namespaceSettings.dailyReportSettings.members && this.state.namespaceSettings.dailyReportSettings.members[this.state.ownUUID] &&  this.state.namespaceSettings.dailyReportSettings.members[this.state.ownUUID].length>0) return true
      return false;
    },
    inBridgeCall() {
      return amInABridgeCall();
    },
    isDailyReportEnabled(){
      return isDailyReportEnabled()
    },
    getSortedList() {
      return [
        { index: 1, name: this.$t("components.userSettings.userSettings") },
        { index: 2, name: this.$t("components.userSettings.userManagement") },
        { index: 4, name: this.$t("generics.adminSettings") },
        { index: 5, name: this.$t("components.payment.payment") },
        { index: 7, name: this.$t("components.adminSettingsModal.dailyReport") },
      ].sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    hasPrivilege() {
      return hasPrivilege(this.state.ownUUID);
    },
    locales() {
      return Object.keys(messages).map((key) => messages[key]._locale);
    },
  },
};
</script>
<style scoped lang="scss">
.facePayLogo {
  height: 20px;
  width: 20px;
}
.imageIcon {
  margin-left: -1px;
}
.imgBubble {
  margin-left: -5px;
}
.pointer {
  cursor: pointer;
}
.blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.ctrm {
  bottom: -5px;
  right: 117px;
  z-index: 101;
}
</style>
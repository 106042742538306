<template>
  <div>
    <v-tooltip bottom v-if="!isMobile && !sidebar">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          :color="wholeBarWithColors() ? 'white' : 'black'"
          :disabled="userInCall || startingConference"
          @click.stop.prevent="startInstaConference()"
        >
          <font-awesome-icon
            :icon="['fal', 'bolt']"
            :style="{fontSize: '20px'}"
          />
        </v-btn>
      </template>
      <span>{{ $t("components.conferenceForm.startInstantConference") }}</span>
    </v-tooltip>
    <v-list v-if="sidebar && !mini" max-width="320" @click.stop.prevent>
      <v-list-item @click.stop.prevent="startInstaConference()" :disabled="userInCall || startingConference" v-if="!isGuest && !mini">
        <!-- :appendIcon="sortedFavoriteUsers.length === 0 ? null : '$expand'" -->

          <v-list-item-icon>
            <font-awesome-icon
              :icon="['fal', 'bolt']"
              class="primary--text"
              :style="{fontSize: '20px'}"
            />
          </v-list-item-icon>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-list-item-title

                v-on="on"
              >
                {{ $t("components.conferenceForm.startInstantConference") }}
              </v-list-item-title>
            </template>
            <span>{{
              $t("components.conferenceForm.startInstantConference")
            }}</span>
          </v-tooltip>
      </v-list-item>
    
    </v-list>
      <v-tooltip right v-if="!isGuest && mini">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" class="cursorPointer" :disabled="userInCall || startingConference">
            <v-list-item-icon @click.stop.prevent="startInstaConference()">
              <font-awesome-icon
                :icon="['fal', 'bolt']"
                class="primary--text"
                :style="{fontSize: '20px'}"
              />
            </v-list-item-icon>
          </v-list-item>
        </template>
        <span>{{
          $t("components.conferenceForm.startInstantConference")
        }}</span>
      </v-tooltip>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { userInCall, wsCallStartBridgeCall } from "../../utils/calls";
import moment from "../../../sharedsrc/moment";
import { hasPrivilege } from "../../utils/privileges";
import { markAsCallWaitingRoomUserEvent } from "../../effector/users/waitingInfo";
import { v4 as uuidv4 } from "uuid";
import {
  myConferenceDetails,
  newGroupConferenceEvent,
} from "../../effector/groupConferences";
import { createTimelineEntryEffect } from "../../effector/timeline";
import { getTimezone } from "../../utils/basicFunctions";
import { getDirectCallInviteLink } from "../../lib/wsMsg";
// import { dispatchSuccessAlert } from "../../effector/alerts";
import { wholeBarWithColor } from "../../utils/color";
import { dispatchErrorAlert } from "../../effector/alerts";
export default {
  props: ["sidebar", "mini"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      moment: moment,
      members: [
        {
          name: store.state.user.name,
          uuid: store.state.ownUUID,
          isModerator: true,
          isGuest: false,
          isGroup: false,
          isTeam: false,
          tempDocumentStore: null,
        },
      ],
      openModalSettingsCall: undefined,
      startingConference: false
    };
  },
  effector: {
    myConferenceDetails: myConferenceDetails,
  },
  watch: {
    userInCall: {
      handler(value) {
        if (value && this.openModalSettingsCall) {
          EventBus.$emit("openModalSettingsCall");
          this.openModalSettingsCall = undefined;
        }
      },
    },
  },
  mounted() {
    EventBus.$on('startInstaConference', this.startInstaConference);
  },
  destroyed() {
    EventBus.$off('startInstaConference', this.startInstaConference);
  },
  methods: {
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    async getUniversalLink() {
      return await getDirectCallInviteLink();
    },
    // async copyLink() {
    //   let instalink = await this.getUniversalLink();
    //   const selBox = document.createElement("textarea");
    //   selBox.style.position = "fixed";
    //   selBox.style.left = "0";
    //   selBox.style.top = "0";
    //   selBox.style.opacity = "0";
    //   selBox.value = instalink;
    //   document.body.appendChild(selBox);
    //   selBox.focus();
    //   selBox.select();
    //   document.execCommand("copy");
    //   document.body.removeChild(selBox);
    //   dispatchSuccessAlert(
    //     this.$t("components.conferenceForm.copyLink")
    //   );
    // },
    async startInstaConference() {
      if (this.userInCall) return;
      this.startingConference = true;
      // create temp object
      const time = new Date();
      const startEvent = this.moment(time).format("YYYY-MM-DD");
      const timeStart = this.moment(time).format("HH:mm");
      const dataConference = {
        confIsTemp: true,
        isUnlimited: true,
        conferenceId: uuidv4(),
        confName: "Instaconference",
        conferenceDescription: "Instaconference-Temp",
        conferenceImage: this.state.namespaceSettings.companyLogo
          ? this.state.namespaceSettings.companyLogo
          : "/img/icon.png",
        conferenceISOStartDate: new Date(),
        startEvent: startEvent,
        endEvent: startEvent,
        timeStart: timeStart,
        timeEnd: timeStart,
        durationMin: null,
      };
      const tempConf = await this.saveConference(dataConference);
      if (tempConf && tempConf.confId) {
        const myConference = this.myConferenceDetails.find(
          (e) => e.confId == tempConf.confId
        );
        if (myConference && myConference.confId) {
          this.openModalSettingsCall = true;
          await this.callGroup(myConference, this.members);
        }
      }
      setTimeout(() => {
        this.startingConference = false;
      }, 900);
    },
    async saveConference(dataConference) {
      // save conference
      let transResults = false;
      let confObject = {};
      if (dataConference) {
        try {
          confObject = {
            confId: dataConference.conferenceId,
            confIsTemp: dataConference.confIsTemp,
            confName: dataConference.confName,
            confDescription: dataConference.conferenceDescription,
            confOwner: this.ownUUID,
            confModerators: [this.ownUUID],
            confPin: "",
            confStartDate: dataConference.startEvent,
            confStartTime: dataConference.timeStart,
            confEndDate: dataConference.isUnlimited
              ? dataConference.startEvent
              : dataConference.endEvent,
            confEndTime: dataConference.isUnlimited
              ? dataConference.timeStart
              : dataConference.timeEnd,
            confTimezone: getTimezone(dataConference.startEvent, dataConference.timeStart),
            confDuration: dataConference.isUnlimited
              ? 0
              : dataConference.durationMin,
            isUnlimited: dataConference.isUnlimited,
            confIsReocurring: false,
            confIsReoInterval: null,
            confIsReoFrequency: null,
            members: this.members,
            confUUIDS: await this.extractUsers(store.state.user.uuid),
            confRealUsers: await this.extractOnlyUsers(store.state.user.uuid),
            creationServerTs: Date.now(),
            updatedServerTs: Date.now(),
            confAvatar: dataConference.conferenceImage,
            timelineEventId: undefined,
          };
          await newGroupConferenceEvent(confObject);
          transResults = true;
          // if (!dataConference.confIsTemp) {
          //   this.goBackToGrid();
          //   dispatchSuccessAlert(this.$t("components.groups.templateCreated"));
          // }
        } catch (err) {
          console.warn("Error creating conference", err);
          return dispatchErrorAlert("Error creating conference");
        }
      } else {
        return dispatchErrorAlert("Error creating conference");
      }

      // CREATE DOCUMENT STORE
      // const entryDocument = {
      //   docStoreUUID: uuidv4(),
      //   docMembersUUIDS: [store.state.ownUUID],
      //   moderators: [store.state.ownUUID],
      //   creator: store.state.ownUUID,
      //   callUUID: false,
      //   confId: dataConference.conferenceId,
      //   timelineId: false,
      //   templateId: false,
      //   documents: []
      //   };
      // await newCompanyDocumentEvent(entryDocument);
      // if (this.tempDocumentStore && this.tempDocumentStore.documents.length) {
      //   this.tempDocumentStore.docMembersUUIDS = confObject.confUUIDS;
      //   this.tempDocumentStore.moderators = confObject.confModerators;
      //   this.tempDocumentStore.confId = confObject.confId;
      //   await newCompanyDocumentEvent(this.tempDocumentStore);
      //   this.tempDocumentStore = null;
      // }

      // CREATE TIMELINE EVENT TO ALL USERS
      if (transResults) {
        transResults = false;
        confObject.rsvp = true;
        const event = await this.createTimelineEntry(confObject);
        if (event) {
          return event;
        }
      }
    },
    zeroFill(time) {
      return ("0" + time).slice(-5);
    },
    async createTimelineEntry(data) {
      const dateTimeStart =
        data.confStartDate + "T" + this.zeroFill(data.confStartTime);
      const dateTimeEnd =
        data.confEndDate + "T" + this.zeroFill(data.confEndTime);
      const tmpData = {
        confId: data.confId,
        name: data.confName,
        details: data.confDescription,
        isTemp: data.confIsTemp,
        users: data.confUUIDS,
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: "orange",
        type: "videoCall",
        host: data.confOwner,
        title: data.confName.trim(),
        eventType: "bridgeCall",
        isUnlimited: data.isUnlimited,
        isReOcurring: data.confIsReocurring || false,
        confIsReoInterval: data.confIsReoInterval || null,
        confIsReoFrequency: data.confIsReoFrequency || null,
        viewed: {},
        rsvp: true,
      };
      const meeting = await createTimelineEntryEffect(tmpData);
      return meeting;
    },
    extractOnlyUsers(owner) {
      const allUsers = this.extractUsers(owner);
      let onlyUsers = [];
      if (allUsers && allUsers.length) {
        onlyUsers = allUsers.filter(
          (e) => (store.state.group[e] || {}).permissions !== 10
        );
      }
      return onlyUsers;
    },
    extractUsers(owner) {
      let users = [];
      this.members.forEach((member) => {
        if (member.isTeam) {
          const extant =
            this.state.namespaceSettings.processedOrganisation.teams[
              member.name
            ];
          const tempMembers = extant.members;
          extant.supervisors.forEach((element) => {
            if (tempMembers.indexOf(element.uuid) == -1) {
              if (this.state.group[element.uuid]) {
                tempMembers.push(element.uuid);
              }
            }
          });
          tempMembers.forEach((id) => {
            if (users.indexOf(id) == -1) {
              if (this.state.group[id]) {
                users.push(id);
              }
            }
          });
        } else if (member.isGroup) {
          if (this.state.group[member.uuid]) {
            const extant = store.state.group[owner].user.groups.find(
              (e) => e.groupId == member.uuid
            );
            const extantMmembers = extant.groupMembers;
            const members = extantMmembers.map((member) => member.uuid);
            members.forEach((el) => {
              if (users.indexOf(el) == -1) {
                users.push(el);
              }
            });
          }
        } else if (member.uuid) {
          if (this.state.group[member.uuid]) {
            if (users.indexOf(member.uuid) == -1) {
              users.push(member.uuid);
            }
          }
        }
      });
      return users;
    },
    setdurationMeeting(duration = null) {
      return store.setdurationMeeting(duration || 300000);
    },
    getModerators(members) {
      let mods = [];
      members.forEach((member) => {
        if (member.isModerator === true) {
          mods.push(member.uuid);
        }
      });
      return mods;
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    async callGroup(conf, members) {
      const duration = 24 * 3600 * 1000;
      this.setdurationMeeting(duration);
      if (conf && conf.confUUIDS.length > 0) {
        const usersToCall = [];
        const usersFunction = [];
        if (this.$route.path !== "/home") {
          this.$router.push({ path: "/home" });
        }
        conf.confUUIDS.forEach((user) => {
          if (user !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[user] || {}).connected || false;
            const person = store.state.group[user];
            if (
              person &&
              person.user &&
              (!person.user.rtcCallStatus.length ||
                0 > 5 ||
                !person.user.inBridgeCall)
            ) {
              if (!this.userInCall) {
                if (
                  isConnected &&
                  (((store.state.group[user] || {}).user || {}).activity ||
                    false) !== "Holidays"
                ) {
                  usersToCall.push(user);
                }
              }
              if (!usersFunction[user]) {
                if (
                  this.state.group[user].user.position ||
                  this.state.group[user].user.visitorData.function
                ) {
                  usersFunction[user] =
                    this.state.group[user].user.position ||
                    this.state.group[user].user.visitorData.function;
                } else {
                  usersFunction[user] = "";
                }
              }
            }
          }
        });
        const id = uuidv4();
        const info = {
          callUUID: id,
          calling: usersToCall,
          isNormalMode: true,
          initiator: this.state.ownUUID,
          callStartTs: Date.now(),
          callDurationMs: duration,
          infiniteCall: false,
          isConference: true,
          conferenceAttendees: this.getAttendeesObject(conf.confUUIDS),
          isConferenceCall: conf.confId || undefined,
          confModerators: await this.getModerators(members),
          confId: conf.confId,
        };
        usersToCall.forEach(async (user, idx) => {
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: conf.confUUIDS.length > 0 ? true : false,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: info.callDurationMs,
            infiniteCall: false,
            conferenceDocuments: [],
            userConferencePosition: usersFunction || [],
            excludedFromCalling: [],
            conferenceAttendees: this.getAttendeesObject(conf.confUUIDS),
            conferenceSettings: {},
            usersToHideName: [],
            isConference: true,
            confId: conf.confId,
            isConferenceCall: conf.confId || undefined,
            confModerators: await this.getModerators(members),
            isPinProtected: conf.confPin.length > 0,
            pinCode: conf.confPin.length > 0 ? conf.confPin : false,
          };
          if (!hasPrivilege(user)) {
            markAsCallWaitingRoomUserEvent(user);
          }
          wsCallStartBridgeCall(
            user,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        });
        store.addRemoteBridgeStream(id, info);
        store.setCurrentContentVisile("", false, this.$router);
      }
    },
  },
  computed: {
    userInCall() {
      return userInCall(this.ownUUID);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
</style>

<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="630"
      @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal :titleModal="titleModal" :closeModalFunction="closeModal">
          <div
            class="w100"
            v-if="hasRepresentative(callUserInCallModalStore.uuid)"
          >
            Vertreter für {{ getNameForUuid(callUserInCallModalStore.uuid) }}
          </div>
        </HeaderModal>
        <v-row class="w-100 mx-0 my-0 px-3 pt-3">
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
            :class="{ 'text-center': isMobile && n == 1 }"
            class="my-0 py-0 px-0"
          >
            <div class="pl-3" v-if="n == 1">
              <v-list-item-avatar
                tile
                size="150"
                class="borderRadius4"
                :style="
                  getBorderByStatusFull(
                    state.group[callUserInCallModalStore.uuid]
                  )"
              >
                <v-img
                  class="borderRadius4"
                  max-height="150"
                  max-width="150"
                  contain
                  :src="
                    hasRepresentative(callUserInCallModalStore.uuid)
                      ? getAvatarForUuid(
                          hasRepresentative(callUserInCallModalStore.uuid)
                        )
                      : getAvatarForUuid(callUserInCallModalStore.uuid)
                  "
                  lazy-src="img/default_profile_picture.png"
                  v-if="
                    (hasRepresentative(callUserInCallModalStore.uuid)
                      ? getAvatarForUuid(
                          hasRepresentative(callUserInCallModalStore.uuid)
                        )
                      : getAvatarForUuid(callUserInCallModalStore.uuid)) !==
                    'img/default_profile_picture.png'
                  "
                ></v-img>
                <DefaultAvatar
                  :size="120"
                  :userData="state.group[callUserInCallModalStore.uuid].user"
                  v-if="
                    (hasRepresentative(callUserInCallModalStore.uuid)
                      ? getAvatarForUuid(
                          hasRepresentative(callUserInCallModalStore.uuid)
                        )
                      : getAvatarForUuid(callUserInCallModalStore.uuid)) ===
                    'img/default_profile_picture.png'
                  "
                ></DefaultAvatar>
              </v-list-item-avatar>
            </div>

            <div class="pt-3 pr-3" v-if="n == 2">
              <p>{{ $t("generics.inConversation") }}</p>
              <span v-for="(uuid, index) in getUsersInCall" :key="uuid">
                {{ titelPerson(uuid) }} {{ getNameForUuid(uuid)
                }}{{ getUsersInCall.length - 1 == index ? "" : "," }}
              </span>
            </div>
          </v-col>
        </v-row>
        <FooterModal :closeModalFunction="closeModal">
          <v-btn class="ml-3" @click="sendMessage(callUserInCallModalStore.uuid)">{{ $t("components.userListItem.callBackTT") }}</v-btn>
          <v-btn class="ml-3" @click="callUser()">{{ $t("generics.call") }}</v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                style="border-radius: 4px; min-width: 45px"
                class="ml-3"
                outlined
                v-on="on"
                icon
                color="white"
                tile
                @click="
                  setShowModalSenMsg(callUserInCallModalStore.uuid, 'message')
                "
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.userProfile.sendAMessage") }}</span>
          </v-tooltip>
        </FooterModal>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import { hasRepresentative } from "../../utils/representatives";
import { wsCallStartBridgeCall } from "../../utils/calls";
import MeetingTimeGlobalDuration from "./meetingTimeGlobalDuration.vue";
import MeetingTimeTitle from "./meetingTimeTitle.vue";
import MeetingTimeRelay from "./meetingTimeRelay.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { hasPrivilege } from "../../utils/privileges.js";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { newMessageEvent } from "../../effector/message";
import {
  setJoinBridgeCallModalEvent,
  callUserInCallModalStore,
  resetCallUserInCallModalEvent,
  setHistoryMessagesModalEvent
} from "../../effector/modals";
export default {
  components: {
    MeetingTimeGlobalDuration,
    MeetingTimeTitle,
    MeetingTimeRelay,
    DefaultAvatar,
    HeaderModal,
    FooterModal,
  },
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      isIpad: isIpad(),
      ownUUID: store.state.ownUUID,
    };
  },
  effector: {
    callUserInCallModalStore: callUserInCallModalStore,
    bridgeInfoStore: bridgeInfoStore,
  },
  mounted() {},
  methods: {
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
      this.closeModal();
    },
    sendMessage(userUUID) {
      if (!userUUID) return false;
      const title = this.state.user.titel && this.state.user.titel !== "unknown"
        ? this.state.user.titel
        : "";
      const text = this.$t("components.userListItem.callBackMessage", [
        `${title} ${this.state.user.name}`.trim(),
      ]);
      const header = {
        translateMessage: {
          translateKey: "components.userListItem.callBackMessage",
          params: [`${title} ${this.state.user.name}`.trim()],
        },
      };
      this.preparedataToSendMsgEffector(userUUID, text, header);
      this.closeModal()
    },
    preparedataToSendMsgEffector(id, text, header) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: text,
        header: header,
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
    titelPerson(uuid) {
      return store.getTitelForUuid(uuid);
    },
    callUser() {
      if (
        wsCallStartBridgeCall(
          this.callUserInCallModalStore.uuid,
          store.state.ownUUID,
          this.callUserInCallModalStore.infoStartBridgeCall,
          this.callUserInCallModalStore.skipTotalIncrement
        )
      ) {
        setJoinBridgeCallModalEvent({
          show: true,
          info: {
            user: this.callUserInCallModalStore.uuid,
            callUUID:
              this.callUserInCallModalStore.infoStartBridgeCall.callUUID,
          },
        });
      }
      this.closeModal();
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    },
    closeModal() {
      resetCallUserInCallModalEvent();
    },
  },
  computed: {
    getUsersInCall() {
      if (
        this.bridgeInfoStore &&
        this.bridgeInfoStore.calls &&
        Object.keys(this.bridgeInfoStore.calls).length
      ) {
        if (
          this.bridgeInfoStore.calls[
            this.callUserInCallModalStore.infoStartBridgeCall.callUUID
          ]
        ) {
          return this.bridgeInfoStore.calls[
            this.callUserInCallModalStore.infoStartBridgeCall.callUUID
          ].users;
        }
      }
    },
    getCallUUID() {
      let remoteStreamsKey;
      if (Object.keys(this.state.remoteBridgeStreams).length > 0) {
        remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      } else {
        remoteStreamsKey = "";
      }
      return remoteStreamsKey;
    },
    titleModal() {
      const typePerson = this.hasPrivilege(this.callUserInCallModalStore.uuid)
        ? this.$t("generics.userLowercase")
        : this.$t("generics.guestLowercase");
      if (
        !store.state.group[this.callUserInCallModalStore.uuid].beaconCallInvite
      ) {
        return (
          this.$t("components.meetingTime.titleModal") +
          " " +
          typePerson +
          " " +
          this.getNameForUuid(this.callUserInCallModalStore.uuid)
        );
      } else {
        return this.$t("components.meetingTime.titleModal") + " " + typePerson;
      }
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    showModal() {
      return this.callUserInCallModalStore.showModal;
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.v-avatar .v-image {
  border-radius: 10px !important;
}
.borderRadius4,
.v-avatar .v-image {
  border-radius: 4px !important;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>

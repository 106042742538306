import { render, staticRenderFns } from "./defaultAvatar.vue?vue&type=template&id=dcf617c4&scoped=true&"
import script from "./defaultAvatar.vue?vue&type=script&lang=js&"
export * from "./defaultAvatar.vue?vue&type=script&lang=js&"
import style0 from "./defaultAvatar.vue?vue&type=style&index=0&id=dcf617c4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcf617c4",
  null
  
)

export default component.exports
<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
       <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
       <v-card-text>
          <p class="breakSpaces">{{ state.modalPopUp.msg }}</p>
       </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    closeModal() {
       return store.setmodalPopUp(undefined);
    },
  },
  computed: {
    showModal() {
     return (
        store.state.modalPopUp &&store.state.modalPopUp.type === "popUp"
     )
    },
  },
};
</script>
<style scoped lang="scss">
.breakSpaces{
  white-space: break-spaces;
}

</style>

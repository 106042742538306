import Vue from 'vue';
import { getTimezone } from "../utils/basicFunctions";
import moment from "../../sharedsrc/moment";
import {
  createNewUser,
  setRemoteUser,
  sendInviteMailCustom,
  sendInviteWhatsApp,
  getUserInviteLink,
} from './wsMsg';

/**
 * @param {Object} visitorData
 * @param {string} visitorData.visitorId
 * @param {string} visitorData.name
 * @param {string=} visitorData.email
 * @param {string=} visitorData.mobilePhone
 * @param {string=} visitorData.subject
 * @param {string} visitorData.inviteText
 * @param {string=} visitorData.eventUUID
 * @param {string=} visitorData.linkDateTo
 * @param {string=} visitorData.linkDateFrom
 * @param {Object} options
 * @param {string=} options.eventUUID
 */
export async function sendVisitorEmail(visitorData, options = {}) {
  if (visitorData.inviteText) {
    const visitorDataPatch = { inviteText: visitorData.inviteText }; // Save inviteText for resend invite
    if (options.eventUUID && visitorData.inviteText.includes('%DECLINE_LINK%')) visitorDataPatch.eventUUID = options.eventUUID; // Save eventUUID for resend invite
    await setRemoteUser(visitorData.visitorId, { visitorData: visitorDataPatch }); // Commit visitorData changes
  }
  if (visitorData.email) {
    return sendInviteMailCustom(visitorData.visitorId, {
      name: visitorData.name,
      email: visitorData.email,
      guest: true,
      subject: visitorData.subject || Vue.prototype.$t('components.inviteVisitor.inviteSubject'),
      linkDateTo: visitorData.linkDateTo || visitorData.linkDateFrom,
      language: Vue.prototype.$locale.current(),
      customMessage: visitorData.inviteText,
      variables: visitorData.variables,
      carbonCopy: options.carbonCopy,
      eventUUID: options.eventUUID || (
        ((visitorData.inviteText || '').includes('%DECLINE_LINK%') && visitorData.eventUUID) || undefined),
    });
  } else if (visitorData.mobilePhone) {
    const optionsWhatsApp = {
      customMessage: visitorData.inviteText || '',
      ...options,
    };
    await sendInviteWhatsApp(visitorData.visitorId, optionsWhatsApp);
  } else {
    throw new Error('No email or mobile phone provided');
  }
}

/**
 * @param {Object} user
 * @param {string} user.uuid
 * @param {string} user.name
 * @param {string=} user.email
 * @param {string=} user.mobilePhone
 * @param {Object} message
 * @param {string} message.subject
 * @param {string} message.inviteText
 * @param {Object<string,string>} message.variables
 * @param {Object} options
 * @param {string=} options.eventUUID
 * @param {boolean=} options.carbonCopy
 * @param {boolean=} options.sendWhatsApp
 */
export async function sendUserEmail(user, message, options = {}) {
  if (user.email) {
    return sendInviteMailCustom(user.uuid, {
      name: user.name,
      email: user.email,
      guest: false,
      subject: message.subject,
      language: Vue.prototype.$locale.current(),
      customMessage: message.inviteText,
      variables: message.variables,
      carbonCopy: options.carbonCopy,
      eventUUID: options.eventUUID,
      conferenceNotification: true,
      noInviteLink: true,
    });
  } else if (user.mobilePhone && options.sendWhatsApp) {
    const optionsWhatsApp = {
      customMessage: message.inviteText || '',
      ...options,
    };
    await sendInviteWhatsApp(user.uuid, optionsWhatsApp);
  } else {
    throw new Error('No email or mobile phone provided');
  }
}

/**
 * @param {Object} visitorData
 * @param {string} visitorData.name
 * @param {string=} visitorData.email
 * @param {string=} visitorData.mobilePhone
 * @param {Object=} options
 */
export async function createVisitor(visitorData, options = {}) {
  visitorData.visitorId = await createNewUser(
    {
      sections: [],
      locations: [],
      teams: [],
      position: '',
      qualification: '',
      titel: '',
      name: visitorData.name,
      email: visitorData.email || '',
      conferenceId: visitorData.conferenceId,
      conferenceTitle: visitorData.conferenceTitle,
      accessmail: '',
      phone: '',
      extension: '',
      mobilePhone: visitorData.mobilePhone || '',
      device: '',
      hours: '',
      homeHours: '',
      coreHours: '',
      guest: false,
      visitor: true,
      visitorData,
    },
    visitorData.visitorId,
    options
  );
  if (options.sendMail) {
    // if error ????
    await sendVisitorEmail(visitorData, options);
  }
  return getUserInviteLink(visitorData.visitorId);
}

// +++++++++++++++++++++++++++++
// HELPERS FOR INVITATION EMAILS
// +++++++++++++++++++++++++++++
export async function getUserCompanyInviteText(uuid, name) {
  // "inviteCompanySubject"
  let userInviteText =  Vue.prototype.$t("components.invitePreview.inviteCompanyLineInviter") +
  '%COMPANY_NAME%' +
  Vue.prototype.$t("components.invitePreview.inviteCompanyLineInviterEnd") +
  "\n" +
  Vue.prototype.$t("components.invitePreview.inviteCompanyLinePhrase") +
  "\n" +
  Vue.prototype.$t("components.invitePreview.inviteCompanyLineLink") +
  "\n" +
  "%INVITE_LINK%" +
  "\n" +
  "\n";

  return userInviteText;
}

export async function getInviteTextForRem(tZone, conf, asHtml = false) {
  let userInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    userInviteText = conf.personalizedGreeting + "\n";
  }
  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteRemInviterPhrase", [conf.inviter]);
  if (formattedDateData) {
    userInviteText +=
      Vue.prototype.$t("components.invitePreview.inviteRemDatesPhrase", [
        formattedDateData.format("DD.MM.YYYY"),
        formattedDateData.format("HH:mm")
      ]);
  }
  userInviteText += "\n" +
    conf.confName +
    "\n";
    if (conf.confDescription && conf.confDescription.length){
      userInviteText += conf.confDescription + "." + "\n"+ "\n";
    }

  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteRemLinkPhrase") +
    "\n" +
    "%INVITE_LINK%" +
    "\n" +
    "\n";

  if (asHtml) {
    userInviteText += "[custom imprint]" +
      "\n";
  }
  return userInviteText;
}

export async function getInviteTextForCancellation(tZone, conf, asHtml = false) {
  let userInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    userInviteText = conf.personalizedGreeting + "\n";
  }
  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteCancelInviterPhrase", [conf.inviter]);
  if (formattedDateData) {
    userInviteText +=
      Vue.prototype.$t("components.invitePreview.inviteCancelDatesPhrase", [
        formattedDateData.format("DD.MM.YYYY"),
        formattedDateData.format("HH:mm")
      ]);
  }
  userInviteText += "\n" +
    conf.confName +
    "\n";

  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteCancelCancelled") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteCancelledNote") +
    "\n" +
    "\n";

  if (asHtml) {
    userInviteText += "[custom imprint]" +
      "\n";
  }
  return userInviteText;

}

export async function getInviteTextUserChange(tZone, conf, oldConfStartDate, oldConfStartTime, asHtml = false) {
  let userInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedOldDateData = getFormattedConfDates(timezone, oldConfStartDate, oldConfStartTime);
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    userInviteText = conf.personalizedGreeting + "\n";
  }
  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteChangeLine1", [conf.inviter]) +
    "\n" +
    conf.confName +
    "\n";

  if (formattedDateData  && formattedOldDateData){
    userInviteText += Vue.prototype.$t("components.invitePreview.inviteChangeDateLine", [
      formattedOldDateData.format("DD.MM.YYYY"),
      formattedOldDateData.format("HH:mm") + " " + Vue.prototype.$t("components.meetingLineModal.hours"),
      formattedDateData.format("DD.MM.YYYY"),
      formattedDateData.format("HH:mm")   + " " + Vue.prototype.$t("components.meetingLineModal.hours")

    ]) + "\n";
  }

    userInviteText += "\n" + (conf.isUnlimited || conf.durationMin == 0
      ? ""
      : await getDurationPhrase(conf.durationMin)) +
      "\n";

    if (conf.confDescription && conf.confDescription.length){
      userInviteText += conf.confDescription + "." + "\n"+ "\n";
    }

    userInviteText += Vue.prototype.$t("components.invitePreview.inviteChangeLinkLineUser") +
    "\n" +
    "%INVITE_LINK%" +
    "\n" +
    "\n" ;

  if (asHtml) {
    userInviteText += "[custom imprint]" + "\n";
    userInviteText = userInviteText.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
  return userInviteText;
}

export async function getInviteTextGuestChange(tZone, conf, oldConfStartDate, oldConfStartTime, asHtml = false) {

  let userInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedOldDateData = getFormattedConfDates(timezone, oldConfStartDate, oldConfStartTime);
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    userInviteText = conf.personalizedGreeting + "\n";
  }
  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteChangeLine1", [conf.inviter]) +
    "\n" +
    conf.confName +
    "\n";

  if (formattedDateData && formattedOldDateData){
    userInviteText += Vue.prototype.$t("components.invitePreview.inviteChangeDateLine", [
      formattedOldDateData.format("DD.MM.YYYY"),
      formattedOldDateData.format("HH:mm")  + " " + Vue.prototype.$t("components.meetingLineModal.hours"),
      formattedDateData.format("DD.MM.YYYY"),
      formattedDateData.format("HH:mm")  + " " + Vue.prototype.$t("components.meetingLineModal.hours")

    ]) + "\n";
  }

  userInviteText += "\n" + (conf.isUnlimited || conf.durationMin == 0
    ? ""
    :"\n" + await getDurationPhrase(conf.durationMin)) +
    "\n";
    if (conf.confDescription && conf.confDescription.length){
      userInviteText += conf.confDescription + "." + "\n"+ "\n";
    }
    userInviteText += Vue.prototype.$t("components.invitePreview.inviteChangeLinkLineGuest") +
    "\n" +
    "%INVITE_LINK%" +
    "\n" +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestLinkDecline") +
    "\n" +
    "%DECLINE_LINK%" +
    "\n" +
    "\n";

  if (asHtml) {
    userInviteText += "[custom imprint]" + "\n";
    userInviteText = userInviteText.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
  return userInviteText;
}

export async function getInviteTextForUser(tZone, conf, asHtml = false) {
  let userInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    userInviteText = conf.personalizedGreeting + "\n";
  }
  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteAllLineInviter", [
      conf.inviter
    ]) +
    "\n" +
    conf.confName +
    "\n";
  if (formattedDateData) {
    userInviteText +=
      Vue.prototype.$t("components.invitePreview.inviteAllLineDateTime", [
        formattedDateData.format("DD.MM.YYYY"),
        formattedDateData.format("HH:mm")
      ]) +
      " " +
      Vue.prototype.$t("components.meetingLineModal.hours") +
      ` (${timezone})` + "." +

      (conf.isUnlimited || conf.durationMin == 0
        ? ""
        : "\n" + await getDurationPhrase(conf.durationMin)) +
      "\n" +
      "\n";
  }
  if (conf.confDescription && conf.confDescription.length){
    userInviteText += (conf.confDescription + ".").replace(/\.\.$/, ".") + "\n"+ "\n";
  }

  userInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteAllLineLink") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteAllLine2Link") +
    "\n" +
    "%INVITE_LINK%" +
    "\n" +
    "\n";

  userInviteText +=
    Vue.prototype.$t("components.invitePreview.youWillBeCalled") +
    "\n" +
    "\n";

  if (asHtml) {
    userInviteText += "[custom imprint]" + "\n";
    userInviteText = userInviteText.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
  return userInviteText;
}

function getDurationPhrase(num) {
let hours = (num / 60);
let rhours = Math.floor(hours);
let minutes = (hours - rhours) * 60;
let rminutes = Math.round(minutes);

  if (rhours === 1 && rminutes === 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHourMinute", [rhours,rminutes]) + ".";
  if (rhours === 1 && rminutes > 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [rhours,rminutes]) + ".";
  if (rhours > 1 && rminutes === 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [rhours,rminutes]) + ".";
  if (rhours > 1 && rminutes > 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHoursMinutes", [rhours,rminutes]) + ".";
  if (rhours === 0 && rminutes === 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationMinute", [rminutes]) + ".";
  if (rhours === 0 && rminutes > 1) return Vue.prototype.$t("components.invitePreview.inviteAllLineDuration", [rminutes]) + ".";
  if (rhours === 1 && rminutes === 0) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHour", [rhours]) + ".";
  if (rhours > 1 && rminutes === 0) return Vue.prototype.$t("components.invitePreview.inviteAllLineDurationHours", [rhours]) + ".";

}

export async function getInviteTextForGuest(tZone, conf, asHtml = false) {
  let guestInviteText = "";
  const timezone = tZone || "Europe/Berlin";
  const formattedDateData = getFormattedConfDates(timezone, conf.confStartDate, conf.confStartTime);
  if (
    conf.personalizedGreeting &&
    conf.personalizedGreeting.trim().length
  ) {
    guestInviteText = conf.personalizedGreeting + "\n";
  }
  guestInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteAllLineInviter", [
      conf.inviter
    ]) +
    "\n" +
    conf.confName +
    "\n";
  if (formattedDateData) {
    guestInviteText +=
      Vue.prototype.$t("components.invitePreview.inviteAllLineDateTime", [
        formattedDateData.format("DD.MM.YYYY"),
        formattedDateData.format("HH:mm")
      ]) +
      " " +
      Vue.prototype.$t("components.meetingLineModal.hours") +
      ` (${timezone})` + "." +
      (conf.isUnlimited
        ? ""
        : "\n" + await getDurationPhrase(conf.durationMin)) +
      "\n" +
      "\n";
  }
  if (conf.confDescription && conf.confDescription.length){
    guestInviteText += conf.confDescription + "." + "\n"+ "\n";
  }
  guestInviteText +=
    Vue.prototype.$t("components.invitePreview.inviteGuestRequirements") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestReqObjects") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestVerify") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestBrowsers") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestBrowserConf") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestRecomendation") +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestLinkPhrase") +
    "\n" +
    "%INVITE_LINK%" +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestLink2Phrase") +
    "\n" +
    "\n" +
    Vue.prototype.$t("components.invitePreview.inviteGuestLinkDecline") +
    "\n" +
    "%DECLINE_LINK%" +
    "\n" +
    "\n";

    if (asHtml) {
      guestInviteText += "[custom imprint]" + "\n";
      guestInviteText = guestInviteText.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }
  return guestInviteText;
}

//// HELPERS

function getFormattedConfDates(timezone, confStartDate, confStartTime) {
  if (!confStartDate || !confStartTime) return ''
  const confDate = new Date(
    confStartDate +
    "T" +
    confStartTime
      .split(":")
      .map(e => e.padStart(2, "0"))
      .join(":") +
    ":00" +
    getTimezone(confStartDate, confStartTime)
  );
  const confMoment = moment(confDate).tz(timezone);
  return confMoment;
}

<template>
  <v-row class="mx-0">
    <v-col :cols="selectedUser ? 8 : 12" class="py-0 px-0">
      <UserManagementTable :formIsChanged="formIsChanged" :changesInForm="changesInForm" :users="users" :selectedUser="selectedUser" :setUserInfo="setUserInfo" :addRecord="addRecord" />
    </v-col>
    <v-col v-if="selectedUser" :cols="4" class="profileWrapper py-0 px-0">
      <UserManagementDetail :formIsChanged="formIsChanged" :changesInForm="changesInForm" :selectedUser="selectedUser"  :closeDetails="closeDetails" :hideSaveButtons="hideSaveButtons"/>
    </v-col>
  </v-row>
</template>

<script>
import store from "../../store";
import UserManagementDetail from "./userManagementDetail.vue";
import UserManagementTable from "./userManagementTable.vue";
export default {
  props: ["users", "hideSaveButtons"],
  components: { UserManagementDetail, UserManagementTable },
  data() {
    return {
      state: store.state,
      selectedUser: undefined,
      formIsChanged: false
    };
  },
  mounted() {
  },
  watch: {},
  methods: {
    changesInForm(value){
      this.formIsChanged = value;
    },
    addRecord() {
     this.selectedUser = {
       name: '', 
       uuid: '', 
       assistants: [],
       avatar: 'img/default_profile_picture.png',
     }; 
    },
    setUserInfo(user) {
      if (this.selectedUser && this.selectedUser.uuid === user.uuid) {
        this.selectedUser = undefined
      } else {
        this.selectedUser = user;
      }
    },
    closeDetails(){
     this.selectedUser = undefined; 
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.profileWrapper {
    height: calc(100vh - 320px);
    overflow: auto;
}
</style>
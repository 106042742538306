import store from '../store';
import Vue from 'vue';
import { callUUID } from "../lib/rtcConn";
import { hasRepresentative } from "./representatives";
import { usedMobileRecently } from "./status"
import { wsCall } from "../lib/wsConnect";
import { sendNotificationToUUID } from "../lib/wsMsg";
import { messagesToMe, markAsViewedSpecificMessageEvent, unseenMessagesToMe } from "../effector/message";
import { bridgeInfoStore } from "../effector/users/bridgeInfo";
import { setFullBridgeCallModalEvent, setCanJoinConferenceEvent, /* setMobileInsideCallModalEvent, setMobileCallModalEvent, */ setCallUserInCallModalEvent, setCallPersonConferenceHallModalEvent } from "../effector/modals";
import { v4 as uuidv4 } from "uuid";
import { hasPrivilege } from "./privileges";
import { markAsCallWaitingRoomUserEvent } from "../effector/users/waitingInfo"

export async function callPerson(uuid, $router, isExternalCall = false) {
    if (uuid) {
        if (unseenMessagesToMe.getState()) {
            messagesToMe.getState()
                .filter((mssg) => mssg.users[store.state.ownUUID] && !mssg.users[store.state.ownUUID].viewed && mssg.creatorUUID === uuid)
                .map((mssg) => markAsViewedSpecificMessageEvent(mssg.messageUUID));
        }
        if (!personIsInBridgeCall(uuid)) {
            doCall(uuid, $router, isExternalCall)
        } else {
            if (personIsInBridgeCall(uuid)) {
                joinBridgeCall(uuid, $router)
            }
            // if person is a waitingroom user i add to effector called inside waitingInfo and decrease the badge os waitingroomusers #3024
            if (!hasPrivilege(uuid)) {
                markAsCallWaitingRoomUserEvent(uuid)
            }
        }
    }
}
export async function callPeople(uuids, $router) {
    for (let index = 0; index < uuids.length; index++) {
        const uuid = uuids[index];
        dbClickCall(uuid, $router)
    }
}
export async function dbClickCall(uuid, $router) {
    const representativeUuid = hasRepresentative(uuid);
    if (!amInABridgeCall()) {
        if (representativeUuid) {
            callDefaultminutes(representativeUuid, $router);
        } else {
            callDefaultminutes(uuid, $router);
        }
    } else {
        callPerson(uuid, $router);
    }
}
function callDefaultminutes(uuid, $router) {
    store.setdurationMeeting(store.state.namespaceSettings.defaultMeetingDuration.value)
    personCall(uuid, $router)
}
export function personIsInBridgeCall(uuid) {
    const person = store.state.group[uuid];
    if (!person) return false;
    if (
        person.connected &&
        person.user &&
        ((person.user.inBridgeCall &&
        person.user.inBridgeCall.length > 0)||
        (person.user.inBridgeCallListener &&
            person.user.inBridgeCallListener.length > 0))
    ) {
        return true
    }
}
export function personIsInConferenceHall(uuid) {
    const person = store.state.group[uuid];
    if (!person) return false;
    if (
        personIsInBridgeCall(uuid) && 
        person.user &&
        person.user.bridgeCallInfo &&
        person.user.bridgeCallInfo.conferenceHall
    ) {
        return true
    }
}
export function personIsInConference(uuid) {
    const person = store.state.group[uuid];
    if (!person) return false;
    if (
        personIsInBridgeCall(uuid) && 
        person.user &&
        person.user.bridgeCallInfo &&
        person.user.bridgeCallInfo.isConferenceCall &&
        !person.user.bridgeCallInfo.conferenceHall
    ) {
        return true
    }
}
function getBridgeCallUUidByPerson(uuid) {
    const person = store.state.group[uuid];
    if (
        person.connected &&
        person.user &&
        ((person.user.inBridgeCall &&
        person.user.inBridgeCall.length > 0) || (person.user.inBridgeCallListener &&  person.user.inBridgeCallListener.length > 0))
    ) {
        return person.user.inBridgeCall? person.user.inBridgeCall : person.user.inBridgeCallListener 
    }
}
function doCall(uuid, $router, isExternalCall) {
    if (amInABridgeCall()) {
        if(personIsInConferenceHall(store.state.ownUUID)){
            // New modal to decide if it is a podium or not.
            const callUUID = getBridgeCallUUidByPerson(store.state.ownUUID);
            if(callUUID && store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[callUUID] && store.state.remoteBridgeStreams[callUUID].initiator == store.state.ownUUID){
                setCallPersonConferenceHallModalEvent(uuid)
            }
        }else if (personIsInConference(store.state.ownUUID)){
            const callUUID = getBridgeCallUUidByPerson(store.state.ownUUID);
            const callObject = store.state.remoteBridgeStreams[callUUID]
            const userUUID = uuid;
            if(callObject?.conferenceAttendees.length < 20) {
                personBridgeCall(uuid, $router)
            } else if(callObject?.conferenceAttendees?.length == 20 && callObject?.conferenceAttendees.find(({ uuid }) => uuid === userUUID)){
                personBridgeCall(uuid, $router)
            }else{
                // show a info modal to inform the user that the conferene is already full
                const data = {
                    show: true,
                    header: `${Vue.prototype.$t("generics.info")}`,
                    body: `${Vue.prototype.$t("components.conferences.conferenceFull")}`,
                  };
                  store.setinfoModal(data);
            }
        } else {
            personBridgeCall(uuid, $router);
        }
        // if person is a waitingroom user i add to effector called inside waitingInfo and decrease the badge os waitingroomusers #3024
        if (!hasPrivilege(uuid)) {
            markAsCallWaitingRoomUserEvent(uuid)
        }
    } else {
        if (isExternalCall) {
            directBridgeCall(uuid, $router)
        } else {
            setShowModalMeetingTime(uuid);
        }
    }
}
function joinBridgeCall(uuid) {
    const callUUID = getBridgeCallUUidByPerson(uuid);
    const bridgeInfo = bridgeInfoStore.getState()
    const numberOfPeople = ((bridgeInfo.calls[callUUID] || {}).users || []).length
    if (numberOfPeople > 19) {
        setFullBridgeCallModalEvent(true)
        return;
    }
    const infoStartBridgeCall = {
        callUUID,
        calling: [],
    };
    if (store.state.group[uuid] && (store.state.group[uuid].user || {}).bridgeCallInfo && (store.state.group[uuid].user.bridgeCallInfo || {}).isConferenceCall) {
        //open modal to ask if we cant join the conference
        if (store.state.group[uuid].user.bridgeCallInfo.initiator && uuid !== store.state.group[uuid].user.bridgeCallInfo.initiator) {
            setCanJoinConferenceEvent({
                show: true,
                initiator: store.state.group[uuid]?.user?.bridgeCallInfo?.initiator,
                confId: store.state.group[uuid]?.user?.bridgeCallInfo?.confId
            })
        } else {
            wsCall("sendToUUID", uuid, {
                type: "joinConference",
                message: `${Vue.prototype.$t('components.callsContent.joinConference')}`,
                sender: store.state.ownUUID,
            });
        }

    } else {
        setCallUserInCallModalEvent({ showModal: true, uuid, infoStartBridgeCall, skipTotalIncrement: true });
    }
}
function personCall(uuid, $router) {
    setCurrentContentVisile("", false, $router);
    callUUID(uuid);
}
export function incomingConfCalls() {
    const confCalls = [];
    for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (call.isConference) {
            confCalls.push({ ...call, uuid });
        }
    }
    return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
    });
}
export function incomingCallsBridge() {
    const calls = [];
    for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (!call.isConference) {
            calls.push({ ...call, uuid });
        }
    }
    return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
    });
}
export function directBridgeCall(uuid, $router) {
    const id = uuidv4();
    const info = {
        callUUID: id,
        calling: [uuid],
        isNormalMode: true,
        initiator: store.state.ownUUID,
        callStartTs: Date.now(),
        callDurationMs: store.state.namespaceSettings.defaultMeetingDuration.value || 60000 * 20,
        infiniteCall: true,
        isAudioOnly: false,
    };
    const infoStartBridgeCall = {
        callUUID: id,
        isNormalMode: true,
        initiator: store.state.ownUUID,
        callStartTs: Date.now(),
        callDurationMs: store.state.namespaceSettings.defaultMeetingDuration.value || 60000 * 20,
        infiniteCall: true,
        isAudioOnly: false,
    };
    if (wsCallStartBridgeCall(uuid, store.state.ownUUID, infoStartBridgeCall)) {
        store.addRemoteBridgeStream(id, info);
        setCurrentContentVisile("", false, $router);
    }
}
export function personBridgeCall(uuid, $router) {
    const remoteBridgeStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
    );
    if (remoteBridgeStreamsKey.length > 0) {
        let callInfo = store.state.remoteBridgeStreams[
            remoteBridgeStreamsKey[0]
        ];
        const callUUID = callInfo.callUUID
        const bridgeInfo = bridgeInfoStore.getState()
        const numberOfPeople = ((bridgeInfo.calls[callUUID] || {}).users || []).length
        if (numberOfPeople > 19) {
            setFullBridgeCallModalEvent(true)
            return;
        }
        const infoStartBridgeCall = {
            callUUID: callInfo.callUUID,
            isNormalMode: callInfo.isNormalMode,
            initiator: callInfo.initiator || '',
            callStartTs: callInfo.callStartTs,
            callDurationMs: callInfo.callDurationMs,
            infiniteCall: callInfo.infiniteCall,
            conferenceDocuments: callInfo.conferenceDocuments || [],
            userConferencePosition: callInfo.userConferencePosition || {},
            excludedFromCalling: callInfo.usersToExclude || [],
            conferenceAttendees: callInfo.conferenceAttendees || [],
            confModerators: callInfo.confModerators || [],
            conferenceSettings: callInfo.conferenceSettings || {},
            usersToHideName: callInfo.usersToHideName || [],
            isConference: callInfo.isConference,
            confId: callInfo.confId || '',
            isConferenceCall: callInfo.isConferenceCall || undefined,
            conferenceHall: callInfo.conferenceHall || false
        }
        if (wsCallStartBridgeCall(uuid, store.state.ownUUID, infoStartBridgeCall, (numberOfPeople > 1))) {
            store.addBridgeCallingUser(uuid, callInfo.callUUID);
            setCurrentContentVisile("", false, $router);
        }
    }
}
export function amInABridgeCall() {
    if (store && store.state && store.state.remoteBridgeStreams) {
        return !!Object.keys(store.state.remoteBridgeStreams).length;
    } else {
        return false
    }
}
export function isConferenceCall(callUUID) {
    if (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[callUUID]) {
        return store.state.remoteBridgeStreams[callUUID].isConferenceCall;
    }
}
export function isConferenceHallCall(callUUID) {
    if (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[callUUID]) {
        return store.state.remoteBridgeStreams[callUUID].conferenceHall || false;
    }
}
function setCurrentContentVisile(...args) {
    return store.setCurrentContentVisile(...args);
}
function setShowModalMeetingTime(uuid) {
    store.setmeetingStartAt(undefined);
    store.setdurationMeeting(undefined);
    return store.setShowModalMeetingTime(uuid);
}
export function showOnCallData() {
    if (store.state.namespaceSettings.displayCurrentVideocallForUser) {
        return true
    } else if (store.state.namespaceSettings.displayCurrentVideocallForManagement) {
        if (store.getUserIsAdmin(store.state.ownUUID)) {
            return true
        } else {
            return false
        }
    }
    return false
}
export function userInCallText(person) {
    const personUuid = person.uuid || person.user?.uuid
    if (personIsInBridgeCall(personUuid)) {
        const callUUID = person.user.inBridgeCall || person.user.inBridgeCallListener;
        const bridgeInfo = bridgeInfoStore.getState()
        if (callUUID) {
            const bridgeLength = ((bridgeInfo.calls[callUUID] || {}).users || []).length
            const participantsArray = ((bridgeInfo.calls[callUUID] || {}).users || [])
            if (bridgeLength > 0) {
                if (showOnCallData()) {
                    const participants = participantsArray.filter(uuid => personUuid !== uuid).map((uuid) => {
                        return store.state.group[uuid]
                            ? (store.state.group[uuid].user || {}).name || "Anonymous"
                            : ""
                    })
                        .join(", ");
                    return participants;
                } else {
                    return `${bridgeLength} ${Vue.prototype.$t('components.callsContent.participants')}`;
                }
            } else {
                return ' '
            }
        } else {
            return ' '
        }
    }
    return false;
}
export function userInCall(uuid) {
    // function to check if a user is in a call (both kind of calls)
    const person = store.state.group[uuid];
    if (person && person.connected && person.user && (person.user.inBridgeCall || person.user.inBridgeCallListener)) {
        return true;
    }
    return false;
}
export function disableCallBtn(receivePerson) {
    let person = receivePerson;
    if (!person || !person.user) return;
    const callUUID = getBridgeCallUUidByPerson(store.state.ownUUID);
    const representativeUuid = hasRepresentative(person.uuid)
    if (representativeUuid && representativeUuid !== store.state.ownUUID) {
        //everybody can call to the representative and the representative can call the user
        person = store.state.group[representativeUuid];
    } else {
        person = receivePerson;
    }
    const result = (
        !!(
            !person ||
            !(person.connected || usedMobileRecently(person)) ||
            (!!personIsInBridgeCall(store.state.ownUUID) && !!personIsInBridgeCall(person.uuid || person.user.uuid)) ||
            personIsInConferenceHall(person.uuid || person.user.uuid) || 
            (callUUID &&personIsInConferenceHall(store.state.ownUUID) && store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[callUUID] && store.state.remoteBridgeStreams[callUUID].initiator !== store.state.ownUUID)
        ) || person.user.activity === "Out of Office" || person.user.activity === 'No Calls'  || person.user.activity === 'No status' || store.state.isCallFull
    );
    return result;
}
export function wsCallStartBridgeCall(user, sender, info, skipTotalIncrement = false, skipNotification = false) {
    /*
    const bridgeInfo = bridgeInfoStore.getState();
    const bridgeCall = bridgeInfo.calls[info.callUUID];
    if (bridgeCall && bridgeCall.users && bridgeCall.users.length) {
        const bridgeLength = bridgeCall.users.length;
        const isMobile = false; // bridgeCall.isMobile || ((store.state.group[user] || {}).user || {}).isMobile;
        if (isMobile && bridgeLength > 1) {
            if (bridgeCall.users.indexOf(sender) !== -1) {
                setMobileInsideCallModalEvent(true);
            } else {
                setMobileCallModalEvent(true);
            }
            return false;
        }
    }
    */
    wsCall("sendToUUID", user, {
        type: "bridge-signal",
        action: "start_bridge_call",
        sender: sender,
        info: info
    });
    if (!skipNotification) {
        sendNotificationToUUID(user, { type: 'call', skipTotalIncrement });
    }
    store.appendOutgoingCallHistory(user, info.callUUID, Date.now());
    return true;
}
import { render, staticRenderFns } from "./callIcon.vue?vue&type=template&id=43b6c96e&scoped=true&"
import script from "./callIcon.vue?vue&type=script&lang=js&"
export * from "./callIcon.vue?vue&type=script&lang=js&"
import style0 from "./callIcon.vue?vue&type=style&index=0&id=43b6c96e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43b6c96e",
  null
  
)

export default component.exports
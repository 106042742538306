<template>
  <div>
    <v-row class="mx-0" align="center">
      <v-col class="col-12">
        <div>
          {{ $t('components.companyRegister.emailConfirmation') }}
        </div>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="verifyCode"
          :label="$t('components.verifyEmailRegister.sixDigits')"
          required
          outlined
          dense
          :rules="verifyCodeRules"
          :disabled="verifyCodeLoading || verifyCodeSuccess"
          counter
          maxlength="6"
          @change="(verifyCode || '').length === 6 && !(verifyCodeLoading || verifyCodeSuccess) && checkCodeRules()"
          @keypress="onCodeInput"
          @keyup="checkCodeRules"
          clearable
        >
          <template slot="append">
            <span
              class="custom-loader-verify-code mt-1"
              v-if="verifyCodeLoading && verifyCode.length > 0"
            >
              <font-awesome-icon
                :icon="['fal', 'spinner-third']"
                :style="{ fontSize: '15px' }"
              />
            </span>
            <span class="mt-1" v-if="verifyCodeSuccess">
              <font-awesome-icon
                :icon="['fal', 'check']"
                :style="{ fontSize: '15px' }"
                color="green"
              />
            </span>
            <!-- <span class="mt-1" v-if="verifyCodeError">
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '15px' }"
                color="red"
              />
            </span> 
            commented, because without funktionality
            -->
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="right">
      <v-col cols="12" class="py-0 text-right">
        <v-btn
          depressed
          class="mr-4"
          color="primary"
          @click="endSetup()"
          :disabled="!hasVerifiedEmail"
        >
          {{
            isGermanLawyer || isPackagedVersion
              ? $t('generics.startNow')
              : $t('components.companyRegister.buttonFreeTrial')
          }}
        </v-btn>

        <v-btn
          depressed
          v-if="!(isGermanLawyer || isPackagedVersion)"
          color="primary"
          @click="showPayPalScreen()"
          :disabled="!hasVerifiedEmail"
        >
          {{ $t('components.companyRegister.buttonPayPal') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { setNamespaceSetting } from '../../lib/wsMsg'
  import store, { syncedUserState } from '../../store'

  export default {
    components: {},
    data() {
      return {
        companyRegisterObject: store.state.namespaceSettings.companyInfo,
        ownUUID: store.state.ownUUID,
        setSetUpConfigRoute: store.setSetUpConfigRoute,
        setShowSetup: store.setShowSetup,
        state: store.state,
        loading: true,
        verifyCode: '',
        verifyCodeLoading: false,
        verifyCodeSuccess: false,
        verifyCodeError: false,
      }
    },
    watch: {
      hasVerifiedEmail: {
        handler(oldValue, newValue) {
          if (!oldValue && newValue) {
            this.verifyCodeSuccess = true
            this.verifyCodeError = false
          }
        },
        immediate: true,
      },
    },
    computed: {
      getEmailRegistered() {
        return store.state.namespaceSettings.companyInfo.email
      },
      hasVerifiedEmail() {
        return (store.state.group[this.ownUUID] || {}).emailVerified
      },
      isGermanLawyer() {
        if (
          this.companyRegisterObject.countrySelected &&
          this.companyRegisterObject.countrySelected.value === 'DE' &&
          this.companyRegisterObject.branchSelected &&
          (this.companyRegisterObject.branchSelected === 'lawyers' ||
            this.companyRegisterObject.branchSelected === 'tax-consultant' ||
            this.companyRegisterObject.branchSelected === 'court' ||
            this.companyRegisterObject.branchSelected === 'notary')
        ) {
          return true
        } else {
          return false
        }
      },
      isPackagedVersion() {
        return !!process.env.PKG
      },
      secret() {
        const uuid = this.ownUUID
        const localSecretKey = 'ownSecret_' + uuid
        return localStorage.getItem(localSecretKey)
      },
      verifyCodeRules() {
        if (!this.verifyCodeLoading) {
          return [
            (v) => !!v || this.$t('components.verifyEmailRegister.required'),
            !this.verifyCodeError ||
              this.$t('components.verifyEmailRegister.invalid-number'),
          ]
        }
      },
    },
    methods: {
      checkCodeRules() {
        if (this.verifyCodeSuccess) {
          this.verifyCodeSuccess = false
        }
        if (this.verifyCodeError) {
          this.verifyCodeError = false
        }
        if (this.verifyCode.length == 6) {
          this.verifyEmail()
        }
      },
      endSetup() {
        setNamespaceSetting('setupDone', true)
        this.setShowSetup(false)
      },
      onCodeInput(evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      },
      showPayPalScreen() {
        this.setSetUpConfigRoute('paypal')
      },
      async verifyEmail() {
        if (this.verifyCodeLoading) return;
        this.verifyCodeLoading = true;
        const result = await fetch("/api/verifyEmail/verify", {
          method: "POST",
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-secret': this.secret,
          },
          body: JSON.stringify({
            uuid: this.ownUUID,
            code: this.verifyCode,
          }),
        }).then((res) => res.json())
        // clean loading when we have the answer
        this.verifyCodeLoading = false
        // set the control variables
        if (result.success) {
          this.verifyCodeSuccess = true
        } else {
          this.verifyCodeError = true
        }
      },
    },
    async created() {
      this.loading = true
      // update email and wait for backend update
      await syncedUserState(
        () => (store.state.user.email = this.getEmailRegistered)
      )
      // send verification
      const result = await fetch('/api/verifyEmail/send', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-secret': this.secret,
        },
        body: JSON.stringify({
          uuid: this.ownUUID,
        }),
      }).then((res) => res.json())
      if (!result.success) {
        console.warn('verifyEmail send Err:', result)
      }
      this.loading = false
    },
    mounted() {},
  }
</script>
<style scoped lang="scss">
  .paymentIcon {
    width: 30px;
    vertical-align: middle;
  }
  .custom-loader-verify-code {
    animation: loader 1s infinite;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>

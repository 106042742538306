<template>
  <v-dialog
    persistent
    v-model="showModal"
    :max-width="getModalClass"
    v-if="state.userOnModal"
    @keydown.esc="closeUserProfileAndCamera"
  >
    <!-- :content-class="getModalClass" -->
    <v-card>
      <HeaderModal
        :titleModal="
          state.userOnModal.uuid == state.ownUUID
            ? $t('components.userProfile.myUserInfo')
            : amIAdmin
              ? $t('components.userProfile.userInfo') /* sentence with edit photo */
              : $t('components.adminSettingsModal.userInfo') /* user info */
        "
        :closeModalFunction="closeUserProfileAndCamera"
      />
      <camera
        v-if="this.showCam"
        :getSnapshot="getSnapshot"
        :closeCamera="closeCamera"
      />
      <template v-if="showCroppie">
        <UploadAvatarFunctionality
          :temporalAvatar="temporalAvatarUrl"
          :person="state.userOnModal"
          :showCroppie="showCroppie"
          :closeCroppie="openCloseCroppie"
          :resetInputAvatar="resetInputAvatar"
        />
      </template>
      <v-row
        flex
        class="pt-2 pb-4"
        :style="{ width: '100%', margin: 0 }"
        v-if="!showCroppie && !showCam"
      >
        <input
          type="file"
          ref="fileInput"
          class="d-none"
          accept="image/x-png,image/gif,image/jpeg"
          @change="showCroppieModal($event)"
        />
        <v-col
          v-for="n in 2"
          :key="n"
          :lg="cols[n - 1]"
          :md="cols[n - 1]"
          :sm="cols[n - 1]"
          :xl="cols[n - 1]"
        >
          <div v-if="n == 1" align="center">
            <div>
              <v-list-item-avatar
                tile
                size="190"
                class="videoSquareProfil borderRadius4 mx-0 my-0"
              >
                <div style="overflow: hidden" class="borderRadius4">
                  <template v-if="!showCameraPreview">
                    <DefaultAvatar
                      :size="190"
                      :userData="state.userOnModal"
                      v-if="
                        !showCam &&
                        state.userOnModal &&
                        getAvatarForUuid(state.userOnModal.uuid) ===
                          'img/default_profile_picture.png'
                      "
                    ></DefaultAvatar>
                    <v-img
                      class="borderRadius4"
                      max-height="190"
                      max-width="190"
                      :contain="getPictureSize"
                      :src="getAvatarForUuid(state.userOnModal.uuid)"
                      v-if="
                        !showCam &&
                        state.userOnModal &&
                        getAvatarForUuid(state.userOnModal.uuid) !==
                          'img/default_profile_picture.png'
                      "
                      lazy-src="assets/default_profile_picture.png"
                    ></v-img>
                  </template>
                  <template v-if="showCameraPreview">
                    <CameraPreview
                      :width="190"
                      :closeCamera="closeCameraPreview"
                    ></CameraPreview>
                  </template>
                </div>
                <!--Buttons hover image -->
                <div
                  flex
                  class="uploadAvatarButtons p-4 mx-auto"
                  v-if="
                    (state.ownModalUser || amIAdmin) &&
                    !showCam &&
                    !showCameraPreview
                  "
                  :class="{
                    hasImage:
                      state.user.avatar != 'img/default_profile_picture.png',
                    hasNoImage:
                      state.user.avatar == 'img/default_profile_picture.png',
                  }"
                >
                  <v-tooltip
                    top
                    v-if="
                      getAvatarForUuid(state.userOnModal.uuid) !==
                      'img/default_profile_picture.png'
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        v-on="on"
                        color="primary"
                        isSmall="true"
                        v-on:click="removeAvatar()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'trash']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.downloadsDropdown.delete") }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="
                      getAvatarForUuid(state.userOnModal.uuid) !==
                      'img/default_profile_picture.png'
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        v-on="on"
                        color="primary"
                        isSmall="true"
                        v-on:click="editAvatar()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'pen']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.navbar.editPicture") }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        v-on="on"
                        color="primary"
                        isSmall="true"
                        v-on:click="selectAvatar()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'upload']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.navbar.uploadPicture") }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        v-on="on"
                        color="primary"
                        isSmall="true"
                        v-on:click="showCamFunc()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'camera']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.camera.takeSnapshot") }}</span>
                  </v-tooltip>
                </div>
              </v-list-item-avatar>
            </div>
          </div>
          <div v-if="n == 2" class="pt-2">
            <p
              color="primary"
              class="userNameCard mt-1 mb-2 font-weight-medium"
            >
              {{ state.userOnModal.name }}
            </p>
            <p class="dataUserCard my-1" v-if="state.userOnModal.position">
              {{ state.userOnModal.position }}
            </p>
            <p
              class="dataUserCard my-1"
              v-if="
                !state.userOnModal.visitorData &&
                getLocationTranslated(state.userOnModal)
              "
            >
              {{ getLocationTranslated(state.userOnModal) }}
            </p>
            <p class="dataUserCard my-1" v-if="amIPrivileges && state.userOnModal.email">
              {{ state.userOnModal.email }}
            </p>
            <p class="dataUserCard my-1" v-if="amIPrivileges && state.userOnModal.phone">
              Tel.: {{ state.userOnModal.phone }}
            </p>
          </div>
        </v-col>
        <v-col class="px-8 pb-0 pt-4 additionalInfoClass" v-if="(getOrgaInfoDeps || getOrgaInfoTeams) || showAdditionalInfo && !showCroppie">
          <div v-if="getOrgaInfoDeps">
              <p class="dataUserCard my-1 fontWeight500">
                {{ $t('components.organisationCarousel.departments') }}:
              </p>
              <p class="dataUserCard my-1">
                {{getOrgaInfoDeps}}
              </p>
            </div>
            <div v-if="getOrgaInfoTeams">
              <p class="dataUserCard my-1 fontWeight500">
                {{ $t('components.organisationCarousel.teams') }}:
              </p>
              <p class="dataUserCard my-1">
                {{getOrgaInfoTeams}}
              </p>
            </div>
            <template v-if="showAdditionalInfo">
              <div v-if="state.userOnModal.userSettings.additionalStartDate">
                <p class="dataUserCard my-1 fontWeight500">
                  {{ $t('components.userSettings.startDate') }}:
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="editButtons"
                      v-if="state.userOnModal.uuid == state.ownUUID"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'pen']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="state.userOnModal.userSettings.additionalStartDate"
                    no-title
                    scrollable
                  ></v-date-picker>
                  </v-menu>
                </p>
                <p class="dataUserCard my-1">
                  {{ moment(state.userOnModal.userSettings.additionalStartDate).format('DD-MM-YYYY') }}
                </p>
              </div>
              <div v-if="state.userOnModal.userSettings.educationBackground">
                <p class="dataUserCard my-0 fontWeight500">
                  {{ $t('components.userSettings.educationBackground') }}:
                  <v-btn icon v-if="!editEducation && state.userOnModal.uuid == state.ownUUID" @click="changeEditEducation()" class="editButtons">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '15px' }"
                    />
                  </v-btn>
                  <template v-if="editEducation">
                    <v-btn icon @click="saveAdditionalUserInfo('educationBackground_info')" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                    <v-btn icon @click="changeEditEducation()" :disabled="educationBackground_info.trim() === ''" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                </p>
                <v-text-field
                  hide-details
                  v-if="editEducation"
                  dense
                  flat
                  v-model="educationBackground_info"
                >
                </v-text-field>
                <p class="dataUserCard my-1"  v-if="!editAdditionalKnowledge">
                  {{ educationBackground_info }}
                </p>
              </div>
              <div v-if="state.userOnModal.userSettings.additionalKnowledge">
                <p class="dataUserCard my-1 fontWeight500">
                  {{ $t('components.userSettings.additionalKnowledge') }}:
                  <v-btn icon v-if="!editAdditionalKnowledge && state.userOnModal.uuid == state.ownUUID" @click="changeAdditionalKnowledge()" class="editButtons">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '15px' }"
                    />
                  </v-btn>
                  <template v-if="editAdditionalKnowledge">
                    <v-btn icon @click="saveAdditionalUserInfo('additionalKnowledge_info')" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                    <v-btn icon @click="changeAdditionalKnowledge()" :disabled="additionalKnowledge_info.trim() === ''" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                </p>
                <v-text-field
                  hide-details
                  v-if="editAdditionalKnowledge"
                  dense
                  flat
                  v-model="additionalKnowledge_info"
                >
                </v-text-field>
                <p class="dataUserCard my-1" v-if="!editAdditionalKnowledge">
                  {{ additionalKnowledge_info }}
                </p>
              </div>
              <div v-if="state.userOnModal.userSettings.additionalBriefDesc">
                <p class="dataUserCard my-1 fontWeight500">
                  {{ $t('components.userSettings.briefDesc') }}: 
                  <v-btn icon v-if="!editAdditionalBriefDesc && state.userOnModal.uuid == state.ownUUID" @click="changeAdditionalBriefDesc()" class="editButtons">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '15px' }"
                    />
                  </v-btn>
                  <template v-if="editAdditionalBriefDesc">
                    <v-btn icon @click="saveAdditionalUserInfo('additionalBriefDesc_info')" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                    <v-btn icon @click="changeAdditionalBriefDesc()" :disabled="additionalBriefDesc_info.trim() === ''" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                </p>
                <v-text-field
                  hide-details
                  v-if="editAdditionalBriefDesc"
                  dense
                  flat
                  v-model="additionalBriefDesc_info"
                >
                </v-text-field>
                <p class="dataUserCard my-1" v-if="!editAdditionalBriefDesc">
                  {{ additionalBriefDesc_info }}
                </p>
              </div>
              <div v-if="state.userOnModal.userSettings.additionalWorkExp">
                <p class="dataUserCard my-1 fontWeight500">
                  {{ $t('components.userSettings.workExp') }}: 
                  <v-btn icon v-if="!editAdditionalWorkExp && state.userOnModal.uuid == state.ownUUID" @click="changeAdditionalWorkExp()" class="editButtons">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '15px' }"
                    />
                  </v-btn>
                  <template v-if="editAdditionalWorkExp">
                    <v-btn icon @click="saveAdditionalUserInfo('additionalWorkExp_info')" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                    <v-btn icon @click="changeAdditionalWorkExp()" :disabled="additionalWorkExp_info.trim() === ''" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                </p>
                <v-text-field
                  hide-details
                  v-if="editAdditionalWorkExp"
                  dense
                  flat
                  v-model="additionalWorkExp_info"
                >
                </v-text-field>
                <p class="dataUserCard my-1" v-if="!editAdditionalWorkExp">
                  {{ additionalWorkExp_info }}
                </p>
              </div>
              <div v-if="state.userOnModal.userSettings.additionalInterests">
                <p class="dataUserCard my-1 fontWeight500">
                  {{ $t('components.userSettings.interests') }}: 
                  <v-btn icon v-if="!editAdditionalInterests && state.userOnModal.uuid == state.ownUUID" @click="changeaAdditionalInterests()" class="editButtons">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '15px' }"
                    />
                  </v-btn>
                  <template v-if="editAdditionalInterests">
                    <v-btn icon @click="saveAdditionalUserInfo('additionalInterests_info')" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'save']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                    <v-btn icon @click="changeaAdditionalInterests()" :disabled="additionalInterests_info.trim() === ''" class="editButtons">
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '15px' }"
                      />
                    </v-btn>
                  </template>
                </p>
                <v-text-field
                  hide-details
                  v-if="editAdditionalInterests"
                  dense
                  flat
                  v-model="additionalInterests_info"
                >
                </v-text-field>
                <p class="dataUserCard my-1" v-if="!editAdditionalInterests">
                  {{ additionalInterests_info }}
                </p>
              </div>
            </template>
        </v-col>
      </v-row>
      <template v-if="!showCroppie && !showCam">
        <FooterModal
          :closeModalFunction="closeUserProfileAndCamera"
          :showFooter="!showCroppie && !showCam"
        >
          <div class="px-0 py-0" v-if="!state.ownModalUser">
            <v-tooltip top v-if="!isInCallWithUs">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  tile
                  color="primary"
                  v-on:click="callUser(state.userOnModal.uuid)"
                  v-bind:disabled="disableCallBtn"
                  :class="`ml-2 buttonIconPrimaryFooter ${
                    disableCallBtn ? 'buttonIconPrimaryFooterDisable' : ''
                  }`"
                  :style="` ${
                    disableCallBtn ? 'color: #11889d!important;' : ''
                  }`"
                >
                  <font-awesome-icon
                    :icon="['fal', 'phone']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.userProfile.callUser") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isInCallWithUs">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  class="ml-2 buttonIconPrimaryFooter"
                  icon
                  tile
                  @click="
                    getWebrtcConnections()[state.userOnModal.uuid].die(true);
                    closeUserProfileAndCamera();
                  "
                >
                  <font-awesome-icon
                    :icon="['fal', 'phone']"
                    :rotation="270"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.hangUp") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  tile
                  v-on="on"
                  color="primary"
                  @click="setShowModalSenMsg(state.userOnModal.uuid)"
                  class="ml-2 buttonIconPrimaryFooter"
                >
                  <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.userProfile.sendAMessage") }}</span>
            </v-tooltip>
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2 buttonIconPrimaryFooter"
                icon
                tile
                v-on="on"
                v-if="state.ownModalUser"
                color="primary"
                @click="cameraPreview(!showCameraPreview)"
              >
                <font-awesome-icon
                  :icon="['fal', 'video']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.userProfile.videoTest") }}</span>
          </v-tooltip>
        </FooterModal>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { dispatchSuccessAlert } from "../../effector/alerts";
import { getWebrtcConnections } from "../../lib/rtcConn";
import { setRemoteUser } from "../../lib/wsMsg"
import camera from "../camera/camera.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import CameraPreview from "../camera/cameraPreview.vue";
import { hasPrivilege } from "../../utils/privileges";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { locationTranslated, getPictureSize } from "../../utils/basicFunctions";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import UploadAvatarFunctionality from "../content/uploadAvatarFunctionality.vue";
import moment from "../../../sharedsrc/moment";
import {
  resetUserProfileEvent,
  userProfileStore,
  setHistoryMessagesModalEvent,
} from "../../effector/modals";

export default {
  components: {
    camera,
    DefaultAvatar,
    CameraPreview,
    HeaderModal,
    FooterModal,
    UploadAvatarFunctionality,
  },
  data: () => ({
    state: store.state,
    showCam: false,
    showCroppie: false,
    ownUUID: store.state.ownUUID,
    showCameraPreview: false,
    temporalAvatarUrl: "",
    moment: moment,
    editEducation: false,
    editAdditionalKnowledge: false,
    editAdditionalBriefDesc: false,
    editAdditionalWorkExp: false,
    editAdditionalInterests: false,
    startDateMenu: false,
    educationBackground_info: store.state.userOnModal?.userSettings?.educationBackground || "",
    additionalKnowledge_info: store.state.userOnModal?.userSettings?.additionalKnowledge || "",
    additionalBriefDesc_info: store.state.userOnModal?.userSettings?.additionalBriefDesc || "",
    additionalWorkExp_info: store.state.userOnModal?.userSettings?.additionalWorkExp || "",
    additionalInterests_info: store.state.userOnModal?.userSettings?.additionalInterests || "",
  }),
  beforeDestroy() {
    this.cleanStore();
  },
  effector: {
    showModal: userProfileStore,
  },
  methods: {
    saveAdditionalUserInfo(variableToUpdate){
      const updateData = variableToUpdate.split('_')[0];
      const temporalUserSettings = this.state.user.userSettings;
      temporalUserSettings[updateData] =  this[variableToUpdate];
      // temporalUserSettings.additionalStartDate = this.additionalStartDate;
      dispatchSuccessAlert(
        this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
      );
      switch (updateData) {
        case 'educationBackground':
          this.changeEditEducation();
          break;
        case 'additionalKnowledge':
          this.changeAdditionalKnowledge();
          break;
        case 'additionalBriefDesc':
          this.changeAdditionalBriefDesc();
          break;
        case 'additionalWorkExp':
          this.changeAdditionalWorkExp();
          break;
        case 'additionalInterests':
          this.changeaAdditionalInterests();
          break;          
        default:
      }
      return store.setUserSettings(temporalUserSettings);
    },
    changeEditEducation(){
      this.editEducation = !this.editEducation;
    },
    changeAdditionalKnowledge() {
      this.editAdditionalKnowledge = !this.editAdditionalKnowledge;
    },
    changeAdditionalBriefDesc() {
      this.editAdditionalBriefDesc = !this.editAdditionalBriefDesc;
    },
    changeAdditionalWorkExp() {
      this.editAdditionalWorkExp = !this.editAdditionalWorkExp;
    },
    changeaAdditionalInterests() {
      this.editAdditionalInterests = !this.editAdditionalInterests;
    },
    closeModal() {
      resetUserProfileEvent();
    },
    getLocationTranslated(person) {
      return locationTranslated(person);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    cleanStore() {
      this.state.ownModalUser = false;
      this.state.userOnModal = undefined;
    },
    callUser(uuid) {
      const uuidToCall = uuid;
      this.closeModal();
      this.cleanStore();
      callPerson(uuidToCall, this.$router);
    },
    closeUserProfileAndCamera() {
      this.closeModal();
      this.cleanStore();
      this.showCameraPreview = false;
      this.showCroppie = false;
    },
    cameraPreview(value) {
      this.showCameraPreview = value;
    },
    closeCameraPreview() {
      this.showCameraPreview = false;
    },
    removeAvatar() {
      if (this.state.userOnModal.uuid == this.state.ownUUID) {
        this.$set(this.state.user, "avatar", "img/default_profile_picture.png");
        this.$set(this.state.user, "avatarRectangle", "");
      } else {
        setRemoteUser(this.state.userOnModal.uuid, {
          avatar: "img/default_profile_picture.png",
          avatarRectangle: "",
        });
      }
    },
    editAvatar() {
      const image = this.getAvatarForUuid(this.state.userOnModal.uuid);
      this.showCroppie = true;
      this.temporalAvatarUrl = image;
    },
    // start methods for camera
    showCamFunc() {
      this.showCam = true;
    },
    getSnapshot(image) {
      this.showCam = false;
      this.showCroppie = true;
      this.temporalAvatarUrl = image;
    },
    closeCamera() {
      this.showCam = false;
    },
    openCloseCroppie(value = undefined) {
      this.showCroppie = value ? value : !this.showCroppie;
    },
    result(output) {
      this.cropped = output;
    },
    selectAvatar() {
      this.$refs.fileInput.click();
    },
    resetInputAvatar() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    setShowModalSenMsg(uuid) {
      this.closeUserProfileAndCamera();
      setHistoryMessagesModalEvent(uuid);
    },
    showCroppieModal(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.temporalAvatarUrl = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
      this.resetInputAvatar();
    },
  },
  computed: {
    processedUser() {
      return store.getProcessedUserList()[this.state.userOnModal.uuid] || {};
    },
    getOrgaInfoDeps() {
      const deps = this.processedUser.teamDepartments;
      if(deps) return deps.join(', ');
      return false;
    },
    getOrgaInfoTeams() {
      const teams = this.processedUser.memberOfTeams;
      if(teams) return teams.join(', ');
      return false;
    },
    getModalClass() {
      if (!this.showAdditionalInfo || this.showCroppie) {
        return '570'
        // return 'normalModal'
      } else if (this.showAdditionalInfo && !this.showCroppie) {
        return '1000'
        // return 'extendedModal'
      }
    },
    showAdditionalInfo(){
      if (
        this.state.userOnModal.userSettings?.educationBackground ||
        this.state.userOnModal.userSettings?.additionalKnowledge ||
        this.state.userOnModal.userSettings?.additionalBriefDesc ||
        this.state.userOnModal.userSettings?.additionalWorkExp   ||
        this.state.userOnModal.userSettings?.additionalInterests ||
        this.state.userOnModal.userSettings?.additionalStartDate
      ) {
        return true;
      }
      return false;
    },
    getPictureSize() {
      return getPictureSize();
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    amIPrivileges() {
      return hasPrivilege(this.ownUUID);
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg ? [5, 7] : sm ? [5, 7] : md ? [5, 7] : xl ? [5, 7] : [12, 12];
    },
    isInCallWithUs() {
      return !!this.state.rtc[store.state.userOnModal.uuid];
    },
    disableCallBtn() {
      let person = store.state.group[this.state.userOnModal.uuid];
      person.uuid = this.state.userOnModal.uuid;
      return disableCallBtn(person);
    },
  },
};
</script>

<style scoped lang="scss">
.editButtons {
  width: 20px !important;
  height: 20px !important;
}
.dataUserCard {
  min-height: 25px;
}
.additionalInfoClass {
  height: auto;
  max-height: calc(100vh - 415px);
  overflow-y: auto;
}
.additionalInfoClass::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  box-shadow: none;
}

/* Track */
.additionalInfoClass::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000000;
  border-radius: 10px;
  box-shadow: none;
}
/* Handle */
.additionalInfoClass::-webkit-scrollbar-thumb {
  background: #06a5df;
  border-radius: 10px;
  box-shadow: none;
}
.normalModal {
  width: 570px !important;
}
.extendedModal {
    max-width: 1000px !important;
    min-width: 570px !important;
    width: auto !important;
}
.fontWeight500 {
  font-weight: 500;
}
video {
  height: 257 !important;
  width: 257 !important;
  min-width: 257;
  background: #101010;
}
.userNameCard {
  font-size: 19px;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 190px;
  width: 190px;
  top: 0px;
  border-radius: 4px;
  text-align: center;
  padding-top: 90%;
  button {
    height: 33px !important;
    min-width: 40px !important;
    padding: 0 10px !important;
  }
  &.hasImage {
    background: #6666668f;
    display: none;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: none;
    &:hover {
      display: block;
    }
  }
}
.borderRadius4 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
.borderRadius4 {
  border-radius: 4px;
}
.videoSquareProfil {
  overflow: visible !important;
}
</style>

<style>
.videoSquareProfil video {
  height: 190px;
  width: 253px;
  min-width: 253px;
  background: #101010;
  border-radius: 4px;
}
</style>
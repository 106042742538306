<template>
  <v-row>
    <v-row class="mx-0">
      <v-col cols="12" class="pb-0">
        <v-checkbox
          v-model="companyRegisterObject.isRamicroCustomer"
          dense
          class="mt-0 pt-1"
        >
          <template v-slot:label
            ><span
              v-html="$t('components.companyRegister.isRamicroCustomer')"
            ></span
          ></template>
        </v-checkbox>
      </v-col>

      <!-- <v-col cols="12" class="py-0">
      <v-autocomplete
        v-model="companyRegisterObject.salesPartnerSelected"
        :items="salesPartnersArray"
        :filter="customFilter"
        :label="$t('components.companyRegister.salesPartner')"
        autocomplete="very_false"
        :return-object="true"
        @change="setSalesPartner"
        outlined
      dense
      ></v-autocomplete>
    </v-col> -->
    </v-row>
    <!-- ra-micro customer -->
    <!-- <v-row class="mx-0" v-if="companyRegisterObject.isRamicroCustomer">
    <v-col cols="12" class="py-0">
      <v-text-field
          v-model="companyRegisterObject.ramicroCustomerNumber"
          :label="$t('components.companyRegister.ramicroCustomerNumber')"
          :rules="ramicroCustomerNumberRules"
          outlined
          dense
          required
          >
        </v-text-field>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.zipCode"
        :label="$t('components.companyRegister.zipCode')"
        :rules="zipCodeRules"
        required
        outlined
        dense
        >
      </v-text-field>
    </v-col>
  </v-row> -->
    <!-- end ra-micro customer -->
    <!-- german lawyer but not ra-micro customer -->
    <!-- <v-row class="mx-0"> -->
    <!-- <v-col cols="12" class="py-0">
      <v-autocomplete
          v-model="companyRegisterObject.salutationSelected"
          :items="salutationsArray"
          :filter="customFilter"
          :label="$t('components.companyRegister.salutation')"
          autocomplete="very_false"
          required
          :return-object="true"
          @change="setSalutation"
          :rules="salutationRules"
          outlined
          dense
      ></v-autocomplete>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.firstName"
        :label="$t('components.companyRegister.firstName')"
        :rules="contactNameRules"
        required
        outlined
        dense
        >
      </v-text-field>
    </v-col>
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.lastName"
        :label="$t('components.companyRegister.lastName')"
        :rules="contactNameRules"
        required
        outlined
        dense
        >
      </v-text-field>
    </v-col>
    
    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.lawOffice"
        :label="$t('components.companyRegister.lawOffice')"
        :rules="lawOfficeRules"
        required
        outlined
        dense
      >
      </v-text-field>
    </v-col>

    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.streetHouse"
        :label="$t('components.companyRegister.streetHouse')"
        :rules="streetHouseRules"
        required
        outlined
        dense
        >
      </v-text-field>
    </v-col>

    <v-col cols="6" class="py-0">
      <v-text-field
          v-model="companyRegisterObject.zipCode"
          :label="$t('components.companyRegister.zipCode')"
          :rules="zipCodeRules"
          required
          outlined
          dense
          >
        </v-text-field>
    </v-col>

    <v-col cols="6" class="py-0">
       <v-text-field
          v-model="companyRegisterObject.city"
          :label="$t('components.companyRegister.city')"
          :rules="cityRules"
          required
          outlined
          dense
          >
        </v-text-field>
    </v-col>

    <v-col cols="6" class="py-0 pRelative">
       <div>
        <p class="pContact px-1">{{ $t('components.companyRegister.contactNumber') }}</p>
          <vue-tel-input required enabledCountryCode @onInput="onTelInput" name="number" id="numDisplay" class="form-control text-center input-sm" value="" placeholder="Example: 612 34 56 78" autocomplete="off" :class="{ 'borderError': !phoneValid }"></vue-tel-input>
      </div>
    </v-col>

    <v-col cols="6" class="py-0">
      <v-text-field
        v-model="companyRegisterObject.email"
        :label="$t('components.companyRegister.email')"
        :rules="emailRules"
        required
        outlined
        dense
        >
      </v-text-field>
    </v-col> -->
    <!-- <v-col cols="12" class="py-0">
      <v-autocomplete
        v-model="companyRegisterObject.salesPartnerSelected"
        :items="salesPartnersArray"
        :filter="customFilter"
        :label="$t('components.companyRegister.salesPartner')"
        autocomplete="very_false"
        required
        :return-object="true"
        @change="setSalesPartner"
        :rules="salesPartnerRules"
        outlined
      dense
      ></v-autocomplete>
    </v-col> -->
    <!-- </v-row> -->
    <!-- end german lawyer but not ra-micro customer -->
  </v-row>
</template>

<script>
  import store from '../../store'
  import salesPartners from '../../salesPartners.json'
  import VueTelInput from 'vue-tel-input'
  import Vue from 'vue'

  Vue.use(VueTelInput)

  export default {
    props: [],
    components: {},
    data: () => ({
      valid: true,
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      companyRegisterObject: store.state.namespaceSettings.companyInfo,
      salutationSelected: store.state.namespaceSettings.companyInfo.salutation,
      phoneValid: false,
    }),
    methods: {
      setSalutation(value) {
        this.companyRegisterObject.salutationSelected = value.value
      },
      setSalesPartner(value) {
        this.companyRegisterObject.salesPartnerSelected = value.value
      },
      customFilter(item, queryText, itemText) {
        const textOne = item.text.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
      // onTelInput ({ number, isValid, country }) {
      //   this.phoneValid = isValid;
      //   if (isValid) {
      //     store.state.namespaceSettings.companyInfo.phone = ((number || {}).international || '').replace('+', '00').replace(/[-\s]/g, '');
      //   }
      // },
    },
    computed: {
      salutationsArray() {
        let arraySalutations = []
        const salutations = this.$t('components.companyRegister.salutations')
        for (let key in salutations) {
          if (key !== 'others') {
            const obj = {
              text: salutations[key],
              value: key,
            }
            arraySalutations.push(obj)
          }
        }
        arraySalutations = arraySalutations.sort(function(a, b) {
          var textA = a.text.toUpperCase()
          var textB = b.text.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        if (salutations['others']) {
          const obj = {
            text: salutations['others'],
            value: 'others',
          }
          arraySalutations.push(obj)
        }
        return arraySalutations
      },
      salesPartnersArray() {
        let arraySalesPartners = []
        salesPartners.forEach((element) => {
          const obj = {
            text: element['name'],
            value: element['id'],
          }
          arraySalesPartners.push(obj)
        })
        arraySalesPartners = arraySalesPartners.sort(function(a, b) {
          var textA = a.text.toUpperCase()
          var textB = b.text.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        return arraySalesPartners
      },
      cols() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg
          ? [12, 12, 12, 12]
          : sm
          ? [12, 12, 12, 12]
          : md
          ? [12, 12, 12, 12]
          : xs
          ? [12, 12, 12, 12]
          : [12, 12, 12, 12]
      },
      cols2() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg ? [8, 4] : sm ? [8, 4] : md ? [8, 4] : xs ? [8, 4] : [8, 4]
      },
      cols3() {
        const { lg, sm, md, xs } = this.$vuetify.breakpoint
        return lg ? [6, 6] : sm ? [6, 6] : md ? [6, 6] : xs ? [6, 6] : [6, 6]
      },
      companyNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.companyNameRequired'),
          (v) =>
            v.length >= 3 ||
            this.$t('components.companyRegister.companyNameMinimum'),
        ]
      },
      contactNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.contactNameRequired'),
          (v) =>
            /^[A-Za-z]{1,}[\-/\s]{0,1}[A-Za-z]{0,}$/.test(v) ||
            this.$t('components.companyRegister.contactNameInvalid'),
        ]
      },
      emailRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.emailRequired'),
          (v) =>
            /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
            this.$t('components.companyRegister.emailMustValid'),
        ]
      },
      salutationRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.salutationRequired'),
        ]
      },
      // salesPartnerRules () { return [ v => !!v || this.$t('components.companyRegister.salesPartnerRequired') ] },
      lawOfficeRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.lawOfficeRequired'),
        ]
      },
      streetHouseRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.streetHouseRequired'),
        ]
      },
      zipCodeRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.zipCodeRequired'),
        ]
      },
      cityRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.cityRequired'),
        ]
      },
      ramicroCustomerNumberRules() {
        return [
          (v) =>
            !!v ||
            this.$t('components.companyRegister.ramicroCustomerNumberRequired'),
        ]
      },
    },
    mounted() {
      // this.$refs.form.validate();
    },
  }
</script>
<style lang="scss">
  // .pRelative{
  //   position: relative;
  // }
  // .pContact{
  //   margin-bottom: -6px !important;
  //   font-size: 16px;
  //   color: rgba(0,0,0,.6);
  //   line-height: 20px;
  //   height: 20px;
  //   max-width: 133%;
  //   transform: translateY(0px) translateX(-40px) scale(.75);
  //   position: absolute;
  // top: -10px;
  // left: 48px;
  // background: white;
  // }
  .vue-tel-input {
    // border: none !important;
    // border-bottom: 1px solid !important;
    // border-radius: 0px !important;
    // box-shadow: none !important;
    // outline: none !important;
    height: 40px;
  }
</style>

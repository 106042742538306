import { render, staticRenderFns } from "./drawerContentUser.vue?vue&type=template&id=55b48221&scoped=true&"
import script from "./drawerContentUser.vue?vue&type=script&lang=js&"
export * from "./drawerContentUser.vue?vue&type=script&lang=js&"
import style0 from "./drawerContentUser.vue?vue&type=style&index=0&id=55b48221&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b48221",
  null
  
)

export default component.exports
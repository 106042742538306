/**
 * @param {number} num number of concurrent executions
 * @param {Array} arr array being the source of data, elements will be removed as they are processed
 * @param {Function} func async function to walk each element
 * @returns {Promise} promise
 */
export const parallel = async (num, arr, func) => {
  const thread = (item) => {
    if (item === undefined) return;
    return func(item) // eslint-disable-line consistent-return
      .catch(err => {
        console.error('Error in parallel, should be handled in func!', err);
        return true;
      })
      .then(() => { // eslint-disable-line consistent-return
        if (arr.length) return thread(arr.shift());
      });
  };
  const promises = []; // eslint-disable-next-line no-plusplus
  for (let i = 0; i < num; ++i) promises.push(thread(arr.shift()));
  await Promise.all(promises);
};

/**
 * @param {number} ms miliseconds
 */
export const aDelay = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
